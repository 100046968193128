import { CacheService } from './../utilities/cache/cache.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AuthService} from "../auth/auth.service";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private _storageServ: StorageService,
    private http: HttpClient,
    private _stateServ: CacheService,
    private authService: AuthService
  ) {}
  private headers = new HttpHeaders({
    'x-Gateway-APIKey': 'c2c22314-ca55-4b42-b472-494a6c180706',
    'Access-Control-Allow-Origin': '*',
  });

  getAllUsers(): Observable<any> {
    let url = 'user/get';
    let result = this.http.get<any>(this.authService.service_base + url, {
      headers: this.headers,
    });
    return result;
  }

  getUserByEmailAddress(EmailAddress: any) {
    let data = {
      "EmailAddress": EmailAddress
    }
    const url = 'user/getUserByEmailAddress';
    const result = this.http.post<any>(this.authService.service_base + url, data, {
      headers: this.headers,
    });
    return result;
  }

  createUser(payload: any) {
    const url = 'user/store';
    const result = this.http.post<any>(this.authService.service_base + url, payload, {
      headers: this.headers,
    });
    return result;
  }
  updateUser(payload: any): Observable<any> {
    const url = 'user/update';
    const result = this.http.post<any>(this.authService.service_base + url, payload, {
      headers: this.headers,
    });
    return result;
  }
  deleteUser(uuid: any): Observable<any> {
    let data = {
      "uuid": uuid
    }
    const url = "user/delete";
    const result = this.http.post<any>(this.authService.service_base + url, data, {
      headers: this.headers,
    });
    return result;
  }

  setUserCompany(payload: any): Observable<any> {
    const url = "/user/setUserCompany/";
    const result = this.http.post<any>(this.authService.service_base.slice(0, -1) + url, payload, {
      headers: this.headers,
    });
    return result;
  }

  deleteUserCompany(payload: any): Observable<any> {
    const url = "/user/deleteUserCompany/";
    const result = this.http.post<any>(this.authService.service_base.slice(0, -1) + url, payload, {
      headers: this.headers,
    });
    return result;
  }
}
