import { ReportingModule } from './reporting/reporting.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanySelectComponent } from './components/companies/company-select/company-select.component';
import { DashboardComponent } from './dashboard/dashboard.component';
const routes: Routes = [
  { path: 'login', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'select-company', component: CompanySelectComponent },
  // { path: 'select-company', loadChildren: () => import('./components/companies/company-select/company-select.component').then(m => m.CompanySelectComponent) },
  { path: 'dashboard/:id', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'manage-company', loadChildren: () => import('./company-management/company-management.module').then(m => m.CompanyManagementModule) },
  { path: 'manage-vehicle/:id', loadChildren: () => import('./vehicle-management/vehicle-management.module').then(m => m.VehicleManagementModule) },
  { path: 'manage-driver', loadChildren: () => import('./driver-management/driver-management.module').then(m => m.DriverManagementModule) },
  { path: 'manage-station', loadChildren: () => import('./station-management/station-management.module').then(m => m.StationManagementModule) },
  { path: 'billing-account', loadChildren: () => import('./billing-accounts/billing-accounts.module').then(m => m.BillingAccountsModule) },
  { path: 'mails', loadChildren: () => import('./mails/mails.module').then(m => m.MailsModule) },
  { path: 'manage-users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
  { path: 'manage-reports', loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
