<div class="myModal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="lft">
          <p>Report Settings</p>
          <p>{{data.form.COMPANYNAME}}</p>
      </div>
      <div class="rgt">
          <button mat-flat-button (click)="closeModal()">
              <i class="fa-solid fa-xmark"></i>
          </button>
      </div>
  </div>
  <div class="modal-contain">
    <form class="myform report-settings">
      <div class="row">
        <div class="col-3">
          <mat-checkbox name="LperH" [checked]="data.form.LperH == '1'? true : false " (change)="onChangeValue('LperH', $event.checked? '1' : '0' )" color="primary">Only L/H</mat-checkbox>
          <hr />
        </div>
        <div class="col-9">
          <mat-checkbox name="Lper100" [checked]="data.form.Lper100 == '1'? true : false "  (change)="onChangeValue('Lper100', $event.checked? '1' : '0' )" color="primary" >Only L/100</mat-checkbox>
          <hr />
        </div>
        <div class="col-3">
          <mat-checkbox name="Accumulative" [checked]="data.form.Accumulative == '1'? true : false " (change)="onChangeValue('Accumulative', $event.checked? '1' : '0' )" color="primary">Accumalitive</mat-checkbox>
        </div>
        <div class="col-3">
          <mat-checkbox name="NotToManageReport" [checked]="data.form.NotToManageReport == '1'? true : false "(change)="onChangeValue('NotToManageReport', $event.checked? '1' : '0' )"  color="primary">Not To Manage Reports</mat-checkbox>
        </div>
        <div class="col-3">
          <mat-checkbox name="NoCalcPossible" [checked]="data.form.NoCalcPossible == '1'? true : false " (change)="onChangeValue('NoCalcPossible', $event.checked? '1' : '0' )" color="primary">No Calculations Possible</mat-checkbox>
        </div>
        <div class="col-3">
          <mat-checkbox name="Awaiting" [checked]="data.form.Awaiting == '1'? true : false " (change)="onChangeValue('Awaiting', $event.checked? '1' : '0' )" color="primary">Awaiting Report</mat-checkbox>
        </div>
        <div class="col-3">
          <mat-checkbox name="SummaryReport" [checked]="data.form.SummaryReport == '1'? true : false " (change)="onChangeValue('SummaryReport', $event.checked? '1' : '0' )" color="primary">Top 15 Summary Reports</mat-checkbox>
        </div>
        <div class="col-3">
          <mat-checkbox name="OnlyTop15Detail" [checked]="data.form.OnlyTop15Detail == '1'? true : false " (change)="onChangeValue('OnlyTop15Detail', $event.checked? '1' : '0' )" color="primary">Only Top 15 in Detail Export</mat-checkbox>
        </div>
      
        <div class="col-3">
          <mat-checkbox name="DetailReportBuroAll" [checked]="data.form.DetailReportBuroAll == '1'? true : false " (change)="onChangeValue('DetailReportBuroAll', $event.checked? '1' : '0' )" color="primary">Detail Buro Report</mat-checkbox>
        </div>
        <div class="col-3">
          <mat-checkbox name="ReportInKmL" [checked]="data.form.ReportInKmL == '1'? true : false " (change)="onChangeValue('ReportInKmL', $event.checked? '1' : '0' )" color="primary">Report in Km/L</mat-checkbox>
        </div>
        <!-- <div class="col-6">
          <mat-checkbox name="NonFuelReport" [checked]="data.form.NonFuelReport == '1'? true : false " color="primary">Non Fuel Reports</mat-checkbox>
        </div> -->
        <div class="col-3">
          <mat-form-field>
            <mat-label>Top</mat-label>
            <input matInput name="TopXReport" [value]="data.form.TopXReport" placeholder="E.g 100" />
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>Add to Top 15 if more transactions</mat-label>
            <input matInput name="TopXTransactions" [value]="data.form.TopXTransactions" placeholder="E.g 10" />
          </mat-form-field>
        </div>
        
        <div class="col-3">
          <mat-form-field>
            <mat-label>Monthly Litres Start</mat-label>
            <input matInput name="MonthlyLitresStart" [value]="data.form.MonthlyLitresStart" (keyup)="onChangeValue('MonthlyLitresStart', $event.target.value)" placeholder="E.g 100"/>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>First Day Of Report</mat-label>
            <input matInput name="FirstDayOfReport" [value]="data.form.FirstDayOfReport" (keyup)="onChangeValue('FirstDayOfReport', $event.target.value)" placeholder="E.g 10"/>
          </mat-form-field>
        </div>
        <div class="htitle FxLine">
          <p>Awaiting vehicle information:</p>
        </div>
        <div class="col-3">
          <mat-checkbox name="AwaitingNoNorm" [checked]="data.form.AwaitingNoNorm == '1'? true : false " [value]="data.form.AwaitingNoNorm" (change)="onChangeValue('AwaitingNoNorm', $event.checked? '1' : '0' )" color="primary" placeholder="E.g 100">No Norm Specified</mat-checkbox>
        </div>
        <div class="col-9">
          <mat-checkbox name="AwaitingNoMake" [checked]="data.form.AwaitingNoMake == '1'? true : false " [value]="data.form.AwaitingNoMake" (change)="onChangeValue('AwaitingNoMake', $event.checked? '1' : '0' )" color="primary" placeholder="E.g 100">No Vehicle Make Specified</mat-checkbox>
        </div>
        <div class="htitle FxLine">
          <p>No calculations possible:</p>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Consumption Average Above (70%)</mat-label>
            <input matInput name="NoCalcAbove" [value]="data.form.NoCalcAbove" (keyup)="onChangeValue('NoCalcAbove', $event.target.value)" placeholder="E.g 160.00" />
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Consumption Average Below (150%)</mat-label>
            <input matInput name="NoCalcBelow" [value]="data.form.NoCalcBelow" (keyup)="onChangeValue('NoCalcBelow', $event.target.value)" placeholder="E.g 50.00" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <div class="htitle">
            <p>Burst default reports by:</p>
          </div>
        </div>
        <div class="col-3">
          <mat-checkbox name="BurstReportCompany" [checked]="data.form.BurstReportCompany == '1'? true : false " (change)="onChangeValue('BurstReportCompany', $event.checked? '1' : '0' )" color="primary">IFM Company</mat-checkbox>
        </div>
        <div class="col-3">
          <mat-checkbox name="BurstReportDivision" [checked]="data.form.BurstReportDivision == '1'? true : false " (change)="onChangeValue('BurstReportDivision', $event.checked? '1' : '0' )" color="primary">IFM Division</mat-checkbox>
        </div>
        <div class="col-2">
          <mat-checkbox name="BurstReportCustomer" [checked]="data.form.BurstReportCustomer == '1'? true : false " (change)="onChangeValue('BurstReportCustomer', $event.checked? '1' : '0' )" color="primary">AS400 Customer</mat-checkbox>
        </div>
        <div class="col-2">
          <mat-checkbox name="BurstReportAccount" [checked]="data.form.BurstReportAccount == '1'? true : false " (change)="onChangeValue('BurstReportAccount', $event.checked? '1' : '0' )" color="primary">AS400 Account</mat-checkbox>
        </div>
        <div class="col-2">
          <mat-checkbox name="BurstReportCostCentre" [checked]="data.form.BurstReportCostCentre == '1'? true : false " (change)="onChangeValue('BurstReportCostCentre', $event.checked? '1' : '0' )" color="primary">AS400 Cost Centre</mat-checkbox>
        </div>
        
        <div class="col-12">
          <mat-form-field>
            <mat-label>Oil Warn Limit</mat-label>
            <input matInput name="OilWarnLimit" [value]="data.form.OilWarnLimit" placeholder="E.g 10" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-progress-bar mode="indeterminate"  *ngIf="updateReportSettingsResponse === 'loading'"></mat-progress-bar>
          <button [disabled]="updateReportSettingsResponse === 'loading' ? true : false" class="mybtn" mat-flat-button color="primary" type="submit" (click)="updateReportSettings()">
            UPDATE 
          </button>
        </div>
      </div>
    </form>
  </div>
</div>




