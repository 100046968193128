<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="lft">
        <ng-container *ngIf="data.action === 'create'">
          <p>Create New Company</p>
          <p>Add new company</p>
        </ng-container>
        <ng-container *ngIf="data.action === 'update'">
          <p>Update Company</p>
          <p>{{data.form.company_name}}</p>
        </ng-container>
      </div>
      <div class="rgt">
        <button mat-flat-button (click)="closeModal()">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>

    <div class="modal-contain">
      <form class="myform">
        <div class="row">
          <div class="col-12 pb-3">
            <div class="htitle">
              <p class="FxLine">Company Information</p>
            </div>
            <div class="row">
              <div class="col-xs-12 col-md-12">
                <mat-form-field>
                  <mat-label>Company Name</mat-label>
                  <input matInput maxlength="50"
                         name="company_name"
                         [value]="data.form.company_name"
                         (keyup)="onChangeValue('company_name', $event.target.value)"
                         placeholder="Ex. Company Name" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Contact Person (Optional)</mat-label>
                  <input matInput
                         name="contact_person"  maxlength="50"
                         [value]="data.form.contact_person"
                         (keyup)="onChangeValue('contact_person', $event.target.value)"
                         placeholder="Ex. Name & Surname"/>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Telephone (Optional)</mat-label>
                  <input matInput maxlength="50"
                         name="telephone"
                         [value]="data.form.telephone"
                         (keyup)="onChangeValue('telephone', $event.target.value)"
                         placeholder="Ex. 0124567890"/>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Cell (Optional)</mat-label>
                  <input matInput maxlength="50"
                         name="cellphone"
                         [value]="data.form.cellphone"
                         (keyup)="onChangeValue('cellphone', $event.target.value)"
                         placeholder="Ex. 01234567890" />
                </mat-form-field>
              </div>

              <div class="col-xs-12 col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label>Currency Symbol</mat-label>
                  <mat-select
                    name="currency_symbol"
                    (selectionChange)="onChangeValue('currency_symbol', $event.value)"
                    [value]="data.form.currency_symbol">
                    <mat-option value="R">R</mat-option>
                    <mat-option value="$">$</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <mat-progress-bar
              mode="indeterminate"
              *ngIf="addCompanyResponse === 'loading' || updateCompanyResponse === 'loading'"
            ></mat-progress-bar>

            <button class="mybtn" type="button" mat-flat-button color="primary"
              [disabled]="addCompanyResponse === 'loading' || updateCompanyResponse === 'loading' ? true : false"
              *ngIf="data.action == 'create'"
              (click)="addCompany()"
            >Add Company</button>

            <button
              class="mybtn" type="button" mat-flat-button color="primary"
              [disabled]="addCompanyResponse === 'loading' || updateCompanyResponse === 'loading' ? true : false"
              *ngIf="data.action == 'update'"
              (click)="updateCompany()"
            >Update Company</button>

          </div>
        </div>
      </form>
    </div>
  </div>
