<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="lft">
            <p>Reports Results Per Vehicle</p>
            <p>Add description here</p>
        </div>
        <div class="rgt">
            <button mat-flat-button (click)="closeModal()">
                <i class="fa-solid fa-xmark"></i>
            </button>
        </div>
    </div>

    <div class="modal-contain">
        <form class="myform">
            <div class="row">
                <div class="col-12">
                    <div class="modal-tbl">
                        <div class="mytable">
                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                                <ng-container matColumnDef="REGISTRATION_NO">
                                    <th mat-header-cell *matHeaderCellDef>REGISTRATION NO</th>
                                    <td mat-cell *matCellDef="let row">{{ row.REGISTRATION_NO }}</td>
                                </ng-container>
                                <ng-container matColumnDef="YEAR">
                                    <th mat-header-cell *matHeaderCellDef>YEAR</th>
                                    <td mat-cell *matCellDef="let row">{{ row.YEAR }}</td>
                                </ng-container>
                                <ng-container matColumnDef="MONTH">
                                    <th mat-header-cell *matHeaderCellDef>MONTH</th>
                                    <td mat-cell *matCellDef="let row">{{ row.MONTH }}</td>
                                </ng-container>
                                <ng-container matColumnDef="FLEETNO">
                                    <th mat-header-cell *matHeaderCellDef>FLEETNO</th>
                                    <td mat-cell *matCellDef="let row">{{ row.FLEETNO }}</td>
                                </ng-container>
                                <ng-container matColumnDef="MVA">
                                    <th mat-header-cell *matHeaderCellDef>MVA</th>
                                    <td mat-cell *matCellDef="let row">{{ row.MVA }}</td>
                                </ng-container>
                                <ng-container matColumnDef="DRIVER">
                                    <th mat-header-cell *matHeaderCellDef>DRIVER</th>
                                    <td mat-cell *matCellDef="let row">{{ row.DRIVER }}</td>
                                </ng-container>
                                <ng-container matColumnDef="MAKE">
                                    <th mat-header-cell *matHeaderCellDef>MAKE</th>
                                    <td mat-cell *matCellDef="let row">{{ row.MAKE }}</td>
                                </ng-container>
                                <ng-container matColumnDef="RANGE">
                                    <th mat-header-cell *matHeaderCellDef>RANGE</th>
                                    <td mat-cell *matCellDef="let row">{{ row.RANGE }}</td>
                                </ng-container>
                                <ng-container matColumnDef="MODEL">
                                    <th mat-header-cell *matHeaderCellDef>MODEL</th>
                                    <td mat-cell *matCellDef="let row">{{ row.MODEL }}</td>
                                </ng-container>
                                <ng-container matColumnDef="VEHTYPE">
                                    <th mat-header-cell *matHeaderCellDef>VEHTYPE</th>
                                    <td mat-cell *matCellDef="let row">{{ row.VEHTYPE }}</td>
                                </ng-container>
                                <tr
                                    mat-header-row
                                    *matHeaderRowDef="
                                    displayedColumns;
                                    sticky: true
                                    "
                                ></tr>
                                <tr
                                    mat-row
                                    *matRowDef="let row; columns: displayedColumns"
                                ></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
  </div>

