import { Component, EventEmitter, Inject, Output, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/companies/company.service';
import { DriverService } from 'src/app/services/drivers/driver.service';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';

@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrls: ['./add-driver.component.css'],
})
export class AddDriverComponent implements OnInit {
  @Input() vehicleDriver: boolean = false;
  addDriverForm: any;
  allDrivers: any;
  vehicleDriverForm: any;
  oldDriverData: any;
  oldDriverForm: any;
  confirmDriverAssignement: boolean = false;
  confirmDriverResponse =  '';
  constructor(
    public dialogRef: MatDialogRef<AddDriverComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _snackbar: SnackbarsService,
    private _driverServ: DriverService,
    private _companyServ: CompanyService
  ) {}

  ngOnInit() {
    if (this.vehicleDriver) {
      this.oldDriverData = this.data.driver;
    } else {
      this.oldDriverData = this.data;
    }

    this.addDriverForm = this.formBuilder.group({
      uuid: [this.oldDriverData?.uuid || ''],
      company_uuid: [this._companyServ.getCompanyId()],
      vehicle_uuid: [this.oldDriverData?.vehicle_uuid || ''],
      driver_name: [{ value: this.oldDriverData?.driver_name || '', disabled: this.vehicleDriver }, Validators.required],
      employee_number: [{ value: this.oldDriverData?.employee_number || '', disabled: this.vehicleDriver }, Validators.required],
      id_number: [{ value: this.oldDriverData?.id_number || '', disabled: this.vehicleDriver }],
      passport_number: [{ value: this.oldDriverData?.passport_number || '', disabled: this.vehicleDriver }]
    });

    this.oldDriverForm = this.formBuilder.group({
      uuid: [{ value: this.oldDriverData?.uuid || '' }],
      company_uuid: [{ value: this.oldDriverData?.company_uuid || '' }],
      vehicle_uuid: [{ value: this.oldDriverData?.vehicle_uuid || '' }],
      driver_name: [{ value: this.oldDriverData?.driver_name || '', disabled: true }],
      employee_number: [{ value: this.oldDriverData?.employee_number || '', disabled: true }],
      id_number: [{ value: this.oldDriverData?.id_number || '', disabled: true }],
      passport_number: [{ value: this.oldDriverData?.passport_number || '', disabled: true }]
    });

    this.vehicleDriverForm = this.formBuilder.group({
      new_driver: [null],
      vehicle_uuid: [this.vehicleDriver ? this.data?.uuid : ''],
      old_driver_uuid: [this.vehicleDriver && this.oldDriverData ? this.oldDriverData?.uuid : '']
    });

    this.getAllDrivers();

    console.log(this.vehicleDriverForm.value['new_driver']);
  }

  onSubmit() {
    if (this.addDriverForm.valid) {
      if (this.addDriverForm.value.id_number || this.addDriverForm.value.passport_number) {
        if (!this.oldDriverData) {
          this._driverServ.createNewDriver(this.addDriverForm.value).subscribe({
            next: (data: any) => {
              this._snackbar.success(data.status.message);
              if (data.status.message.includes('Driver added')) {
                this.closeModal('add');
              }
            },
            error: (error) => this._snackbar.error(error.message)
          });
        } else {
          this._driverServ.updateDriver(this.addDriverForm.value).subscribe({
            next: (data: any) => {
              this._snackbar.success(data.status.message);
              if (data.status.message.includes('Driver updated')) {
                this.closeModal('update');
              }
            },
            error: (error) => this._snackbar.error(error.message)
          });
        }
      } else {
        this._snackbar.error('Please fill the ID or Passport number');
      }
    } else {
      this._snackbar.error('Please fill all required fields');
    }
  }

  closeModal(data?: any): void {
    this.dialogRef.close({ event: data });
  }

  getAllDrivers() {
    this._driverServ.getDriversByCompanyId(this._companyServ.getCompanyId()).subscribe((response: any) => {
      this.allDrivers = response.results;
    });
  }

  onChangeDriver(event: any) {
    console.log(event);
    if (this.vehicleDriverForm.value['old_driver_uuid'] !== event.uuid) {
      this.addDriverForm.patchValue(event);
      this.vehicleDriverForm.patchValue({ new_driver: event });
    } else {
      this.vehicleDriverForm.patchValue({ new_driver: null });
    }
  }

  assignDriver() {
    if(this.vehicleDriverForm.value['new_driver'].vehicle !== null) {
      this.confirmDriverAssignement = true;
    } else {
      this.saveAssignedDriver();
    }
  }

  saveAssignedDriver() {
    this.confirmDriverResponse = 'loading';
    this._driverServ.assignNewDriver(this.vehicleDriverForm.value).subscribe({
      next: (data: any) => {
        this._snackbar.success(data.status.message);
        if (data.status.message.includes('Driver assigned')) {
          this.vehicleDriverForm.patchValue({'old_driver_uuid': this.vehicleDriverForm.value['new_driver']?.uuid});
          this.oldDriverForm.patchValue(this.vehicleDriverForm.value['new_driver']);
          this.vehicleDriverForm.patchValue({ new_driver: null });
          this.confirmDriverAssignement = false;
          this.confirmDriverResponse = '';
          this.getAllDrivers();
        }
      },
      error: (error) => this._snackbar.error(error.message)
    });
  }

  closeConfirmDialog(){
    this.confirmDriverAssignement = false;
    this.confirmDriverResponse = '';
    this.confirmDriverAssignement = false;
  }
}
