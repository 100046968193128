import { Component } from '@angular/core';

@Component({
  selector: 'app-view-update-account-billing',
  templateUrl: './view-update-account-billing.component.html',
  styleUrls: ['./view-update-account-billing.component.css']
})
export class ViewUpdateAccountBillingComponent {

}
