<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="lft">
            <p>Special Procedures</p>
            <p>Special Procedures</p>
        </div>
        <div class="rgt">
            <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
        </div>
    </div>

    <div class="modal-contain">
        <div class="myuitabs">
            <div class="lft">
                <button mat-stroked-button [ngClass]="tab==='1'?'active':''" (click)="selectTab('1')" type="button"><p>Others</p></button>
                <button mat-stroked-button [ngClass]="tab==='2'?'active':''" (click)="selectTab('2')" type="button"><p>Change PPL For Fuelstation</p></button>
                <button mat-stroked-button [ngClass]="tab==='3'?'active':''" (click)="selectTab('3')" type="button"><p>Switch Companies</p></button> 
            </div>
        </div>
        <ng-container *ngIf="tab==='1'">
            <form class="myform">
                <div class="row">
                    <div class="col-xs-12 col-md-3 col-lg-8">
                        <div class="row">
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Fix Fleet No & Vehicle Id</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Awaiting Vehicle</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Customer Mails</p>
                                    </div>
                                </button>
                            </div>
                            
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Make High Odo = 0</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        
                                        <p>Update MRM Database</p>
                                    </div>
                                </button>
                            </div>
                            
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        
                                        <p>Delete, Make initial</p>
                                    </div>
                                </button>
                            </div>
                            
                            
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Fix Values On Mailsent</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Fix In Vehc Type</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Invalid Vehicle Id Transactions</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Invalid in email address</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        
                                        <p>Fix Vehicle Cost Centre Name</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        
                                        <p>Recheck Registration Swap</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        
                                        <p>Fill in Oil Companies in FS</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        
                                        <p>Wipe all traces of vehicle</p>
                                    </div>
                                </button>
                            </div>
                            
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        
                                        <p>Fill In Driver Names in Mailsent</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        
                                        <p>Fill In Cus.Acc.CC in Accumalitive</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        
                                        <p>Check Swap Reg File</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        
                                        <p>Export Empty Divisons</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        
                                        <p>Delete all emails for Vehicles</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        
                                        <p>Fill In Driver Names in Mailsent</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        
                                        <p>Fill In Driver Names in Mailsent</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Fill In Driver Names in Mailsent</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-4">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Fill In Driver Names in Mailsent</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-2">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Create MRM</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-2">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>INI Files Merge</p>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3 col-lg-4">
                        <div class="row">
                            <div class="col-xs-12 col-lg-12">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Remove Duplicate transactions Id's</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-md-12">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Check dublicate Reg in Awaiting. NTM and Live</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-md-12">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Delete All Vehicles ACC.CC.Status Transactions</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-md-12">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Delete Divison For Company</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-md-12">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Make initial Odo All Vehicles (This Customer)</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-md-12">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Delete All Not To Manage Transactions before</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-12">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Fix Company Name on Transactions</p>
                                    </div>
                                </button>
                            </div>
                            <div class="col-xs-12 col-lg-12">
                                <button mat-stroked-button class="specialbtn">
                                    <div class="details">
                                        <p>Update Account Names Transactions</p>
                                    </div>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="tab==='2'">
            <form class="myform">
                <div class="row">
                    <div class="col-12 col-md-5">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Start Date</mat-label>
                                    <input
                                      (keydown)="$event.preventDefault()"
                                      placeholder="Ex. 2020-02-20"
                                      matInput
                                      [matDatepicker]="StartDatePicker"
                                      name="StartDate"
                                    />
                                    <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                    <mat-datepicker-toggle
                                      matIconSuffix
                                      [for]="StartDatePicker"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker #StartDatePicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Stop Date</mat-label>
                                    <input
                                      (keydown)="$event.preventDefault()"
                                      placeholder="Ex. 2020-02-20"
                                      matInput
                                      [matDatepicker]="StopDatePicker"
                                      name="StopDate"
                                    />
                                    <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                    <mat-datepicker-toggle
                                      matIconSuffix
                                      [for]="StartDatePicker"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker #StopDatePicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            
                            <div class="col-12 col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Select</mat-label>
                                    <mat-select>
                                      <mat-option value="Driver">Title 1</mat-option>
                                      <mat-option value="Date">Title 2</mat-option>
                                      <mat-option value="Time">Title </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6">
                                <mat-form-field >
                                    <mat-label>New Price Per Litre</mat-label>
                                    <input matInput type="number" value="0.00" placeholder="Ex. 1234">
                                </mat-form-field>
                            </div>
                            
                            <div class="col-12">
                                <button class="mybtn" mat-flat-button color="primary" type="submit">
                                  Change PPL for Fuelstation
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="tab==='3'">
            <form class="myform">
                <div class="row">
                    <div class="col-12 col-md-5">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Select Company</mat-label>
                                    <mat-select>
                                      <mat-option value="Driver">Company 1</mat-option>
                                      <mat-option value="Date">Company 2</mat-option>
                                      <mat-option value="Time">Company 3 </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Select To Company</mat-label>
                                    <mat-select>
                                      <mat-option value="Driver">Company 1</mat-option>
                                      <mat-option value="Date">Company 2</mat-option>
                                      <mat-option value="Time">Company 3 </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            
                            <div class="col-12">
                                <button class="mybtn" mat-flat-button color="primary" type="submit">
                                  Switch Companies
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
  </div>

