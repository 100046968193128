<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="lft">
            <p>Operators Report</p>
            <p>Add description here</p>
        </div>
        <div class="rgt">
            <button mat-flat-button (click)="closeModal()">
                <i class="fa-solid fa-xmark"></i>
            </button>
        </div>
    </div>

    <div class="modal-contain">
        <form class="myform">
            <div class="row">
                <div class="col-12">
                    <p>Dates are only used when checking emails and telephone calls.</p>
                </div>
                <div class="col-12 col-md-5">
                    <mat-form-field appearance="fill">
                        <mat-label>Start Date</mat-label>
                        <input
                        (dateChange)="handleStartDate($event.target)"
                        (keydown)="$event.preventDefault()"
                        placeholder="Ex. 2020-02-20"
                        matInput
                        [matDatepicker]="StartDatePicker"
                        name="StartDatePicker"
                        value = {{startDate}}
                        />
                        <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                        <mat-datepicker-toggle
                          matIconSuffix
                          [for]="StartDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #StartDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-5">
                    <mat-form-field appearance="fill">
                        <mat-label>End Date</mat-label>
                        <input
                        (dateChange)="handleEndDate($event.target)"
                        (keydown)="$event.preventDefault()"
                        placeholder="Ex. 2001-01-31"
                        matInput
                        [matDatepicker]="EndDatePicker"
                        name="EndDatePicker"
                        value = {{endDate}}
                        />
                        <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                        <mat-datepicker-toggle
                          matIconSuffix
                          [for]="EndDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #EndDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-2">
                    <button mat-flat-button color="primary" (click)="ExportIt()">
                        Export Report
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="modal-tbl">
                        <div class="mytable">
                            <div class="myfeedback" *ngIf="dataSource.length<1 && response!= 'loading'">
                                <div class="wrapp">
                                    <i class="fa-regular fa-folder-open"></i>
                                    <p>No results, no records retrieved.</p>
                                </div>
                            </div>
                            <div class="table-skeleton anime-skeleton" *ngIf="response == 'loading'">
                                <div class="mywrapper">
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                    <div class="tr"><p></p><p></p></div>
                                </div>
                            </div>
                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="response == 'success'">
                                <ng-container matColumnDef="USERNAME">
                                    <th mat-header-cell *matHeaderCellDef>Operator</th>
                                    <td mat-cell *matCellDef="let row">{{ row.USERNAME }}</td>
                                </ng-container>
                                <ng-container matColumnDef="COMPANYNAME">
                                    <th mat-header-cell *matHeaderCellDef>Company</th>
                                    <td mat-cell *matCellDef="let row">{{ row.COMPANYNAME }}</td>
                                </ng-container>
                                <ng-container matColumnDef="LastArchiveDate">
                                    <th mat-header-cell *matHeaderCellDef>Last Archive Date</th>
                                    <td mat-cell *matCellDef="let row">{{ row.LastArchiveDate?row.LastArchiveDate.substring(0,10):'No date' }}</td>
                                </ng-container>
                                <ng-container matColumnDef="LastImportDate">
                                    <th mat-header-cell *matHeaderCellDef>Last Import Date</th>
                                    <td mat-cell *matCellDef="let row">{{ row.LastImportDate?row.LastImportDate.substring(0,10):'No date' }}</td>
                                </ng-container>
                                <ng-container matColumnDef="NoEmailsSent">
                                    <th mat-header-cell *matHeaderCellDef>No Emails Sent</th>
                                    <td mat-cell *matCellDef="let row">{{ row.NoEmailsSent }}</td>
                                </ng-container>
                                <ng-container matColumnDef="NoEmailsReplied">
                                    <th mat-header-cell *matHeaderCellDef>No Emails Replied</th>
                                    <td mat-cell *matCellDef="let row">{{ row.NoEmailsReplied }}</td>
                                </ng-container>
                                <ng-container matColumnDef="NoFSEmails">
                                    <th mat-header-cell *matHeaderCellDef>No FS Emails</th>
                                    <td mat-cell *matCellDef="let row">{{ row.NoFSEmails }}</td>
                                </ng-container>
                                <ng-container matColumnDef="NoFSEmailsReplied">
                                    <th mat-header-cell *matHeaderCellDef>No FS Emails Replied</th>
                                    <td mat-cell *matCellDef="let row">{{ row.NoFSEmailsReplied }}</td>
                                </ng-container>
                                <ng-container matColumnDef="NoTelCalls">
                                    <th mat-header-cell *matHeaderCellDef>No Tel Calls</th>
                                    <td mat-cell *matCellDef="let row">{{ row.NoTelCalls }}</td>
                                </ng-container>
                                <ng-container matColumnDef="NoOccurrences">
                                    <th mat-header-cell *matHeaderCellDef>No Occurrence</th>
                                    <td mat-cell *matCellDef="let row">{{ row.NoOccurrences }}</td>
                                </ng-container>
                                <tr
                                    mat-header-row
                                    *matHeaderRowDef="
                                    displayedColumns;
                                    sticky: true
                                    "
                                ></tr>
                                <tr
                                    mat-row
                                    *matRowDef="let row; columns: displayedColumns"
                                ></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
    
        </form>
    </div>
  </div>

