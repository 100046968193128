import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  private headers = new HttpHeaders({
    'x-Gateway-APIKey': 'c2c22314-ca55-4b42-b472-494a6c180706',
    'Access-Control-Allow-Origin': '*',
  });


  getScoreCardParametersByLevel(level: any) {
    let payload = {
      "LEVEL": level
    }
    const url = "/company/getScoreParameters/";
    const result = this.http.post<any>(url, payload, {
      headers: this.headers,
    });
    return result;
  }


  getOperatorsReport(data: any) {
    const url = "/company/getOperatorsReport/";
    const result = this.http.post<any>(url, data, {
      headers: this.headers,
    });
    return result;
  }

  addScoreCardParameter(data: any) {
    const url = "/company/addScoreCardParameter/";
    const result = this.http.post<any>(url, data, {
      headers: this.headers,
    });
    return result;
  }
  updateScoreCardParameter(data: any) {
    const url = "/company/updateScoreCardParameter/";
    const result = this.http.post<any>(url, data, {
      headers: this.headers,
    });
    return result;
  }

}
