import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class BillingAccountsService {
  constructor(private http: HttpClient) { }

  private service_base = "https://zedadev.apigw-aw-eu.webmethods.io/gateway/IFM/1.0/"

  private headers = new HttpHeaders({
    'x-Gateway-APIKey': 'c2c22314-ca55-4b42-b472-494a6c180706',
    'Access-Control-Allow-Origin': '*',
  });

  createBillingAcount(data: any): Observable<any> {
    const url = this.service_base + 'createAccountBilling';
    const result = this.http.post<any>(url, data, {
      headers: this.headers
    });
    return result;
  }

  getCompanyBillingAccount(companyID: any): Observable<any> {
    const url = this.service_base + 'insertCompany';
    const result = this.http.get<any>(url, {
      headers: this.headers
    });
    return result;
  }

  getBillingAccountKPI() {
    const url = this.service_base + 'insertCompany';
    const result = this.http.get<any>(url, {
      headers: this.headers
    });
    return result;
  }

  updateBuillingAccount(data: any): Observable<any> {
    const url = this.service_base + 'updateBillingAccount';
    const result = this.http.put<any>(url, data, {
      headers: this.headers
    });
    return result;
  }

  getAllBillingAccounts(): Observable<any> {
    const url = this.service_base + 'getAccountBillingKpi';
    const result = this.http.get<any>(url, {
      headers: this.headers
    });
    return result;
  }

  createUpdateAccountBilling(data?: any) {
    const url = '/billing/insert-update';
    const result = this.http.post<any>(url, data, {
      headers: this.headers
    });
    return result;
  }

  getAccountBilling(account_id?: any) {
    const url = "/company/getAccountBilling/" + account_id;
    const result = this.http.get<any>(url, {
      headers: this.headers
    });
    return result;
  }

  getAccountByID(id: any) {
    const url = "/company/getAccountByID/" + id;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;
  }

  getAccountBillingByType(type: any) {
    const url = "/company/getAccountBillingByType/" + type;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;
  }



}

