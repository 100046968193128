import { BillingAccountsService } from 'src/app/services/billing-accounts/billing-accounts.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewCompanyComponent } from '../view-company/view-company.component';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';

@Component({
  selector: 'app-generate-monthly-billing',
  templateUrl: './generate-monthly-billing.component.html',
  styleUrls: ['./generate-monthly-billing.component.css']
})
export class GenerateMonthlyBillingComponent {

  constructor(
    public dialogRef: MatDialogRef<GenerateMonthlyBillingComponent>,
    private _billingAccountServ: BillingAccountsService,
    private _snackBar: SnackbarsService,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
  }
  billingTypeOptions = new MatTableDataSource([
    { name: "NA", value: 'Not Applicable' },
    { name: "FR", value: 'Fuel Remote Homebase Monthly Fee' },
    { name: "FC", value: 'Fuel Manager Monthly Fee By Vehicle Count' },
    { name: "FW", value: 'Fuel Remote Web Monthly Fee' },
    { name: "VU", value: 'Vehicle Usage' },
    { name: "LF", value: 'Legal Fee ' },
    { name: "FA", value: 'Fuel Manager Anual Fee' },
    { name: "FM", value: 'Fuel Manager Monthly Fee' },
    { name: "BS", value: 'Broker Bueau Service Monthly Fee By Vehicle Count' },
    { name: "BC", value: 'Bueau Service Monthly Fee By Vehicle Count' },
    { name: "SF", value: 'Bueau Set Up Fee' },
  ]);

  billingAccounts = new MatTableDataSource();

  ngOnInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }

  accountsDataSource = this.data
  displayedColumns_Accounts: string[] = [
    'TRF',
    'ACCOUNTID',
    'AS400ACCOUNTID',
    'BILLINGTYPE',
    'BILLINGDESCRIPTION',
  ];
  displayedColumns_Email_Addresses: string[] = [
    'BILLINGTYPE',
    'BILLINGDESCRIPTION',
  ];
  displayedColumns_Billing: string[] = [
    'ID',
    'COMPANYNAME',
    'CUSTOMERNAME',
    'ACCOUNTID',
    'ACCOUNTNAME',
    'BILLINGTYPE',
    'BILLINGDESCRIPTION',
    'REGISTRATIONNO',
    'FLEETNO',
  ];

  getBillingAccounts(type: string) {
    try {
      this._billingAccountServ.getAccountBillingByType(type).subscribe((response: any) => {
        if (response.length==0) {
          this._snackBar.success('No Billable Accounts found!');
        }
        this.billingAccounts = response;
      });
    } catch (error: any) {
      this._snackBar.error(error.message)
    }

  }

  closeModal(data?: any): void {
    this.dialogRef.close({ event: data });
  }
}
