import { Component } from '@angular/core';

@Component({
  selector: 'app-vehicle-driver',
  templateUrl: './vehicle-driver.component.html',
  styleUrls: ['./vehicle-driver.component.css']
})
export class VehicleDriverComponent {

}
