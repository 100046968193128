import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-report-results-per-vehicle',
  templateUrl: './report-results-per-vehicle.component.html',
  styleUrls: ['./report-results-per-vehicle.component.css']
})
export class ReportResultsPerVehicleComponent {

  constructor(
  public dialogRef: MatDialogRef<ReportResultsPerVehicleComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any) {}

  closeModal(): void {
    this.dialogRef.close();
  }

  displayedColumns: string[] = [
    'REGISTRATION_NO',
    'YEAR',
    'MONTH',
    'FLEETNO',
    'MVA',
    'DRIVER',
    'MAKE',
    'RANGE',
    'MODEL',
    'VEHTYPE',
  ];
  dataSource = [
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    },
    {
      'REGISTRATION_NO':"CZ1167",
      'YEAR': "2024",
      'MONTH':"2",
      'FLEETNO': "12345",
      'MVA': "2671640",
      'DRIVER': "POOL - BEAUFORT",
      'MAKE': "TOYOTA",
      'RANGE': "HILUX",
      'MODEL': "2.4 GD S A/C",
      'VEHTYPE': "LIGHT",
    }
  
  ];
}
