<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="lft">
        <ng-container *ngIf="data.action == 'create'">
            <p>Create New Role</p>
            <p>Add new role</p>
          </ng-container>
          <ng-container *ngIf="data.action == 'update'">
            <p>Update Role</p>
            <p>{{data.form.role}}</p>
          </ng-container>
      </div>
      <div class="rgt">
        <button mat-flat-button (click)="closeModal()">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>

    <div class="modal-contain">
      <form class="myform addnewuser" >
          <div class="userpp">
            <i class="fa-regular fa-user"></i>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field>
                <mat-label>Role Name</mat-label>
                <input name="role" matInput placeholder="E.g Admin" maxlength="50"
                       (keyup)="onChangeValue('role', $event.target.value)"
                       [value]="data.form.role"
                />
              </mat-form-field>
            </div>
            <div class="col-12">
                <mat-progress-bar mode="indeterminate"  *ngIf="response === 'loading'"></mat-progress-bar>
                <button class="mybtn" color="primary" type="button" mat-flat-button
                        [disabled]="response === 'loading' ? true : false"
                        *ngIf="data.action == 'create'"
                        (click)="createRole()"
                >
                    Create Role
                </button>
                <button class="mybtn" mat-flat-button color="primary" type="button"
                        [disabled]="response === 'loading' ? true : false"
                        *ngIf="data.action == 'update'"
                        (click)="updateRole()"
                >
                    Update Role
                </button>
            </div>
          </div>

      </form>
    </div>
  </div>
