import { Observable, filter } from 'rxjs';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataTransformationsService {
  constructor() {}

  public formatDate(date: string, format?: string) {
    return this.toDashYearMonthDay(date);
  }

  private toDashYearMonthDay(date: string) {
    let new_date = new Date(date);
    // Get year, month, and day part from the date
    var year = new_date.toLocaleString('default', { year: 'numeric' });
    var month = new_date.toLocaleString('default', { month: '2-digit' });
    var day = new_date.toLocaleString('default', { day: '2-digit' });
    return year + '-' + month + '-' + day;
  }

  public objArrFilter(objArr: any, term: any, rawData?: any):Observable<any> {
    let object_properties = this.getObjectProperties(objArr);

    return objArr.filter((result: any) => {
      console.log(result[object_properties[0]]
        .toLowerCase()
        .includes(term.toLowerCase()));
        return result[object_properties[0]]
          .toLowerCase()
          .includes(term.toLowerCase());

    });
  }

  private getObjectProperties(objArr: any) {
    return Object.getOwnPropertyNames(objArr[0]);
  }

  GetArrUpdate(objArr:any,obj:any,index:any){

    objArr[index].AMOUNT = obj.AMOUNT;
    objArr[index].CALCLITRES = obj.CALCLITRES;
    objArr[index].CALCKM = obj.CALCKM;
    objArr[index].PRICEPERLITRE = obj.PRICEPERLITRE;
    objArr[index].TRIPKM = obj.TRIPKM;
    objArr[index].CONSUMPTIONKML = obj.CONSUMPTIONKML;
    objArr[index].CONSUMPTIONL100 = obj.CONSUMPTIONL100;
    objArr[index].CPK = obj.CPK;
    objArr[index].TRIPLITRES = obj.TRIPLITRES;
    objArr[index].LITRESATNORM = obj.LITRESATNORM;
    objArr[index].LOSSLITRES = obj.LOSSLITRES;
    objArr[index].LOSSAMOUNT = obj.LOSSAMOUNT;
    objArr[index].PERCLOSS = obj.PERCLOSS;
    objArr[index].CPK_NORM = obj.CPK_NORM;
    objArr[index].PREVIOUSODO =obj.PREVIOUSODO;
    return objArr

  }
}
