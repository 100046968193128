import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DriverService } from 'src/app/services/drivers/driver.service';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';
@Component({
  selector: 'app-update-driver',
  templateUrl: './update-driver.component.html',
  styleUrls: ['./update-driver.component.css'],
})
export class UpdateDriverComponent {
  constructor(
    public dialogRef: MatDialogRef<UpdateDriverComponent>,
    private formBuilder: FormBuilder,
    private _snackBar: SnackbarsService,
    private _driverServ: DriverService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  updateDriverForm = this.formBuilder.group({
    company_id: [this.data.company_id, Validators.required],
    COMPANYNAME: [this.data.COMPANYNAME, Validators.required],
    DRIVERID: [this.data.DRIVERID.replace(' ', ''), Validators.required],
    DRIVERNAME: [this.data.DRIVERNAME, Validators.required],
    AS400DRIVERID: [this.data.AS400DRIVERID, Validators.required],
    EMPLOYEENUMBER: [this.data.EMPLOYEENUMBER, Validators.required],
    IDNUMBER: [this.data.IDNUMBER, Validators.required],
    ISACTIVE: [this.data.ISACTIVE, Validators.required],
    PASSPORTNUMBER: [this.data.PASSPORTNUMBER, Validators.required],
  });

  onSubmit() {
    try {
      this._driverServ.updateDriver(this.updateDriverForm.value).subscribe({
        next: (data: any) => {
          if (data.statusMessage.includes('Succesfully')) {
            this.closeModal(true);
          }
          this._snackBar.success(data.statusMessage);
        },
      });
    } catch (error) {}
  }

  closeModal(data?: any): void {
    this.dialogRef.close({ event: data });
  }
}
