import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {VehicleService} from "../../../services/vehicles/vehicle.service";
import {CompanyService} from "../../../services/companies/company.service";
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import {Moment} from "moment";
import {DeviceService} from "../../../services/devices/device.service";
@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.css'],
})
export class AddVehicleComponent implements OnInit {
  addVehicleForm: FormGroup;
  addDeviceForm: FormGroup;
  vehicleTypes: any;
  makes: any = [];
  ranges: any = [];
  carModels: any = [];
  fuelTypes: any = [];
  tab = '1';
  constructor(
    private dialogRef: MatDialogRef<AddVehicleComponent>,
    private _snackBar: SnackbarsService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any = [],
    private _companyServe: CompanyService,
    private _vehicleServ: VehicleService,
    private _deviceService: DeviceService
  ) {
    this.addVehicleForm = this.formBuilder.group({
      uuid: [this.data ? this.data.uuid : ''],
      company_uuid: [this._companyServe.getCompanyId()],
      mva_number: [this.data ? this.data?.mva_number : '', Validators.required],
      registration_number: [this.data ? this.data?.registration_number : '', Validators.required],
      year: [this.data ? this.data?.year : '', Validators.required],
      tank_capacity: [this.data ? this.data?.tank_capacity : '', Validators.required],
      make_uuid: [this.data ? this.data?.make_vehicle_type?.make?.uuid : '', Validators.required],
      vehicle_type_uuid: [this.data ? this.data?.make_vehicle_type?.vehicle_type?.uuid : '', Validators.required],
      range_uuid: [this.data ? this.data?.range?.uuid : '', Validators.required],
      model_uuid: [this.data ? this.data?.car_model?.uuid : '', Validators.required],
      fuel_type_uuid: [this.data ? this.data?.fuel_type_uuid : '', Validators.required],
      photo: [this.data ? this.data?.photo : '', Validators.required],
    });

    this.addDeviceForm = this.formBuilder.group({
      uuid: [this.data?.device ? this.data?.device.uuid : ''],
      company_uuid: [this._companyServe.getCompanyId()],
      vehicle_uuid: [this.data?.device ? this.data?.device.vehicle_uuid : ''],
      imei: [this.data?.device ? this.data?.device.imei : '', [Validators.required]],
      device_name: [this.data?.device ? this.data?.device.device_name : '', Validators.required],
      device_type: [this.data?.device ? this.data?.device.device_type : '', Validators.required],
      plate_number: [this.data?.device ? this.data?.device.plate_number : '', Validators.required],
      sim_card: [this.data?.device ? this.data?.device.sim_card : '', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
    });
  }

  ngOnInit(): void {
    this.getAllVehicleTypes();
    this.getFuelTypes();

    if (this.data) {
      this._vehicleServ.getVehicleTypeMakes(this.data?.make_vehicle_type?.vehicle_type?.uuid).subscribe((response: any) => {
        this.makes = response.results;
      });

      this._vehicleServ.getMakeRanges(this.data?.make_vehicle_type?.make?.uuid).subscribe((response: any) => {
        this.ranges = response.results.ranges;
      });

      this._vehicleServ.getRangeModels(this.data?.range?.uuid).subscribe((response: any) => {
        this.carModels = response.results.car_models;
      });
    }
  }
  closeModal(event: string): void {
    this.dialogRef.close(event);
  }

  onSubmit() {
    if (this.addVehicleForm.valid) {
      const vehicleAction = this.data ? this._vehicleServ.updateVehicle(this.addVehicleForm.value) : this._vehicleServ.addVehicle(this.addVehicleForm.value);
      vehicleAction.subscribe(
        (response: any) => {
          const message = this.data ? 'Vehicle updated successfully!' : 'Vehicle added successfully!';
          this._snackBar.success(message);
          this.dialogRef.close(this.data ? 'update' : 'add');
        },
        (error) => {
          console.error('Error with vehicle action:', error);
          this._snackBar.error(error.message);
        }
      );
    } else {
      this._snackBar.error('Please fill in all required fields!');
    }
  }

  onSubmitDeviceForm() {
    if (this.addDeviceForm.valid) {
      const deviceAction = this.data?.device ? this._deviceService.updateDevice(this.addDeviceForm.value) : this._deviceService.addDevice(this.addDeviceForm.value);
      deviceAction.subscribe(
        (response: any) => {
          const message = this.data?.device ? 'Device updated successfully!' : 'Device added successfully!';
          this._snackBar.success(message);
          this.dialogRef.close(this.data?.device ? 'update' : 'add');
        },
        (error) => {
          console.error('Error with device action:', error);
          this._snackBar.error(error.message);
        }
      );
    } else {
      this.addDeviceForm.controls['sim_card']?.errors?.['pattern'] ?
        this._snackBar.error('SIM Card number must be 10 digits.') :
        this._snackBar.error('Please fill in all required fields!');
    }
  }

  chosenYearHandler(normalizedYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const year = normalizedYear.year();

    // @ts-ignore
    this.addVehicleForm.get('year').setValue(new Date(year, 0, 1));

    datepicker.close(); // Close the datepicker
  }

  getAllVehicleTypes() {
    this._vehicleServ.getAllVehicleTypes().subscribe((response: any) => {
      this.vehicleTypes = response.results;
    });
  }

  selectVehicleType(vehicle_type_uuid: string) {
    this._vehicleServ.getVehicleTypeMakes(vehicle_type_uuid).subscribe((response: any) => {
      this.makes = response.results;
    });
  }

  checkSelectedVehicleType() {
    if(this.makes.length === 0) {
      this._snackBar.error('Please select the vehicle type first!');
    }
  }

  selectMake(make_uuid: string) {
    this._vehicleServ.getMakeRanges(make_uuid).subscribe((response: any) => {
      this.ranges = response.results.ranges;
    });
  }

  checkSelectedMake() {
    if(this.ranges.length === 0) {
      this._snackBar.error('Please select the vehicle make first!');
    }
  }

  selectRange(range_uuid: string) {
    this._vehicleServ.getRangeModels(range_uuid).subscribe((response: any) => {
      this.carModels = response.results.car_models;
    });
  }

  checkSelectedRange() {
    if(this.carModels.length === 0) {
      this._snackBar.error('Please select the make range of the vehicle first!');
    }
  }

  getFuelTypes() {
    this._vehicleServ.getFuelTypes().subscribe((response: any) => {
      this.fuelTypes = response.results;
    });
  }

  selectTab(tab:string) {
    this.tab = tab;
  }
}
