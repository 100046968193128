export class Form {
    constructor(
        // public MakeRange: string,
        // public VehicleRegistration: string,
        // public DriverName: string,

        // public Odometer: string,
        // public FillingStation: string,
        // public Town: string,

        // public OilCompany: string,
        // public Litres: string,
        // public PricePerLitre: string,

        // public Amount: string,
        // public Time: string,
        // public ReceiptDate: string,
        // public ReceiptNumber: string,


        public action: string,
        public acceptTerms: string,
        public Amount: string,
        public FuelStation: string,
        public fullCompany: any,
        public litrePrice: string,
        public Litres: string,
        public OdoReading: string,
        public ReceiptDate: string,
        public RegistrationNo: string,
        public ReceiptNo: string,
        public DayOfWeek: string,
        public driverName: string,
        public time: string,
        public makeRange: string,
        public town: string,
        public oilCompany: string,
        public companyID: any,
        public num1: string,



    ){

    }
}
