import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/companies/company.service';
import { DriverService } from 'src/app/services/drivers/driver.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-report-setting',
  templateUrl: './report-setting.component.html',
  styleUrls: ['./report-setting.component.css']
})
export class ReportSettingComponent {

  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;

  constructor(
    private _driverServ: DriverService,
    private _snackBar: MatSnackBar,
    private _companyServ: CompanyService,
    public dialogRef: MatDialogRef<ReportSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, { duration: 5000, panelClass: type });
  }
  closeModal(data?: any): void {
    this.dialogRef.close({ event: data });
  }

  isValidForm(){
    if(this.data.form.TopXReport.length < 1){
      (document.querySelector("input[name=TopXReport]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.TopXTransactions.length < 1){
      (document.querySelector("input[name=TopXTransactions]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.MonthlyLitresStart.length < 1){
      (document.querySelector("input[name=MonthlyLitresStart]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.FirstDayOfReport.length < 1){
      (document.querySelector("input[name=FirstDayOfReport]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.NoCalcAbove.length < 1){
      (document.querySelector("input[name=NoCalcAbove]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.NoCalcBelow.length < 1){
      (document.querySelector("input[name=NoCalcBelow]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.OilWarnLimit.length < 1){
      (document.querySelector("input[name=OilWarnLimit]") as HTMLInputElement).focus();
      return false;
    }
    return true;
  }


  onChangeValue(fieldName: string, value: any) {
    this.data.form[fieldName] = value;
  }

  updateReportSettingsResponse = ''; 
  updateReportSettings() {
    if(!this.isValidForm()){
      return;
    }
    let payload = this.data.form;
    this.updateReportSettingsResponse = 'loading';
    
    this._companyServ.updateCompany(payload).subscribe({
      next: (data: any) => {
        if (data.status.message == "success") {
          this.updateReportSettingsResponse = 'success';
          this.dialogRef.close({event:'update', data: payload});
          this.openSnackBar('Successfully updated the report settings', 'Okay', 'success');
        } else {
          this.updateReportSettingsResponse = 'network_err';
          this.openSnackBar(data.status.message, 'Retry', 'error');
        }
      },
      error: (error: any) => {
        this.openSnackBar(error.message, 'Retry', 'error');
        this.updateReportSettingsResponse = 'network_err';
      },
    });
 
  }

}
