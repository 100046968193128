export class SuspectRegistrationNumber {
  private INP1: string;
  private INP2: string;
  private INP3: string;
  private INP4: string;
  private INP5: string;
  private INP6: string;

  constructor(
    registrationNo: string,
    recieptDate: string,
    fuelStation: string,
    litres: string,
    amount: string,
    registrationNumber: string
  ) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = fuelStation;
    this.INP4 = litres;
    this.INP5 = amount;
    this.INP6 = registrationNumber;
  }
}
export class SuspectHighOdo {
  private INP1: string;
  private INP2: string;
  private INP3: string;
  private INP4: string;
  private INP5: string;
  private INP6: string;
  private INP7: string;
  private INP8: string;
  private INP9: string;
  private INP10: string;
  private INP11: string;
  private INP12: string;
  private INP13: string;

  constructor(
    registrationNo: string,
    company: string,
    costCentre: string,
    fleetNumber: string,
    driver: string,
    recieptDate: string,
    receiptTime: string,
    voucher: string,
    currentOdo: string,
    previousOdo: string,
    fuelStation: string,
    litres: string,
    amount: string
  ) {
    this.INP1 = company;
    this.INP2 = costCentre;
    this.INP3 = registrationNo;
    this.INP4 = fleetNumber;
    this.INP5 = driver;
    this.INP6 = recieptDate;
    this.INP7 = receiptTime;
    this.INP8 = voucher;
    this.INP9 = currentOdo;
    this.INP10 = previousOdo;
    this.INP11 = fuelStation;
    this.INP12 = litres;
    this.INP13 = amount;
  }
}
export class SuspectLowConsumption {
  private INP1: string;
  private INP2: string;
  private INP3: string;
  private INP4: string;
  private INP5: string;
  private INP6: string;
  private INP7: string;
  private INP8: string;
  private INP9: string;
  private INP10: string;
  private INP11: string;
  private INP12: string;
  private INP13: string;
  private INP14: string;
  private INP15: string;

  constructor(
    registrationNo: string,
    company: string,
    costCentre: string,
    fleetNumber: string,
    driver: string,
    recieptDate: string,
    receiptTime: string,
    voucher: string,
    currentOdo: string,
    previousOdo: string,
    fuelStation: string,
    litres: string,
    amount: string,
    consumptionL100KM: string,
    model: string
  ) {
    this.INP1 = company;
    this.INP2 = costCentre;
    this.INP3 = registrationNo;
    this.INP4 = fleetNumber;
    this.INP5 = driver;
    this.INP6 = recieptDate;
    this.INP7 = receiptTime;
    this.INP8 = voucher;
    this.INP9 = currentOdo;
    this.INP10 = previousOdo;
    this.INP11 = fuelStation;
    this.INP12 = litres;
    this.INP13 = amount;
    this.INP14 = consumptionL100KM.toString();
    this.INP15 = model.toString();
  }
}
export class IrregularFuelTransactions {
  private INP1: string;
  private INP2: string;
  private INP3: string;
  private INP4: string;
  private INP5: string;
  private INP6: string;
  private INP7: string;
  private INP8: string;
  private INP9: string;
  private INP10: string;
  private INP11: string;
  private INP12: string;
  private INP13: string;
  private INP14: string;
  private INP15: string;
  private INP16: string;
  private INP17: string;
  private INP18: string;
  private INP19: string;
  private INP20: string;
  private INP21: string;

  constructor(
    registrationNo: string,
    company: string,
    costCentre: string,
    fleetNumber: string,
    driver: string,
    recieptDate: string,
    receiptTime: string,
    voucher: string,
    currentOdo: string,
    previousOdo: string,
    tripKM: string,
    fuelStation: string,
    litres: string,
    amount: string,
    consumptionL100KM: string,
    consumptionKML: string,
    vehicleNormL100KM: string,
    vehicleNormKML: string,
    estimatedLLoss: string,
    estimatedRLoss: string,
    cardNo: string
  ) {
    this.INP1 = company;
    this.INP2 = costCentre;
    this.INP3 = registrationNo;
    this.INP4 = fleetNumber;
    this.INP5 = driver;
    this.INP6 = recieptDate;
    this.INP7 = receiptTime;
    this.INP8 = cardNo;
    this.INP9 = voucher;
    this.INP10 = currentOdo;
    this.INP11 = previousOdo;
    this.INP12 = tripKM;
    this.INP13 = fuelStation;
    this.INP14 = litres;
    this.INP15 = amount;
    this.INP16 = consumptionL100KM;
    this.INP17 = consumptionKML;
    this.INP18 = vehicleNormL100KM;
    this.INP19 = vehicleNormKML;
    this.INP20 = estimatedLLoss;
    this.INP21 = estimatedRLoss;
  }
}
export class TankCapacityExceeded {
  private INP1: string;
  private INP2: string;
  private INP3: string;
  private INP4: string;
  private INP5: string;
  private INP6: string;
  private INP7: string;
  private INP8: string;
  private INP9: string;
  private INP10: string;
  private INP11: string;
  private INP12: string;
  private INP13: string;
  private INP14: string;
  private INP15: string;

  constructor(
    registrationNo: string,
    company: string,
    costCentre: string,
    fleetNumber: string,
    driver: string,
    recieptDate: string,
    receiptTime: string,
    voucher: string,
    currentOdo: string,
    previousOdo: string,
    fuelStation: string,
    litres: string,
    amount: string,
    tankCapacity: string,
    overFill: string
  ) {
    this.INP1 = company;
    this.INP2 = costCentre;
    this.INP3 = registrationNo;
    this.INP4 = fleetNumber;
    this.INP5 = driver;
    this.INP6 = recieptDate;
    this.INP7 = receiptTime;
    this.INP8 = fuelStation;
    this.INP9 = voucher;
    this.INP10 = currentOdo.toString();
    this.INP11 = previousOdo.toString();
    this.INP12 = litres.toString();
    this.INP13 = tankCapacity.toString();
    this.INP14 = overFill;
    this.INP15 = amount.toString();
  }
}
export class SuspectLowOdo {
  private INP1: string;
  private INP2: string;
  private INP3: string;
  private INP4: string;
  private INP5: string;
  private INP6: string;
  private INP7: string;
  private INP8: string;
  private INP9: string;
  private INP10: string;
  private INP11: string;
  private INP12: string;
  private INP13: string;

  constructor(
    registrationNo: string,
    company: string,
    costCentre: string,
    fleetNumber: string,
    driver: string,
    recieptDate: string,
    receiptTime: string,
    voucher: string,
    currentOdo: string,
    previousOdo: string,
    fuelStation: string,
    litres: string,
    amount: string
  ) {
    this.INP1 = company;
    this.INP2 = costCentre;
    this.INP3 = registrationNo;
    this.INP4 = fleetNumber;
    this.INP5 = driver;
    this.INP6 = recieptDate;
    this.INP7 = receiptTime;
    this.INP8 = voucher;
    this.INP9 = currentOdo.toString();
    this.INP10 = previousOdo.toString();
    this.INP11 = fuelStation;
    this.INP12 = litres.toString();
    this.INP13 = amount.toString();
  }
}
export class WeekendRefuel {
  private INP1: string;
  private INP2: string;
  private INP3: string;
  private INP4: string;
  private INP5: string;
  private INP6: string;
  private INP7: string;
  private INP8: string;
  private INP9: string;
  private INP10: string;
  private INP11: string;
  private INP12: string;
  private INP13: string;
  private INP14: string;

  constructor(
    registrationNo: string,
    company: string,
    costCentre: string,
    fleetNumber: string,
    driver: string,
    recieptDate: string,
    receiptTime: string,
    voucher: string,
    currentOdo: string,
    previousOdo: string,
    fuelStation: string,
    litres: string,
    amount: string,
    day: string
  ) {
    this.INP1 = company;
    this.INP2 = costCentre;
    this.INP3 = registrationNo;
    this.INP4 = fleetNumber;
    this.INP5 = driver;
    this.INP6 = recieptDate;
    this.INP7 = receiptTime;
    this.INP8 = voucher;
    this.INP9 = currentOdo;
    this.INP10 = previousOdo;
    this.INP11 = fuelStation;
    this.INP12 = litres;
    this.INP13 = amount;
    this.INP14 = day;
  }
}
export class FuelStationEnquiry {
  private INP1: string;
  private INP2: string;
  private INP3: string;
  private INP4: string;
  private INP5: string;
  private INP6: string;
  private INP7: string;
  private INP8: string;
  private INP9: string;
  private INP10: string;
  private INP11: string;
  private INP12: string;
  private INP13: string;
  private INP14: string;

  constructor(
    registrationNo: string,
    company: string,
    costCentre: string,
    fleetNumber: string,
    driver: string,
    recieptDate: string,
    receiptTime: string,
    voucher: string,
    currentOdo: string,
    previousOdo: string,
    fuelStation: string,
    litres: string,
    amount: string,
    cardNo: string,
  ) {
    this.INP1 = company;
    this.INP2 = costCentre;
    this.INP3 = registrationNo;
    this.INP4 = fleetNumber;
    this.INP5 = driver;
    this.INP6 = recieptDate;
    this.INP7 = receiptTime;
    this.INP8 = voucher;
    this.INP9 = cardNo;
    this.INP10 = currentOdo;
    this.INP11 = previousOdo;
    this.INP12 = fuelStation;
    this.INP13 = litres;
    this.INP14 = amount;
  }
}
export class OutOfBoundsFuelStation {
  private INP1: string;
  private INP2: string;
  private INP3: string;
  private INP4: string;
  private INP5: string;
  private INP6: string;
  private INP7: string;
  private INP8: string;
  private INP9: string;
  private INP10: string;
  private INP11: string;
  private INP12: string;
  private INP13: string;
  private INP14: string;
  private INP15: string;
  private INP16: string;
  private INP17: string;
  private INP18: string;
  private INP19: string;

  constructor(
    registrationNo: string,
    company: string,
    costCentre: string,
    fleetNumber: string,
    driver: string,
    recieptDate: string,
    receiptTime: string,
    voucher: string,
    currentOdo: string,
    tripKM: string,
    fuelStation: string,
    litres: string,
    amount: string,
    ppl: string,
    make: string,
    range: string,
    model: string,
    tankCapacity: string,
    fuelType: string
  ) {
    this.INP1 = company;
    this.INP2 = costCentre;
    this.INP3 = registrationNo;
    this.INP4 = fleetNumber;
    this.INP5 = driver;
    this.INP6 = recieptDate;
    this.INP7 = receiptTime;
    this.INP8 = voucher;
    this.INP9 = currentOdo;
    this.INP10 = tripKM;
    this.INP11 = fuelStation;
    this.INP12 = litres;
    this.INP13 = amount;
    this.INP14 = ppl;
    this.INP15 = make;
    this.INP16 = range;
    this.INP17 = model;
    this.INP18 = tankCapacity;
    this.INP19 = fuelType;
  }
}
export class IrregularFuelTransactions2 {
  private INP1: string;
  private INP2: string;
  private INP3: string;
  private INP4: string;
  private INP5: string;
  private INP6: string;
  private INP7: string;
  private INP8: string;
  private INP9: string;
  private INP10: string;
  private INP11: string;
  private INP12: string;
  private INP13: string;
  private INP14: string;
  private INP15: string;
  private INP16: string;
  private INP17: string;
  private INP18: string;
  private INP19: string;
  private INP20: string;
  private INP21: string;
  private INP22: string;
  private INP23: string;
  private INP24: string;
  private INP25: string;

  constructor(
    registrationNo: string,
    company: string,
    costCentre: string,
    fleetNumber: string,
    driver: string,
    recieptDate: string,
    receiptTime: string,
    voucher: string,
    previousOdo: string,
    currentOdo: string,
    tripKM: string,
    fuelStation: string,
    litres: string,
    amount: string,
    cardNo: string,
    calcLitres: string,
    fridgeLitres: string,
    fridgeHours: string,
    consumptionL100KM: string,
    consumtionKML: string,
    vehicleNormL100KM: string,
    vehicleNormKML: string,
    estimatedLLoss: string,
    estimatedRLoss: string
  ) {
    this.INP1 = company;
    this.INP2 = costCentre;
    this.INP3 = registrationNo;
    this.INP4 = fleetNumber;
    this.INP5 = driver;
    this.INP6 = recieptDate;
    this.INP7 = receiptTime;
    this.INP8 = voucher;
    this.INP9 = cardNo;
    this.INP10 = voucher;
    this.INP11 = currentOdo;
    this.INP12 = previousOdo;
    this.INP13 = tripKM;
    this.INP14 = fuelStation;
    this.INP15 = litres;
    this.INP16 = calcLitres;
    this.INP17 = fridgeLitres;
    this.INP18 = fridgeHours;
    this.INP19 = amount;
    this.INP20 = consumptionL100KM;
    this.INP21 = consumtionKML;
    this.INP22 = vehicleNormL100KM;
    this.INP23 = vehicleNormKML;
    this.INP24 = estimatedLLoss;
    this.INP25 = estimatedRLoss;
  }
}
export class PossibleCloneCard {
  private INP1: string;
  private INP2: string;
  private INP3: string;

  constructor(registrationNo: string, recieptDate: string, stop: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = stop;
  }
}
export class MonthlyPermittedLimitExceeded {
  private INP1: string;
  private INP2: string;

  constructor(registrationNo: string, recieptDate: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
  }
}
export class StaticOdoReading {
  private INP1: string;
  constructor(registrationNo: string) {
    this.INP1 = registrationNo;
  }
}
export class FaultyOdoReading {
  private INP1: string;
  constructor(registrationNo: string) {
    this.INP1 = registrationNo;
  }
}
export class DuplicateTransaction {
  private INP1: string;
  private INP2: string;
  private INP3: string;
  private INP4: string;

  constructor(registrationNo: string, recieptDate: string, receiptTime: string,amount: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = receiptTime;
    this.INP4 = amount;
  }
}
export class ConsumptionNormVehicle {
  private INP1: string;

  constructor(registrationNo: string) {
    this.INP1 = registrationNo;
  }
}
export class Comment {
  private INP1: string;

  constructor(registrationNo: string) {
    this.INP1 = registrationNo;
  }
}
export class OdoMaintenance {
  private INP1: string;


  constructor(registrationNo: string) {
    this.INP1 = registrationNo;

  }
}
export class ConfirmCredit {
  private INP1: string;
  private INP2: string;
  private INP3: string;

  constructor(registrationNo: string, recieptDate: string, stop: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = stop;
  }
}
export class SuspectOdoReadings {
  private INP1: string;
  private INP2: string;
  private INP3: string;

  constructor(registrationNo: string, recieptDate: string, stop: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = stop;
  }
}
export class PossibleDuplicateCard {
  private INP1: string;
  private INP2: string;
  private INP3: string;

  constructor(registrationNo: string, recieptDate: string, stop: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = stop;
  }
}
export class PossibleSplit {
  private INP1: string;
  private INP2: string;
  private INP3: string;

  constructor(registrationNo: string, recieptDate: string, stop: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = stop;
  }
}
export class TankNotFull {
  private INP1: string;
  private INP2: string;
  private INP3: string;

  constructor(registrationNo: string, recieptDate: string, stop: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = stop;
  }
}
export class PossibleCalculatedFills {
  private INP1: string;
  private INP2: string;
  private INP3: string;

  constructor(registrationNo: string, recieptDate: string, stop: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = stop;
  }
}
export class BereauServiceWeeklyReport {
  private INP1: string;
  private INP2: string;
  private INP3: string;

  constructor(registrationNo: string, recieptDate: string, stop: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = stop;
  }
}
export class BereauServiceMonthlyReport {
  private INP1: string;
  private INP2: string;
  private INP3: string;
  private INP4: string;
  private INP5: string;
  private INP6: string;
  private INP7: string;

  constructor(
    registrationNo: string,
    fleetNo: string,
    litreLoss: string,
    randLoss: string,
    loss: string,
    fromDate: string,
    toDate: string
  ) {
    this.INP1 = registrationNo;
    this.INP2 = fleetNo;
    this.INP3 = litreLoss;
    this.INP4 = randLoss;
    this.INP5 = loss;
    this.INP6 = toDate;
    this.INP7 = fromDate;
  }
}
export class ReTankCapacityExceeded {
  private INP1: string;
  private INP2: string;
  private INP3: string;

  constructor(registrationNo: string, recieptDate: string, stop: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = stop;
  }
}
export class ReTankCapacity {
  private INP1: string;
  private INP2: string;
  private INP3: string;

  constructor(registrationNo: string, recieptDate: string, stop: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = stop;
  }
}
export class StaticOdoReadingForVehicle {
  private INP1: string;
  private INP2: string;
  private INP3: string;

  constructor(registrationNo: string, recieptDate: string, stop: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = stop;
  }
}
export class PossibleCloneCardForVehicle {
  private INP1: string;
  private INP2: string;
  private INP3: string;

  constructor(registrationNo: string, recieptDate: string, stop: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = stop;
  }
}
export class ReDuplicateTransactionForVehicle {
  private INP1: string;
  private INP2: string;
  private INP3: string;

  constructor(registrationNo: string, recieptDate: string, stop: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = stop;
  }
}
export class UnknownRegistration {
  private INP1: string;
  private INP2: string;
  private INP3: string;

  constructor(registrationNo: string, recieptDate: string, stop: string) {
    this.INP1 = registrationNo;
    this.INP2 = recieptDate;
    this.INP3 = stop;
  }
}
