import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileImportComponent } from './core/modals/file-import/file-import.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CaptureTransactionComponent } from './core/modals/capture-transaction/capture-transaction.component';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ViewTransactionComponent } from './core/modals/view-transaction/view-transaction.component';
import { ExportComponent } from './core/modals/export/export.component';
import { ViewCompanyComponent } from './core/modals/view-company/view-company.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { RecalculateComponent } from './core/modals/recalculate/recalculate.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CombineTransactionComponent } from './core/modals/combine-transaction/combine-transaction.component';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmailButtonsComponent } from './core/modals/email-buttons/email-buttons.component';
import { HashLocationStrategy, LocationStrategy, NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { HttpClientModule } from '@angular/common/http';
import { MatExpansionModule } from '@angular/material/expansion';
import { ActionButtonsComponent } from './core/modals/action-buttons/action-buttons.component';
import { SelectCompanyComponent } from './core/modals/select-company/select-company.component';
import { ViewVehicleComponent } from './core/modals/view-vehicle/view-vehicle.component';
import { AddVehicleComponent } from './core/modals/add-vehicle/add-vehicle.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ViewBillingAccountComponent } from './core/modals/view-billing-account/view-billing-account.component';
import { AddBillingAccountComponent } from './core/modals/add-billing-account/add-billing-account.component';
import { ViewDriverComponent } from './core/modals/view-driver/view-driver.component';
import { AddDriverComponent } from './core/modals/add-driver/add-driver.component';
import { ViewFuelStationComponent } from './core/modals/view-fuel-station/view-fuel-station.component';
import { AddFuelStationComponent } from './core/modals/add-fuel-station/add-fuel-station.component';
import { ViewReportComponent } from './core/modals/view-report/view-report.component';
import { ImportTransactionFileComponent } from './core/modals//import-transaction-file/import-transaction-file.component';
import { MatRippleModule } from '@angular/material/core';
import { HotTableModule } from '@handsontable/angular';
import { registerAllModules } from 'handsontable/registry';
import { UpdateVehicleComponent } from './core/modals/update-vehicle/update-vehicle.component';
import { UpdateDriverComponent } from './core/modals/update-driver/update-driver.component';
import { AssignUserCompaniesComponent } from './core/modals/assign-user-companies/assign-user-companies.component';
import { LOCALE_ID } from '@angular/core';
import { ConfirmationDialogComponent } from './core/modals/confirmation-dialog/confirmation-dialog.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HtmlPreviewPipe } from './pipes/html-preview.pipe';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { VehicleDriverComponent } from './core/modals/vehicle-driver/vehicle-driver.component';
import {MatTabsModule} from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { EditTransactionComponent } from './core/modals/edit-transaction/edit-transaction.component';
import { MailsConfigurationsComponent } from './core/modals/mails-configurations/mails-configurations.component';
import { SpecialProceduresComponent } from './core/modals/special-procedures/special-procedures.component';

import { ReportSettingComponent } from './core/modals/reports/report-setting/report-setting.component';
import {MatCardModule} from '@angular/material/card';
import { SetupInsertEmailComponent } from './core/modals/setup-insert-email/setup-insert-email.component'
import { MatDialogModule } from '@angular/material/dialog';
import { ViewUpdateAccountBillingComponent } from './core/modals/view-update-account-billing/view-update-account-billing.component';
import { GenerateMonthlyBillingComponent } from './core/modals/generate-monthly-billing/generate-monthly-billing.component';
import { CreateBillingBatchComponent } from './core/modals/create-billing-batch/create-billing-batch.component';
import { IfmReportsPrepareComponent } from './core/modals/reports/ifm-reports-prepare/ifm-reports-prepare.component';
import { OperatorsReportComponent } from './core/modals/reports/operators-report/operators-report.component';
import { ReportResultsPerVehicleComponent } from './core/modals/reports/report-results-per-vehicle/report-results-per-vehicle.component';
import { ScorecardParametersComponent } from './core/modals/reports/scorecard-parameters/scorecard-parameters.component';
import { ScorecardParamaterConfigComponent } from './core/modals/reports/scorecard-parameters/scorecard-paramater-config/scorecard-paramater-config.component';
import { CompanyConfigComponent } from './core/modals/company-config/company-config.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { UserConfigComponent } from './core/modals/user-config/user-config.component';
import { RoleConfigComponent } from "./core/modals/role-config/role-config.component";
import {MAT_MOMENT_DATE_FORMATS, MomentDateModule} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

// register Handsontable's modules
registerAllModules();

@NgModule({
  declarations: [
    AppComponent,
    FileImportComponent,
    CaptureTransactionComponent,
    ViewTransactionComponent,
    ExportComponent,
    RecalculateComponent,
    CombineTransactionComponent,
    EmailButtonsComponent,
    ActionButtonsComponent,
    SelectCompanyComponent,
    ViewCompanyComponent,
    ViewVehicleComponent,
    AddVehicleComponent,
    ViewBillingAccountComponent,
    AddBillingAccountComponent,
    ViewDriverComponent,
    AddDriverComponent,
    ViewFuelStationComponent,
    AddFuelStationComponent,
    ViewReportComponent,
    ImportTransactionFileComponent,
    UpdateVehicleComponent,
    UpdateDriverComponent,
    AssignUserCompaniesComponent,
    ConfirmationDialogComponent,
    HtmlPreviewPipe,
    VehicleDriverComponent,
    EditTransactionComponent,
    MailsConfigurationsComponent,
    SpecialProceduresComponent,
    ReportSettingComponent,
    SetupInsertEmailComponent,
    ViewUpdateAccountBillingComponent,
    GenerateMonthlyBillingComponent,
    CreateBillingBatchComponent,
    IfmReportsPrepareComponent,
    OperatorsReportComponent,
    ReportResultsPerVehicleComponent,
    ScorecardParametersComponent,
    ScorecardParamaterConfigComponent,
    CompanyConfigComponent,
    UserConfigComponent,
    RoleConfigComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    NgxFileDropModule,
    MatMenuModule,
    MatProgressBarModule,
    MatRadioModule,
    MatCardModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTableModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSelectModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRippleModule,
    HotTableModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatFormFieldModule,
    MatChipsModule,
    NgFor,
    AngularEditorModule,
    MatTabsModule,
    MatAutocompleteModule,
    DragDropModule,
    MatProgressSpinnerModule,
    MomentDateModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
