<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="lft">
            <p>Scorecard Parameters</p>
            <p>Add description here</p>
        </div>
        <div class="rgt">
            <button mat-flat-button (click)="closeModal()">
                <i class="fa-solid fa-xmark"></i>
            </button>
        </div>
    </div>

    <div class="modal-contain">
        <div class="myuitabs">
            <div class="lft">
                <button mat-stroked-button [ngClass]="tab==='1'?'active':''" (click)="selectTab('1')" type="button"><p>Company Id</p></button>
                <button mat-stroked-button [ngClass]="tab==='2'?'active':''" (click)="selectTab('2')" type="button"><p>Customer</p></button>
                <button mat-stroked-button [ngClass]="tab==='3'?'active':''" (click)="selectTab('3')" type="button"><p>Account</p></button>
                <button mat-stroked-button [ngClass]="tab==='4'?'active':''" (click)="selectTab('4')" type="button"><p>Cost Centre</p></button>
                <button mat-stroked-button [ngClass]="tab==='5'?'active':''" (click)="selectTab('5')" type="button"><p>Division</p></button>
                <button mat-stroked-button [ngClass]="tab==='6'?'active':''" (click)="selectTab('6')" type="button"><p>Vehicle</p></button>
            </div>
            <div class="rgt">
                <button mat-flat-button color="primary" type="button" (click)="openScoreCardParamaterConfig('create')"><p>Add new <i class="fas fa-plus"></i></p></button>
            </div>
        </div>
        <form class="myform">
            <div class="modal-tbl">
                <div class="mytable">
                    <div class="table-skeleton anime-skeleton" *ngIf="response == 'loading'">
                        <div class="mywrapper">
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                            <div class="tr"><p></p><p></p></div>
                        </div>
                    </div>
                    <div class="myfeedback" *ngIf="dataSource.length<1 && response!= 'loading'">
                        <div class="wrapp">
                            <i class="fa-regular fa-folder-open"></i>
                            <p>No results, no records retrieved.</p>
                        </div>
                    </div>
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="response == 'success'">
                        <ng-container matColumnDef="CompanyId">
                            <th mat-header-cell *matHeaderCellDef>CompanyId</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.CompanyId }}</td>
                        </ng-container>
                        <ng-container matColumnDef="CompanyName">
                            <th mat-header-cell *matHeaderCellDef>CompanyName</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.CompanyName }}</td>
                        </ng-container>
                        <ng-container matColumnDef="AS400CUSTOMERID">
                            <th mat-header-cell *matHeaderCellDef>AS400CUSTOMERID</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.AS400CUSTOMERID }}</td>
                        </ng-container>
                        <ng-container matColumnDef="AS400ACCOUNTID">
                            <th mat-header-cell *matHeaderCellDef>AS400ACCOUNTID</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.AS400ACCOUNTID }}</td>
                        </ng-container>
                        <ng-container matColumnDef="AS400COSTCENTREID">
                            <th mat-header-cell *matHeaderCellDef>AS400COSTCENTREID</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.AS400COSTCENTREID }}</td>
                        </ng-container>
                        <ng-container matColumnDef="DivisionName">
                            <th mat-header-cell *matHeaderCellDef>DivisionName</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.DivisionName }}</td>
                        </ng-container>
                        <ng-container matColumnDef="AS400MVA">
                            <th mat-header-cell *matHeaderCellDef>AS400MVA</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.AS400MVA }}</td>
                        </ng-container>
                        <ng-container matColumnDef="IfmId">
                            <th mat-header-cell *matHeaderCellDef>IfmId</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.IfmId }}</td>
                        </ng-container>
                        <ng-container matColumnDef="Level123456">
                            <th mat-header-cell *matHeaderCellDef>Level123456</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.Level123456 }}</td>
                        </ng-container>
                        <ng-container matColumnDef="NormalWeekend">
                            <th mat-header-cell *matHeaderCellDef>NormalWeekend</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.NormalWeekend }}</td>
                        </ng-container>
                        <ng-container matColumnDef="ExtendedWeekend">
                            <th mat-header-cell *matHeaderCellDef>ExtendedWeekend</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.ExtendedWeekend }}</td>
                        </ng-container>
                        <ng-container matColumnDef="MonthlyLitresSet">
                            <th mat-header-cell *matHeaderCellDef>MonthlyLitresSet</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.MonthlyLitresSet }}</td>
                        </ng-container>
                        <ng-container matColumnDef="FS_OutOfBounds">
                            <th mat-header-cell *matHeaderCellDef>FS_OutOfBounds</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.FS_OutOfBounds }}</td>
                        </ng-container>
                        <ng-container matColumnDef="SameDayRefill">
                            <th mat-header-cell *matHeaderCellDef>SameDayRefill</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.SameDayRefill }}</td>
                        </ng-container>
                        <ng-container matColumnDef="TankCapExceed">
                            <th mat-header-cell *matHeaderCellDef>TankCapExceed</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.TankCapExceed }}</td>
                        </ng-container>
                        <ng-container matColumnDef="DriverResponse">
                            <th mat-header-cell *matHeaderCellDef>DriverResponse</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.DriverResponse }}</td>
                        </ng-container>
                        <ng-container matColumnDef="MonthlyKmSet">
                            <th mat-header-cell *matHeaderCellDef>MonthlyKmSet</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.MonthlyKmSet }}</td>
                        </ng-container>
                        <ng-container matColumnDef="CashTrans">
                            <th mat-header-cell *matHeaderCellDef>CashTrans</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.CashTrans }}</td>
                        </ng-container>
                        <ng-container matColumnDef="LossesPer">
                            <th mat-header-cell *matHeaderCellDef>LossesPer</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.LossesPer }}</td>
                        </ng-container>
                        <ng-container matColumnDef="NormalWeekendPer">
                            <th mat-header-cell *matHeaderCellDef>NormalWeekendPer</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.NormalWeekendPer }}</td>
                        </ng-container>
                        <ng-container matColumnDef="ExtendedWeekendPer">
                            <th mat-header-cell *matHeaderCellDef>ExtendedWeekendPer</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.ExtendedWeekendPer }}</td>
                        </ng-container>
                        <ng-container matColumnDef="MonthlyLitresSetPer">
                            <th mat-header-cell *matHeaderCellDef>MonthlyLitresSetPer</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.MonthlyLitresSetPer }}</td>
                        </ng-container>
                        <ng-container matColumnDef="FS_OutOfBoundsPer">
                            <th mat-header-cell *matHeaderCellDef>FS_OutOfBoundsPer</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.FS_OutOfBoundsPer }}</td>
                        </ng-container>
                        <ng-container matColumnDef="SameDayRefillPer">
                            <th mat-header-cell *matHeaderCellDef>SameDayRefillPer</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.SameDayRefillPer }}</td>
                        </ng-container>
                        <ng-container matColumnDef="TankCapExceedPer">
                            <th mat-header-cell *matHeaderCellDef>TankCapExceedPer</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.TankCapExceedPer }}</td>
                        </ng-container>
                        <ng-container matColumnDef="DriverResponsePer">
                            <th mat-header-cell *matHeaderCellDef>DriverResponsePer</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.DriverResponsePer }}</td>
                        </ng-container>
                        <ng-container matColumnDef="MonthlyKmSetPer">
                            <th mat-header-cell *matHeaderCellDef>MonthlyKmSetPer</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.MonthlyKmSetPer }}</td>
                        </ng-container>
                        <ng-container matColumnDef="CashTransPer">
                            <th mat-header-cell *matHeaderCellDef>CashTransPer</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.CashTransPer }}</td>
                        </ng-container>
                        <ng-container matColumnDef="MaxLitres">
                            <th mat-header-cell *matHeaderCellDef>MaxLitres</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.MaxLitres }}</td>
                        </ng-container>
                        <ng-container matColumnDef="MaxKM">
                            <th mat-header-cell *matHeaderCellDef>MaxKM</th>
                            <td mat-cell *matCellDef="let row" (click)="ClickRow($event, row)">{{ row.MaxKM }}</td>
                        </ng-container>

                        <!-- ACTION Column -->
                        <ng-container matColumnDef="ACTION" stickyEnd>
                            <th mat-header-cell *matHeaderCellDef aria-label="row actions">
                            SELECT
                            </th>
                            <td mat-cell *matCellDef="let row">
                            <mat-checkbox
                                [checked]="selected_records.indexOf(row) !== -1 ? true : false"
                                color="primary"
                                (change)="markRecord(row)"
                            ></mat-checkbox>
                            </td>
                        </ng-container>

                        <tr
                            mat-header-row
                            *matHeaderRowDef="
                            displayedColumns;
                            sticky: true
                            "
                        ></tr>
                        <tr
                        mat-row
                        (contextmenu)="onRightClick($event, row)"
                        [class]="selected_records.indexOf(row) !== -1 ? 'marked' : ''"
                        *matRowDef="let row; columns: displayedColumns"
                        ></tr>
                    </table>
                </div>
            </div>
        </form>
    </div>
</div>

  
<!-- Right Clicked Menu -->
<div
style="visibility: hidden; position: fixed"
[style.left]="menuTopLeftPosition.x + 'px'"
[style.top]="menuTopLeftPosition.y + 'px'"
[matMenuTriggerFor]="RightClickedMenu"
#MoreBillingMenu="matMenuTrigger"
></div>

<mat-menu #RightClickedMenu="matMenu" class="myright-clicked-menu">
<ng-template matMenuContent *ngIf="selected_records.length < 2">
    <button mat-menu-item >
        <div class="details" (click)="openScoreCardParamaterConfig('update')">
            <p>View</p>
            <i class="fa-regular fa-eye"></i>
        </div>
    </button>
    <hr />
    <button mat-menu-item (click)="openScoreCardParamaterConfig('update')">
        <div class="details">
            <p>Update</p>
            <i class="fa-solid fa-pen-to-square"></i>
        </div>
    </button>
    <hr />
    <button mat-menu-item>
        <div class="details">
            <p>Export</p>
            <i class="fa-solid fa-file-export"></i>
        </div>
    </button>
    <hr />
    <button mat-menu-item>
        <div class="details">
            <p>Delete</p>
            <i class="fa-solid fa-trash"></i>
        </div>
    </button>
</ng-template>

<ng-template matMenuContent *ngIf="selected_records.length > 1">
    <button mat-menu-item>
    <div class="details">
        <p>Export selected ({{selected_records.length}})</p>
        <i class="fa-solid fa-file-export"></i>
    </div>
    </button>
    <hr />
    <button mat-menu-item>
    <div class="details">
        <p>Delete</p>
        <i class="fa-solid fa-trash"></i>
    </div>
    </button>
</ng-template>
</mat-menu>

