<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="lft">
        <p class="FxLine">
            Assign Companies
        </p>
      </div>
      <div class="rgt">
        <button mat-flat-button (click)="closeModal()" cdkFocusInitial>
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>

    <div class="modal-contain">
      <!-- <p>
            {{data | json}}
        </p> -->
      <form class="myform" >
        <div class="card001">
            <div class="content">
                <div class="circle">
                    <p>{{data.USERNAME.substring(0,1)}}</p>
                </div>
                <div class="details">
                    <p>{{data.USERNAME}}</p>
                    <p><a href="mailto:">{{data.EmailAddress}}</a></p>
                </div>
            </div>
            <p class="caption">Assigned Companies
                <ng-container *ngIf="fetch_user_companies_response==='success'" >
                    ({{user_companies.length}})
                </ng-container>

            </p>
        </div>
        <hr class="myborder"/>
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="card002">
                    <div class="searchbox">
                        <i class="fa-solid fa-magnifying-glass"></i>
                        <input type="text" placeholder="All Companies" (keyup)="search_all_companies($event.target)"/>
                    </div>
                    <div class="contain">
                        <ng-container *ngIf="fetch_all_companies_response==='no_results'">
                            <div class="_feeback">
                                <div class="containn">
                                    <i class="fa-regular fa-folder-open"></i>
                                    <p>No results, no companies found</p>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="fetch_all_companies_response==='success'" >
                            <ng-container *ngFor="let r of all_companies" >
                                <div class="card" *ngIf="!checkAssigned(r.company_id) && all_company_string!=='' && r.COMPANYNAME.toLowerCase().includes(all_company_string)">
                                    <div class="lft">
                                        <i class="fa fa-building"></i>
                                        <p>{{r.COMPANYNAME}}</p>
                                    </div>
                                    <div class="rgt">
                                        <button mat-flat-button color="primary" (click)="Assign(r)">Assign</button>
                                    </div>
                                </div>
                                <div class="card" *ngIf="!checkAssigned(r.company_id) && all_company_string===''">
                                    <div class="lft">
                                        <i class="fa fa-building"></i>
                                        <p>{{r.COMPANYNAME}}</p>
                                    </div>
                                    <div class="rgt">
                                        <button mat-flat-button color="primary" (click)="Assign(r)">Assign</button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="fetch_user_companies_response==='loading'">
                            <div class="card002-skeleton anime-skeleton">
                                <div class=""></div>
                                <p></p>
                            </div>
                            <div class="card002-skeleton anime-skeleton">
                                <div class=""></div>
                                <p></p>
                            </div>
                            <div class="card002-skeleton anime-skeleton">
                                <div class=""></div>
                                <p></p>
                            </div>
                            <div class="card002-skeleton anime-skeleton">
                                <div class=""></div>
                                <p></p>
                            </div>
                            <div class="card002-skeleton anime-skeleton">
                                <div class=""></div>
                                <p></p>
                            </div>
                            <div class="card002-skeleton anime-skeleton">
                                <div class=""></div>
                                <p></p>
                            </div>
                        </ng-container>



                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card002">
                    <div class="searchbox">
                        <i class="fa-solid fa-magnifying-glass"></i>
                        <input type="text" placeholder="Assigned Companies" (keyup)="search_assigned_companies($event.target)"/>
                    </div>
                    <div class="contain">
                        <ng-container *ngIf="fetch_user_companies_response==='no_results' || (fetch_user_companies_response!=='loading' && user_companies.length<1)">
                            <div class="_feeback">
                                <div class="containn">
                                    <i class="fa-regular fa-folder-open"></i>
                                    <p><b>{{data.USERNAME}}</b> don't have companies.</p>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="fetch_user_companies_response==='success'">

                            <ng-container *ngFor="let r of user_companies;let i = index">
                                <div class="card" *ngIf="checkAssigned(r.company_id) && assigned_companies_search!=='' && r.COMPANYNAME.toLowerCase().includes(assigned_companies_search)">
                                    <div class="lft">
                                        <i class="fa fa-building"></i>
                                        <p>{{r.COMPANYNAME}}</p>
                                    </div>
                                    <div class="rgt">
                                        <button mat-stroked-button (click)="unAssign(i,r)">Unassign</button>
                                    </div>
                                </div>
                                <div class="card" *ngIf="checkAssigned(r.company_id) && assigned_companies_search===''">
                                    <div class="lft">
                                        <i class="fa fa-building"></i>
                                        <p>{{r.COMPANYNAME}}</p>
                                    </div>
                                    <div class="rgt">
                                        <button mat-stroked-button (click)="unAssign(i,r)">Unassign</button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="fetch_user_companies_response==='loading'">
                            <div class="card002-skeleton anime-skeleton">
                                <div class=""></div>
                                <p></p>
                            </div>
                            <div class="card002-skeleton anime-skeleton">
                                <div class=""></div>
                                <p></p>
                            </div>
                            <div class="card002-skeleton anime-skeleton">
                                <div class=""></div>
                                <p></p>
                            </div>
                            <div class="card002-skeleton anime-skeleton">
                                <div class=""></div>
                                <p></p>
                            </div>
                            <div class="card002-skeleton anime-skeleton">
                                <div class=""></div>
                                <p></p>
                            </div>
                            <div class="card002-skeleton anime-skeleton">
                                <div class=""></div>
                                <p></p>
                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>


        </div>

      </form>
    </div>
  </div>
