
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-ifm-reports-prepare',
  templateUrl: './ifm-reports-prepare.component.html',
  styleUrls: ['./ifm-reports-prepare.component.css']
})
export class IfmReportsPrepareComponent {
  constructor(
    public dialogRef: MatDialogRef<IfmReportsPrepareComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    closeModal(): void {
      this.dialogRef.close();
    }

    displayedColumns: string[] = [
      'REGISTRATION_NO',
      'DATE',
      'TIME',
      'ERROR',
    ];
    dataSource = [
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
      {
        'REGISTRATION_NO':"GT3233",
        'DATE': "2024-01-20",
        'TIME':"21H00",
        'ERROR': "NO ERROR",
      },
    
    ];
}



