import { Component } from '@angular/core';

@Component({
  selector: 'app-create-billing-batch',
  templateUrl: './create-billing-batch.component.html',
  styleUrls: ['./create-billing-batch.component.css']
})
export class CreateBillingBatchComponent {

}
