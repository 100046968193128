import { Component, LOCALE_ID, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Form } from './model/Form';
import { TransactionService } from '../../../services/transactions/transaction.service';
import { CompanyService } from 'src/app/services/companies/company.service';
import { VehicleService } from 'src/app/services/vehicles/vehicle.service';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';
import { FuelStationService } from 'src/app/services/fuel-stations/fuel-station.service';
import { DataTransformationsService } from 'src/app/services/utilities/data/data-transformations.service';
@Component({
  selector: 'app-capture-transaction',
  templateUrl: './capture-transaction.component.html',
  styleUrls: ['./capture-transaction.component.css'],
})
export class CaptureTransactionComponent {
  modalStyle: any;
  modalTitle: any;
  modalBody: any;
  modalButtonColor: any;
  getConfirmationValue($event: string) {
    throw new Error('Method not implemented.');
  }
  constructor(
    public dialogRef: MatDialogRef<CaptureTransactionComponent>,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: SnackbarsService,
    private service: TransactionService,
    private _vehicleService: VehicleService,
    private _companyServ: CompanyService,
    private _fuelStationServ: FuelStationService,
    private _dataServ: DataTransformationsService
  ) {}

  companyName = this._companyServ.getCompanyName();

  showing = 'form';
  closeModal(): void {
    this.dialogRef.close();
  }

  FormModel = new Form(
    'INSERT', // action,
    '', // acceptTerms,
    '', // Amount,
    '', // FuelStation,
    '', // fullCompany
    '', // litrePrice
    '', // Litres
    '', // OdoReading
    '', // ReceiptDate
    '', // RegistrationNo
    '', // ReceiptNo
    '', // DayOfWeek
    '', // driverName
    '', // time
    '', // makeRange
    '', // town
    '', // oilCompany
    '', // companyID
    '' // num1
  );
  formatDecimals(
    string: any,
    decimals = 2,
    decimal = ',',
    thousands = '.',
    pre = '',
    pos = ''
  ) {
    let numbers = string.toString().match(/\d+/g).join([]);
    numbers = numbers.padStart(decimals + 1, '0');
    const splitNumbers = numbers.split('').reverse();
    let mask = '';
    splitNumbers.forEach(function (d: any, i: any) {
      if (i == decimals) {
        mask = decimal + mask;
      }
      if (i > decimals + 1 && (i - 2) % (decimals + 1) == 0) {
        mask = thousands + mask;
      }
      mask = d + mask;
    });
    return pre + mask + pos;
  }
  validateOilCompany(event: any) {
    const value = event.value;
    if (value.length <= 100) {
      this.FormModel.makeRange = value;
    } else {
      this.FormModel.makeRange = value.substr(0, 100);
      this._snackBar.error('Max length exceeded (100/100)');
    }
  }

  validateReceiptNumber(event: any) {
    const value = event.value;
    if (value.length <= 10) {
      this.FormModel.ReceiptNo = value.toUpperCase();
    } else {
      this.FormModel.ReceiptNo = value.toUpperCase().substr(0, 20);
      this._snackBar.error('Max length exceeded (20/20)');
    }
  }
  validateDriverName(event: any) {
    const value = event.value;

    if (!/^[a-z][a-z\s]*$/i.test(value)) {
      this.FormModel.driverName = value.replace(/[^a-zA-Z ]/g, '');
      this._snackBar.error('Max length exceeded (15/15)');
    } else {
      if (value.length <= 30) {
        this.FormModel.driverName = value;
      } else {
        this.FormModel.driverName = value.substr(0, 30);
        this._snackBar.error('Max length exceeded (30/30)');
      }
    }
  }

  validateOdometer(event: any) {
    const value = event.value;
    if (value.length <= 15) {
      const newValue = this.formatDecimals(value, 3, ',', '').replace(',', '.');
      this.FormModel.OdoReading = Number(newValue).toFixed(3);
    } else {
      this.FormModel.OdoReading = value.substr(0, 15);
      this._snackBar.error('Max length exceeded (15/15)');
    }
  }
  TimeFormat = 'AM';
  validateTime(event: any) {
    const value = event.value;
    const hours = Number(value.substr(0, 2));
    if (hours >= 12) {
      this.TimeFormat = 'PM';
    } else {
      this.TimeFormat = 'AM';
    }
  }
  selectFuelStation(event: any) {
    const value = event.value;
    this.FormModel.FuelStation = value;
  }
  validateRegistrationNo(event: any) {
    const value = event.value;
    this.FormModel.RegistrationNo = value;
  }
  selectTown(event: any) {
    const value = event.value;
    this.FormModel.town = value;
  }
  selectOilCompany(event: any) {
    const value = event.value;
    this.FormModel.oilCompany = value;
  }

  validateLitres(event: any) {
    const value = event.value;
    if (value.length <= 15) {
      const newValue = this.formatDecimals(value, 2, ',', '').replace(',', '.');
      this.FormModel.Litres = Number(newValue).toFixed(2);

      const Litres = Number(this.FormModel.Litres);
      const PricePerLitre = Number(this.FormModel.litrePrice);
      const Amount = (Litres * PricePerLitre).toFixed(2);
      this.FormModel.Amount = Amount.toString();
    } else {
      this.FormModel.Litres = value.substr(0, 15);
      this._snackBar.error('Max length exceeded (15/15)');
    }
  }
  validatePricePerLitre(event: any) {
    const value = event.value;
    if (value.length <= 10) {
      const newValue = this.formatDecimals(value, 2, ',', '').replace(',', '.');
      this.FormModel.litrePrice = Number(newValue).toFixed(2);

      const Litres = Number(this.FormModel.Litres);
      const PricePerLitre = Number(this.FormModel.litrePrice);
      const Amount = (Litres * PricePerLitre).toFixed(2);
      this.FormModel.Amount = Amount.toString();
    } else {
      this.FormModel.litrePrice = value.substr(0, 10);
      this._snackBar.error('Max length exceeded (10/10)');
    }
  }
  validateAmount(event: any) {
    const value = event.value;
    if (value.length <= 10) {
      const newValue = this.formatDecimals(value, 2, ',', '').replace(',', '.');
      this.FormModel.Amount = Number(newValue).toFixed(2);
    } else {
      this.FormModel.Amount = value.substr(0, 10);
      this._snackBar.error('Max length exceeded (15/15)');
    }
  }

  TranscationList: any = [];
  SelectedIndex: any = null;

  AddTranscation() {
    if (this.FormModel.RegistrationNo.length < 1) {
      (
        document.querySelector(
          'mat-select[name="CaptureRegistrationNo"]'
        ) as HTMLInputElement
      ).focus();
    } else if (this.FormModel.driverName.length < 1) {
      (document.querySelector('input[name="CaptureDriverName"]') as HTMLInputElement).focus();
    } else if (this.FormModel.ReceiptNo.length < 1) {
      (
        document.querySelector('input[name="CaptureReceiptNo"]') as HTMLInputElement
      ).focus();
    } else if (this.FormModel.FuelStation.length < 1) {
      (
        document.querySelector('#CaptureSelectFuelStation') as HTMLInputElement
      ).focus();
    } else if (this.FormModel.town.length < 1) {
      (document.querySelector('#CaptureTown') as HTMLInputElement).focus();
    } else if (this.FormModel.oilCompany.length < 1) {
      (
        document.querySelector('input[name="CaptureOilCompany"]') as HTMLInputElement
      ).focus();
    }else if (this.FormModel.Litres.length < 1) {
      (
        document.querySelector('input[name="CaptureLitres"]') as HTMLInputElement
      ).focus();
    } else if (this.FormModel.litrePrice.length < 1) {
      (
        document.querySelector('input[name="CapturePricePerLitre"]') as HTMLInputElement
      ).focus();
    } else if (this.FormModel.Amount.length < 1) {
      (
        document.querySelector('input[name="CaptureAmount"]') as HTMLInputElement
      ).focus();
    } else if (this.FormModel.OdoReading.length < 1) {
      (
        document.querySelector('input[name="CaptureOdoReading"]') as HTMLInputElement
      ).focus();
    } else if (this.FormModel.time.length < 1) {
      (
        document.querySelector('input[name="CaptureTime"]') as HTMLInputElement
      ).focus();
    } else if (this.FormModel.ReceiptDate.length < 1) {
      (
        document.querySelector('input[name="CaptureReceiptDate"]') as HTMLInputElement
      ).focus();
    } else {
      this.FormModel.ReceiptDate = this.transformDate(
        this.FormModel.ReceiptDate
      );

      const obj = JSON.parse(JSON.stringify(this.FormModel));

      if (this.showing == 'editing_transcation') {
        this.TranscationList[this.SelectedIndex] = obj;
      } else {
        this.TranscationList.push(obj);
      }
      this.showing = 'transcations_list';
    }
  }

  EditTranscation(index: any) {
    this.showing = 'editing_transcation';
    this.SelectedIndex = index;
    this.FormModel.makeRange = this.TranscationList[index].makeRange;
    this.FormModel.RegistrationNo = this.TranscationList[index].RegistrationNo;
    this.FormModel.driverName = this.TranscationList[index].driverName;
    this.FormModel.OdoReading = this.TranscationList[index].OdoReading;
    this.FormModel.FuelStation = this.TranscationList[index].FuelStation;
    this.FormModel.town = this.TranscationList[index].town;
    this.FormModel.oilCompany = this.TranscationList[index].oilCompany;
    this.FormModel.Litres = this.TranscationList[index].Litres;
    this.FormModel.litrePrice = this.TranscationList[index].litrePrice;
    this.FormModel.Amount = this.TranscationList[index].Amount;
    this.FormModel.time = this.TranscationList[index].time;
    this.FormModel.ReceiptNo = this.TranscationList[index].ReceiptNo;
    this.FormModel.ReceiptDate = this._dataServ.formatDate(
      this.TranscationList[index].ReceiptDate
    );
  }

  private transformDate(date: string) {
    let new_date = new Date(this.FormModel.ReceiptDate);
    // Get year, month, and day part from the date
    var year = new_date.toLocaleString('default', { year: 'numeric' });
    var month = new_date.toLocaleString('default', { month: '2-digit' });
    var day = new_date.toLocaleString('default', { day: '2-digit' });
    return year + '-' + month + '-' + day;
  }

  RemoveTranscation(index: any) {
    this.TranscationList.splice(index, 1);
    if (this.TranscationList.length < 1) {
      this.FormModel.makeRange = '';
      this.FormModel.RegistrationNo = '';
      this.FormModel.driverName = '';
      this.FormModel.OdoReading = '';
      this.FormModel.FuelStation = '';
      this.FormModel.town = '';
      this.FormModel.oilCompany = '';
      this.FormModel.Litres = '';
      this.FormModel.litrePrice = '';
      this.FormModel.Amount = '';
      this.FormModel.time = '';
      this.FormModel.ReceiptNo = '';
      this.FormModel.ReceiptDate = '';
      this.showing = 'form';
    }
  }
  AddMore() {
    this.showing = 'adding_more';
    this.FormModel.makeRange = '';
    this.FormModel.RegistrationNo = '';
    this.FormModel.driverName = '';
    this.FormModel.OdoReading = '';
    this.FormModel.FuelStation = '';
    this.FormModel.town = '';
    this.FormModel.oilCompany = '';
    this.FormModel.Litres = '';
    this.FormModel.litrePrice = '';
    this.FormModel.Amount = '';
    this.FormModel.time = '';
    this.FormModel.ReceiptNo = '';
    this.FormModel.ReceiptDate = '';
  }
  ViewTranscationList() {
    this.showing = 'transcations_list';
  }
  response = '';
  SubmitTranscation() {
    if (this.TranscationList.length > 0) {
      this.response = 'loading';
      const payload = this.TranscationList;
      this.service.PostFuelTransactions(payload,this._companyServ.getCompanyId(),this.getFileName()).subscribe({
          next: (data) => {
            this.closeModal();
            if(data.statusMessage.includes('successfully')){
              this._snackBar.success('Successfully Processed');
              document.getElementById("reload_transaction_table")!.click();
              document.getElementById("reload_recon_report")!.click();
              this.response = 'success';
            }else{
              this._snackBar.error('Something went wrong');
            }
          },
        });
    } else {
      this._snackBar.error('No captured transcations');
    }
  }

  // Fetch Fuel Stations
  fetch_fuel_stations_response = 'loading';
  fetch_fuel_stations_data: any = [];
  fetch_fuel_stations() {
    this.fetch_fuel_stations_response = 'loading';
    this._fuelStationServ
      .getCompanyFuelStations(this._companyServ.getCompanyId()!)
      .subscribe({
        next: (data: any) => {
          if (data.results && data.results.length > 0) {
            this.fetch_fuel_stations_response = 'success';
            this.fetch_fuel_stations_data = data.results;
          } else {
            this.fetch_fuel_stations_response = 'no_results';
          }
        },
        error: (err: any) => {
          this.fetch_fuel_stations_response = 'network_err';
        },
      });
  }

  // Fetch towns
  fetch_towns_response = 'loading';
  fetch_towns_data: any = [];
  
  fetch_towns() {
    this.fetch_towns_response = 'loading';
    this._companyServ.getToTowns().subscribe({
      next: (data: any) => {
        if (data.results && data.results.length > 0) {
          this.fetch_towns_response = 'success';
          this.fetch_towns_data = data.results;
        } else {
          this.fetch_towns_response = 'no_results';
        }
      },
      error: (err: any) => {
        this.fetch_towns_response = 'network_err';
      },
    });
  }

  // Fetch Oil Company
  fetch_oil_companies_response = 'loading';
  fetch_oil_companies_data: any = [];
  fetch_oil_companies() {
    this.fetch_oil_companies_response = 'loading';
    this._companyServ.getToTowns().subscribe({
      next: (data: any) => {
        if (data.results && data.results.length > 0) {
          this.fetch_oil_companies_response = 'success';
          this.fetch_oil_companies_data = data.results;
        } else {
          this.fetch_oil_companies_response = 'no_results';
        }
      },
      error: (err: any) => {
        this.fetch_oil_companies_response = 'network_err';
      },
    });
  }

  // Fetch towns
  fetch_registration_response = 'loading';
  fetch_registration_data: any = [];
  fetch_registration() {
    this.fetch_registration_response = 'loading';
    this._vehicleService
      .getVehicleByCompany(this._companyServ.getCompanyId()!)
      .subscribe({
        next: (data: any) => {
          if (data.results && data.results.length > 0) {
            this.fetch_registration_response = 'success';
            this.fetch_registration_data = data.results;
          } else {
            this.fetch_registration_response = 'no_results';
          }
        },
        error: (err: any) => {
          this.fetch_registration_response = 'network_err';
        },
      });
  }

  ngOnInit() {
    if (
      sessionStorage.getItem('company_id') &&
      sessionStorage.getItem('company_selected')
    ) {
      this.FormModel.companyID = Number(sessionStorage.getItem('company_id'));
      this.FormModel.fullCompany = sessionStorage.getItem('company_selected');
      this.fetch_fuel_stations();
      this.fetch_oil_companies();
      this.fetch_towns();
      this.fetch_registration();
    }
  }
  private getFileName() {
    let formData = new FormData();

    // this.openSnackBar(response + '', 'OK', 'success');
    let companyName = this._companyServ.getCompanyName();
    let fileName = 'Capture';
    let uploadTime = new Date();

    let refFileName: any;

    let timeValue =
      uploadTime.getFullYear() +
      '_' +
      (uploadTime.getMonth() + 1) +
      '_' +
      uploadTime.getDate() +
      '_' +
      uploadTime.getHours() +
      uploadTime.getMinutes() +
      uploadTime.getSeconds();
    fileName = fileName.replaceAll(' ', '');
    this.closeModal();
    return (refFileName =
      this._companyServ.getCompanyId() +
      '_' +
      companyName?.replaceAll(' ', '') +
      '_' +
      fileName.replaceAll(/[^a-zA-Z ]/g, '') +
      '_' +
      timeValue?.replaceAll(' ', ''));
  }
}
