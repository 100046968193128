<div class="myModal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p>{{ vehicle_info.REGISTRATIONNO }}</p>
      <p *ngIf="vehicle_info_reponse === 'loading'">Loading, please wait...</p>
      <p *ngIf="vehicle_info_reponse === 'success'">
        {{ vehicle_info.VEHICLEMAKE + " " + vehicle_info.VEHICLERANGE }}
      </p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>

  <div class="modal-contain">
    <div class="myuitabs">
      <div class="lft">
        <button mat-stroked-button [ngClass]="tab==='1'?'active':''" (click)="selectTab('1')" type="button"><p>Vehicle Details</p></button>
        <button mat-stroked-button [ngClass]="tab==='2'?'active':''" (click)="selectTab('2')" type="button"><p>Vehicle Driver</p></button>
        <button mat-stroked-button [ngClass]="tab==='3'?'active':''" (click)="selectTab('3')" type="button"><p>Vehicle Device</p></button>
      </div>
    </div>

    <!-- Vehicle Details Tab -->
    <ng-container *ngIf="tab==='1'">
      <form [formGroup]="updateVehicleForm" (ngSubmit)="onSubmit()" class="myform">
        <div class="row">
          <div class="col-12">
            <div class="card01010">
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Vehicle Id</mat-label>
                    <input matInput formControlName="VEHICLEID" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
<!--                <div class="col-xs-12 col-md-6">-->
<!--                  <mat-form-field>-->
<!--                    <mat-label>Company Id</mat-label>-->
<!--                    <input matInput [readonly]="true" formControlName="company_id" placeholder="eg. 1234" />-->
<!--                  </mat-form-field>-->
<!--                </div>-->
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>AS400 Vehicle Id</mat-label>
                    <input matInput formControlName="AS400VEHICLEID" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>MVA Number</mat-label>
                    <input matInput formControlName="MVA_Number" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Registration No</mat-label>
                    <input matInput formControlName="REGISTRATIONNO" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Fleet No</mat-label>
                    <input matInput formControlName="FLEETNO" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Vehicle Type</mat-label>
                    <input matInput formControlName="VehType" placeholder="eg. ABCD" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Make Id</mat-label>
                    <input matInput [readonly]="true" formControlName="MAKEID" placeholder="eg. ABCD" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Range Id</mat-label>
                    <input matInput [readonly]="true" formControlName="RANGEID" placeholder="eg. ABCD" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Model Id</mat-label>
                    <input matInput [readonly]="true" formControlName="MODELID" placeholder="eg. ABCD" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Fuel Type Id</mat-label>
                    <input matInput formControlName="FUELTYPEID" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Vehicle Industry Type</mat-label>
                    <input matInput formControlName="INDUSTRYTYPE" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Year</mat-label>
                    <input matInput formControlName="YEAR" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Tank Capacity</mat-label>
                    <input matInput formControlName="TANKCAPACITY" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
<!--                <div class="col-xs-12 col-md-6">-->
<!--                  <mat-form-field>-->
<!--                    <mat-label>CO2 Emissions</mat-label>-->
<!--                    <input matInput formControlName="Emissions" placeholder="eg. 1234" />-->
<!--                  </mat-form-field>-->
<!--                </div>-->
<!--                <div class="col-xs-12 col-md-6">-->
<!--                  <mat-form-field>-->
<!--                    <mat-label>CO2 Emissions Class</mat-label>-->
<!--                    <input matInput formControlName="EmissionsClass" placeholder="eg. 1234" />-->
<!--                  </mat-form-field>-->
<!--                </div>-->
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Industry Norm</mat-label>
                    <input matInput formControlName="INDUSTRYNORM" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Month Litres</mat-label>
                    <input matInput formControlName="MONTHLITRES" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Card No</mat-label>
                    <input matInput formControlName="CardNo" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
<!--                <div class="col-xs-12 col-md-6">-->
<!--                  <mat-form-field>-->
<!--                    <mat-label>Customer Id</mat-label>-->
<!--                    <input matInput formControlName="CUSTOMERID" placeholder="eg. 1234" />-->
<!--                  </mat-form-field>-->
<!--                </div>-->
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Customer Name</mat-label>
                    <input matInput formControlName="CUSTOMERNAME" placeholder="eg. ABCD" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>AS400 Company Id</mat-label>
                    <input matInput formControlName="AS400CompanyId" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-12">
                  <mat-form-field>
                    <mat-label>Photo Path</mat-label>
                    <input matInput formControlName="PHOTOPATH" placeholder="eg. /path/" />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12" align="end">
          <button class="mybtn" mat-flat-button color="primary" type="submit">Save</button>
        </div>
      </form>
    </ng-container>

    <!-- Vehicle Driver Tab -->
    <ng-container *ngIf="tab==='2'">
      <div class="row p-4 ">
        <div class="col-4">
          <div class="card01010">
            <div class="row">
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Vehicle Id</mat-label>
                  <input matInput formControlName="VEHICLEID" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
<!--              <div class="col-xs-12 col-md-6">-->
<!--                <mat-form-field>-->
<!--                  <mat-label>Company Id</mat-label>-->
<!--                  <input matInput [readonly]="true" formControlName="company_id" placeholder="eg. 1234" />-->
<!--                </mat-form-field>-->
<!--              </div>-->
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>AS400 Vehicle Id</mat-label>
                  <input matInput formControlName="AS400VEHICLEID" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>MVA Number</mat-label>
                  <input matInput formControlName="MVA_Number" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Registration No</mat-label>
                  <input matInput formControlName="REGISTRATIONNO" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Fleet No</mat-label>
                  <input matInput formControlName="FLEETNO" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Vehicle Type</mat-label>
                  <input matInput formControlName="VehType" placeholder="eg. ABCD" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Make Id</mat-label>
                  <input matInput [readonly]="true" formControlName="MAKEID" placeholder="eg. ABCD" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Range Id</mat-label>
                  <input matInput [readonly]="true" formControlName="RANGEID" placeholder="eg. ABCD" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Model Id</mat-label>
                  <input matInput [readonly]="true" formControlName="MODELID" placeholder="eg. ABCD" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Fuel Type Id</mat-label>
                  <input matInput formControlName="FUELTYPEID" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Vehicle Industry Type</mat-label>
                  <input matInput formControlName="INDUSTRYTYPE" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Year</mat-label>
                  <input matInput formControlName="YEAR" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Tank Capacity</mat-label>
                  <input matInput formControlName="TANKCAPACITY" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>CO2 Emissions</mat-label>
                  <input matInput formControlName="Emissions" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>CO2 Emissions Class</mat-label>
                  <input matInput formControlName="EmissionsClass" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Industry Norm</mat-label>
                  <input matInput formControlName="INDUSTRYNORM" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Month Litres</mat-label>
                  <input matInput formControlName="MONTHLITRES" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Card No</mat-label>
                  <input matInput formControlName="CardNo" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Customer Id</mat-label>
                  <input matInput formControlName="CUSTOMERID" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>Customer Name</mat-label>
                  <input matInput formControlName="CUSTOMERNAME" placeholder="eg. ABCD" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-6">
                <mat-form-field>
                  <mat-label>AS400 Company Id</mat-label>
                  <input matInput formControlName="AS400CompanyId" placeholder="eg. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-12">
                <mat-form-field>
                  <mat-label>Photo Path</mat-label>
                  <input matInput formControlName="PHOTOPATH" placeholder="eg. /path/" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="row">
            <mat-form-field>
              <mat-label>Search Driver</mat-label>
              <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" formControlName="myControl" />
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let driver of drivers" [value]="driver">
                  <div class="row">
                    <div class="col-md-3">{{ driver.DRIVERID }}</div>
                    <div class="col-md-7">{{ driver.DRIVERNAME }}</div>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="card010101">
              <div *ngIf="fetch_vehicle_response === 'loading' || fetch_vehicle_response === 'no_internet'" class="skeletons anime-skeleton">
                <div class="skeleton"></div>
                <div class="skeleton"></div>
                <div class="skeleton"></div>
                <div class="skeleton"></div>
              </div>
              <div *ngIf="fetch_vehicle_response === 'success'" class="mytable">
                <table mat-table [dataSource]="dataSource_Driver" class="mat-elevation-z8">
                  <ng-container matColumnDef="DriverName">
                    <th mat-header-cell *matHeaderCellDef>Driver Name</th>
                    <td mat-cell *matCellDef="let row">{{ row.DriverName }}</td>
                  </ng-container>
                  <ng-container matColumnDef="ActiveDate">
                    <th mat-header-cell *matHeaderCellDef>Active Date</th>
                    <td mat-cell *matCellDef="let row">{{ row.ActiveDate ? row.ActiveDate.substring(0, 10) : '' }}</td>
                  </ng-container>
                  <ng-container matColumnDef="InActiveDate">
                    <th mat-header-cell *matHeaderCellDef>In Active Date</th>
                    <td mat-cell *matCellDef="let row">{{ row.InActiveDate ? row.InActiveDate.substring(0, 10) : '' }}</td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns_Driver; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns_Driver"></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Vehicle Device Tab -->
    <ng-container *ngIf="tab==='3'">
      <form [formGroup]="updateVehicleForm" (ngSubmit)="onSubmit()" class="myform">
        <div class="row">
          <div class="col-12">
            <div class="card01010">
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Vehicle Id</mat-label>
                    <input matInput formControlName="VEHICLEID" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
<!--                <div class="col-xs-12 col-md-6">-->
<!--                  <mat-form-field>-->
<!--                    <mat-label>Company Id</mat-label>-->
<!--                    <input matInput [readonly]="true" formControlName="company_id" placeholder="eg. 1234" />-->
<!--                  </mat-form-field>-->
<!--                </div>-->
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>IMEI</mat-label>
                    <input matInput formControlName="IMEI" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Course</mat-label>
                    <input matInput formControlName="course" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Speed</mat-label>
                    <input matInput formControlName="speed" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Heart Time</mat-label>
                    <input matInput formControlName="hearttime" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Server Time</mat-label>
                    <input matInput formControlName="servertime" placeholder="eg. ABCD" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>System Time</mat-label>
                    <input matInput [readonly]="true" formControlName="systemtime" placeholder="eg. ABCD" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Latitude</mat-label>
                    <input matInput [readonly]="true" formControlName="latitude" placeholder="eg. ABCD" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Longitude</mat-label>
                    <input matInput [readonly]="true" formControlName="longitude" placeholder="eg. ABCD" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Fuel Type Id</mat-label>
                    <input matInput formControlName="FUELTYPEID" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Oil Power Status</mat-label>
                    <input matInput formControlName="oilpowerstatus" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Battery</mat-label>
                    <input matInput formControlName="battery" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Tank Capacity</mat-label>
                    <input matInput formControlName="TANKCAPACITY" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
<!--                <div class="col-xs-12 col-md-6">-->
<!--                  <mat-form-field>-->
<!--                    <mat-label>CO2 Emissions</mat-label>-->
<!--                    <input matInput formControlName="Emissions" placeholder="eg. 1234" />-->
<!--                  </mat-form-field>-->
<!--                </div>-->
<!--                <div class="col-xs-12 col-md-6">-->
<!--                  <mat-form-field>-->
<!--                    <mat-label>CO2 Emissions Class</mat-label>-->
<!--                    <input matInput formControlName="EmissionsClass" placeholder="eg. 1234" />-->
<!--                  </mat-form-field>-->
<!--                </div>-->
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Milage</mat-label>
                    <input matInput formControlName="INDUSTRYNORM" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Current Milage</mat-label>
                    <input matInput formControlName="MONTHLITRES" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Card No</mat-label>
                    <input matInput formControlName="CardNo" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Customer Id</mat-label>
                    <input matInput formControlName="CUSTOMERID" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Customer Name</mat-label>
                    <input matInput formControlName="CUSTOMERNAME" placeholder="eg. ABCD" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field>
                    <mat-label>AS400 Company Id</mat-label>
                    <input matInput formControlName="AS400CompanyId" placeholder="eg. 1234" />
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-12">
                  <mat-form-field>
                    <mat-label>Photo Path</mat-label>
                    <input matInput formControlName="PHOTOPATH" placeholder="eg. /path/" />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12" align="end">
          <button class="mybtn" mat-flat-button color="primary" type="submit">Save</button>
        </div>
      </form>
    </ng-container>

  </div>
</div>
