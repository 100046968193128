import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyService } from '../companies/company.service';
import {AuthService} from "../auth/auth.service";

@Injectable({
  providedIn: 'root',
})
export class DriverService {
  constructor(
    private http: HttpClient,
    private _companyServ: CompanyService,
    private _authService: AuthService
  ) {}

  public service_base = this._authService.service_base;

  private headers = new HttpHeaders({
    'x-Gateway-APIKey': 'c2c22314-ca55-4b42-b472-494a6c180706',
    'Access-Control-Allow-Origin': '*',
  });

  createNewDriver(data: any) {
    const url = this.service_base + 'driver/store';
    const result = this.http.post<any>(url, data, {
      headers: this.headers,
    });
    return result;
  }
  updateDriver(data: any) {
    let url = this.service_base + 'driver/update';
    return this.http.post<any>(url, data,{
      headers:this.headers
    });
  }

  getAllDrivers(): Observable<any> {
    let url = this.service_base+'getAllDrivers';
    return this.http.get<any[]>(url,{
      headers:this.headers
    });
  }

  getDriverById(id: any): Observable<any> {
    let url = this.service_base+'getDriverByDriverID?DriverID=' + id;
    return this.http.get<any[]>(url,{
      headers:this.headers
    });
  }

  getDriversByCompanyId(id: any): Observable<any> {
    let url = this.service_base + `driver/get-drivers-by-company-uuid/${id}`;
    return this.http.get<any[]>(url,{
      headers:this.headers
    });
  }

  deleteDriver(id: any): Observable<any> {
    let url = this.service_base+'deleteDriverByDriverId?DriverId=' + id;
    return this.http.delete<any[]>(url,{
      headers:this.headers
    });
  }

  assignNewDriver(data: any) {
    let url = this.service_base + 'driver/assign';
    return this.http.post<any>(url, data,{
      headers:this.headers
    });
  }
}
