<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="lft">
        <p>{{data.REGISTRATIONNO}}</p>
        <p>Company</p>
        </div>
        <div class="rgt">
        <button mat-flat-button (click)="closeModal()" cdkFocusInitial >
            <i class="fa-solid fa-xmark"></i>
        </button>
        </div>
    </div>

    <div class="modal-contain">
        <form class="myform">

        {{data | json}}
        <div class="row">
            <div class="col-xs-12">
            <button class="mybtn" (click)="closeModal()" type="button" mat-flat-button color="primary">Okay</button>
            </div>
        </div>
        </form>
    </div>
</div>
  