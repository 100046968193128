<div class="myModal" >
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p>View Report</p>
      <p *ngIf="dataSource.length>0">{{ dataSource[0].FILE_NAME }}</p>
      <p *ngIf="dataSource.length<1">No results</p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>

  <div class="modal-contain" >
    <div class="htitle">
      <p class="FxLine" *ngIf="fetch_response==='loading'" >Loading, please waiting</p>
      <p *ngIf="fetch_response==='success'" class="FxLine">Records Proccessed ({{ dataSource.length }})</p>
    </div>
    <app-table-skeleton *ngIf="fetch_response==='loading'"></app-table-skeleton>
    <form class="myform viewreports" *ngIf="fetch_response==='success'">
      <div class="mytable">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->
          <ng-container matColumnDef="REGISTRATION_NUMBER">
            <th mat-header-cell *matHeaderCellDef>REGISTRATION NO.</th>
            <td mat-cell *matCellDef="let row">
              {{ row.REGISTRATION_NUMBER }}
            </td>
          </ng-container>
          <ng-container matColumnDef="FILE_NAME">
            <th mat-header-cell *matHeaderCellDef>FILE NAME</th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="row.FILE_NAME"
                >{{ row.FILE_NAME }}
              </ng-container>
              <ng-container *ngIf="!row.FILE_NAME">Null</ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="COMMENTS">
            <th mat-header-cell *matHeaderCellDef>COMMENTS</th>
            <td mat-cell *matCellDef="let row">{{ row.COMMENTS }}</td>
          </ng-container>
          <ng-container matColumnDef="STATUS">
            <th mat-header-cell *matHeaderCellDef>STATUS</th>
            <td mat-cell *matCellDef="let row">{{ row.STATUS }}</td>
          </ng-container>
          <ng-container matColumnDef="DATE_INSERTED">
            <th mat-header-cell *matHeaderCellDef>DATE INSERTED</th>
            <td mat-cell *matCellDef="let row">{{ row.DATE_INSERTED }}</td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <button
            class="mybtn"
            (click)="closeModal()"
            type="button"
            mat-flat-button
            color="primary"
          >
            Okay
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
