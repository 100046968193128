
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/companies/company.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-company-config',
  templateUrl: './company-config.component.html',
  styleUrls: ['./company-config.component.css']
})
export class CompanyConfigComponent {
  constructor(
    public dialogRef: MatDialogRef<CompanyConfigComponent>,
    private _companyServ: CompanyService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, { duration: 5000, panelClass: type });
  }

  ngOnInit(): void { }

  closeModal(): void {
    this.dialogRef.close({event:''});
  }
  RawData = [];

  onChangeValue(fieldName: string, value: any) {
    this.data.form[fieldName] = value;
  }
  selectDrivers(fieldName: string, value: any) {
    this.data.form.ASSIGNDRIVERSIMPORT = '0';
    this.data.form.UseAS400Drivers = '0';
    this.data.form.INUSE = '0';

    this.data.form[fieldName] = value;
  }

  isValidForm(){
    if(this.data.form.company_name.length < 1){
      (document.querySelector("input[name=company_name]") as HTMLInputElement).focus();
      return false;
    }
    if(this.data.form.currency_symbol.length < 1){
      (document.querySelector("mat-select[name=currency_symbol]") as HTMLInputElement).focus();
      return false;
    }
    return true;
  }

  addCompanyResponse = '';
  addCompany() {
    if(!this.isValidForm()){
      return;
    }
    let payload = this.data.form;
    this.addCompanyResponse = 'loading';

    this._companyServ.insertCompany(payload).subscribe({
      next: (data: any) => {
        if(data.status.message == "success"){
          this.addCompanyResponse = 'success';
          this.dialogRef.close({event:'create', payload});
          this.openSnackBar('Successfully created the company '+payload.company_name, 'Okay', 'success');
        }else{
          this.addCompanyResponse = 'network_err';
          if(data.status.message.includes('Company already exists')){
            this.openSnackBar('Company already exists', 'Okay', 'error');
            (document.querySelector("input[name=company_name]") as HTMLInputElement).focus();
          }else{
            this.openSnackBar(data.status.message, 'Okay', 'success')
          }
        }
      },
      error: (error: any) => {
        this.openSnackBar('Check internet connectivity', 'Okay', 'error');
        this.addCompanyResponse = 'network_err';
      },
    });
  }


  updateCompanyResponse = '';
  updateCompany() {
    if(!this.isValidForm()){
      return;
    }
    let payload = this.data.form;
    this.updateCompanyResponse = 'loading';

    this._companyServ.updateCompany(payload).subscribe({
      next: (data: any) => {
        if(data.status.message == "success"){
          this.updateCompanyResponse = 'success';
          this.dialogRef.close({event:'create', payload});
          this.openSnackBar('Successfully updated the company '+payload.company_name, 'Okay', 'success');
        }else{
          this.updateCompanyResponse = 'network_err';
          if(data.status.message.includes('Company already exists')){
            this.openSnackBar('Company already exists', 'Okay', 'error');
            (document.querySelector("input[name=company_name]") as HTMLInputElement).focus();
          }else{
            this.openSnackBar(data.status.message, 'Okay', 'success')
          }
        }
      },
      error: (error: any) => {
        this.openSnackBar(error.message, 'Retry', 'error');
        this.updateCompanyResponse = 'network_err';
      },
    });

  }
}

