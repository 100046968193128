import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyService } from 'src/app/services/companies/company.service';
import { TransactionService } from 'src/app/services/transactions/transaction.service';
@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.css']
})
export class ViewReportComponent {
  constructor(
    public dialogRef: MatDialogRef<ViewReportComponent>,
    private _snackBar: MatSnackBar,
    private _companyServ: CompanyService,
    private _transaction: TransactionService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, { duration: 5000, panelClass: type });
  }
  closeModal(): void {
    this.dialogRef.close();
  }


  // Table Headers
  displayedColumns: string[] = [
    'REGISTRATION_NUMBER',
    'COMMENTS',
    'FILE_NAME',
    'STATUS',
    'DATE_INSERTED',
  ];

  // API Fetch Records
  //Table Data
  dataSource = [];


    // Fetching Report
    fetch_response = 'loading';
  
    fetch_report_records(FILE_NAME:any) {
      if (this._companyServ.getCompanyId()) {
        this.fetch_response = 'loading';
        this._transaction
          .getFilesStatus(this._companyServ.getCompanyId())
          .subscribe({
            next: (data: any) => {
              if (data.results && data.results.length > 0) {
                this.fetch_response = 'success';
  

                this.dataSource = data.results.filter(function (x:any) {
                  if (x.FILE_NAME===FILE_NAME) {
                    return x;
                  }
                });

              } else {
                this.fetch_response = 'no_results';
              }
            },
            error: (error:any) => {
              this.fetch_response = 'network_err';
            },
          });
      } else {
        this.openSnackBar('Company was not selected', 'Okay', 'error');
      }
    }

    

  ngOnInit() {
    if(this.data.data){
      this.dataSource = this.data.data;
      this.fetch_response = 'success';
    }else{
      this.fetch_report_records(this.data.FILE_NAME)
    }

  }

}
