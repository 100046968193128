import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-special-procedures',
  templateUrl: './special-procedures.component.html',
  styleUrls: ['./special-procedures.component.css']
})
export class SpecialProceduresComponent {
  constructor(
    public dialogRef: MatDialogRef<SpecialProceduresComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}


    dataSource = this.data;
    closeModal(data?: any): void {
      this.dialogRef.close({ event: data });
    }

    tab = '1';
  
    selectTab(value: any) {
      this.tab = value;
    }

    

}

