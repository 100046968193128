import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { read, utils, writeFile } from 'xlsx';
import { TransactionService } from '../../../services/transactions/transaction.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ViewReportComponent } from '../view-report/view-report.component';
import * as XLSX from 'xlsx';
import * as Handsontable from 'handsontable';
import { HotTableRegisterer } from '@handsontable/angular';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { CompanyService } from 'src/app/services/companies/company.service';
@Component({
  selector: 'app-import-transaction-file',
  templateUrl: './import-transaction-file.component.html',
  styleUrls: ['./import-transaction-file.component.css'],
})
export class ImportTransactionFileComponent {
  constructor(
    public dialogRef: MatDialogRef<ImportTransactionFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _companyServ: CompanyService,
    private _StorageServ: StorageService,
    private _transactionServ: TransactionService,
  ) {}

  private hotRegisterer = new HotTableRegisterer();
  id = 'hotInstance';

  openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: type,
      // horizontalPosition: "center",
      // verticalPosition: "top",
    });
  }
  
  closeModal(): void {
    this.dialogRef.close();
  }
  isMaximized = false;
  maximizeModal() {
    if (this.isMaximized) {
      this.isMaximized = false;
    } else {
      this.isMaximized = true;
    }
  }
  isLoading = false;
  File: any = null;
  activeFile: any;
  public files: NgxFileDropEntry[] = [];

  selected_dictionary = '';

  ingoreList: any[] = [];

  raw_dataset: any[] = [];
  dataset: any = null;

  colHeaders: any[] = [];
  tableHidden: boolean = true;
  isSkipFirstRow = "0";

  avis_wesbank_id = '';

  selected_heading = '';
  selected_column_position = '';

  selected_date_format = '';
  selected_time_format = '';
  
  layout_name = '';
  response_layouts_data:any = [];
  selected_layout:any = null;
  tableSettings: any = {
    readOnly: true,
    colHeaders: true,
    rowHeaders: true,
    width: '100%',
    // height: this.isMaximized?'calc(100vh - 210px)':'50vh',
    manualColumnResize: true,
    manualColumnMove: true,
    manualRowResize: true,
    stretchH: 'last',
    contextMenu: false,
    licenseKey: 'non-commercial-and-evaluation',
    afterOnCellMouseDown: (event: any, coords: any, TD: any) => {
      this.selected_column_position = coords.col.toString();
    },
  };

  preview_file(file: any) {
    this.isLoading = true;
    this.tableHidden = true;
    this.colHeaders = [];
    this.dataset = [];
    this.raw_dataset = [];
    // this.selected_dictionary = "100";
    // this.selected_date_format = "";
    // this.selected_time_format = "";
    /* wire up file reader */

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const ab = e.target.result;
      const wb = read(ab);

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      // const data:any = utils.sheet_to_json(ws, {header: 1});
      const data: any = utils.sheet_to_json(ws, { header: 1 });
      for (var i = 0; i < data[0].length; i++) {
        this.colHeaders.push('Col ' + (i + 1));
      }
      this.dataset = data;
      this.raw_dataset = data;
      this.isLoading = false;
      this.tableHidden = false;
      this.formatDataset();
    };
    reader.readAsArrayBuffer(file);
  }

  validFileExtensions = [
    '.xls',
    '.xlsx',
    '.csv',
    '.txt',
    '.txn',
    '.dat',
    '.TXN',
  ];

  // Upload files
  onFileChange(evt: any) {
    this.File = null;
    document.querySelector('.importfile .contain')!.innerHTML =
      '<div class="lft"><p>Upload File...</p></div><div class="rgt"><p>No file selected</p><i class="fa-solid fa-circle-info"></i></div>';
    document.querySelector('.mydraganddrop .contain')!.innerHTML =
      '<i class="fa-solid fa-upload"></i><p>Drag and drop or click to upload transaction File.<a>Browse file</a></p>';
    const event = evt.target;
    if (event.files.length > 0) {
      const sFileName = event.files[0].name;
      if (sFileName.length > 0) {
        let blnValid = false;
        for (let j = 0; j < this.validFileExtensions.length; j++) {
          const sCurExtension = this.validFileExtensions[j];
          if (
            sFileName
              .substr(
                sFileName.length - sCurExtension.length,
                sCurExtension.length
              )
              .toLowerCase() === sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }

        if (!blnValid) {
          document.querySelector('.importfile .contain')!.innerHTML =
            '<div class="lft"><p class="error">File unsupported...</p></div><div class="rgt"><p>Allowed files: ' +
            this.validFileExtensions.join(', ') +
            '</p><i class="fa-solid fa-circle-info error"></i></div>';
          document.querySelector('.mydraganddrop .contain')!.innerHTML =
            '<i class="fa-solid fa-triangle-exclamation error"></i>  <p><span class="error">Sorry, your file (' +
            sFileName +
            ') is unsupported, allowed files are:</span> ' +
            this.validFileExtensions.join(', ') +
            '</p> <a>Browse another file</a>';
        } else {
          this.preview_file(evt.target.files[0]);
          this.File = evt.target.files[0];
          evt.target.value = '';
          document.querySelector('.importfile .contain')!.innerHTML =
            '<div class="lft"><p><span class="success">' +
            sFileName +
            ', </span>click to choose new file...</p></div><div class="rgt"><p>File attached</p><i class="fa-solid fa-circle-check success"></i></div>';
          document.querySelector('.mydraganddrop .contain')!.innerHTML =
            '<i class="fa-solid fa-file-circle-check success"></i><p>Your file has been attached.<span class="success">' +
            sFileName +
            '</span></p><a>Browse another file</a>';
        }
      } else {
        document.querySelector('.importfile .contain')!.innerHTML =
          '<div class="lft"><p class="error">File unsupported...</p></div><div class="rgt"><p>Try different file</p><i class="fa-solid fa-circle-info error"></i></div>';
        document.querySelector('.mydraganddrop .contain')!.innerHTML =
          '<i class="fa-solid fa-triangle-exclamation error"></i><p><span class="error">Sorry, your file (' +
          sFileName +
          ') is unsupported, allowed files are:</span> ' +
          this.validFileExtensions.join(', ') +
          '</p> <a>Browse another file</a>';
      }
    } else {
      document.querySelector('.importfile .contain')!.innerHTML =
        '<div class="lft"><p>Upload File...</p></div><div class="rgt"><p>No file selected</p><i class="fa-solid fa-circle-info"></i></div>';
      document.querySelector('.mydraganddrop .contain')!.innerHTML =
        '<i class="fa-solid fa-upload"></i><p>Drag and drop or click to upload transaction File.<a>Browse file</a></p>';
    }
  }
  // Drag and dop
  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;

    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          // Here you can access the real file
          // console.log(droppedFile.relativePath, file);

          const sFileName = droppedFile.relativePath;

          this.File = null;

          if (sFileName.length > 0) {
            let blnValid = false;
            for (let j = 0; j < this.validFileExtensions.length; j++) {
              const sCurExtension = this.validFileExtensions[j];

              if (
                sFileName
                  .substr(
                    sFileName.length - sCurExtension.length,
                    sCurExtension.length
                  )
                  .toLowerCase() === sCurExtension.toLowerCase()
              ) {
                blnValid = true;
                break;
              }
            }

            if (!blnValid) {
              document.querySelector('.importfile .contain')!.innerHTML =
                '<div class="lft"><p class="error">File unsupported...</p></div><div class="rgt"><p>Allowed files: ' +
                this.validFileExtensions.join(', ') +
                '</p><i class="fa-solid fa-circle-info error"></i></div>';
              document.querySelector('.mydraganddrop .contain')!.innerHTML =
                '<i class="fa-solid fa-triangle-exclamation error"></i><p><span class="error">Sorry, your file (' +
                sFileName +
                ') is unsupported, allowed files are:</span> ' +
                this.validFileExtensions.join(', ') +
                '</p> <a>Browse another file</a>';
            } else {
              this.preview_file(file);
              this.File = file;
              document.querySelector('.importfile .contain')!.innerHTML =
                '<div class="lft"><p><span class="success">' +
                sFileName +
                ', </span>click to choose new file...</p></div><div class="rgt"><p>File attached</p><i class="fa-solid fa-circle-check success"></i></div>';
              document.querySelector('.mydraganddrop .contain')!.innerHTML =
                '<i class="fa-solid fa-file-circle-check success"></i><p>Your file has been attached.<span class="success">' +
                sFileName +
                '</span></p><a>Browse another file</a>';
            }
          } else {
            document.querySelector('.importfile .contain')!.innerHTML =
              '<div class="lft"><p class="error">File unsupported...</p></div><div class="rgt"><p>Try different file</p><i class="fa-solid fa-circle-info error"></i></div>';
            document.querySelector('.mydraganddrop .contain')!.innerHTML =
              '<i class="fa-solid fa-triangle-exclamation error"></i><p><span class="error">Sorry, your file (' +
              sFileName +
              ') is unsupported, allowed files are:</span> ' +
              this.validFileExtensions.join(', ') +
              '</p> <a>Browse another file</a>';
          }
        });
      } else {
        // It was a di;rectory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        document.querySelector('.importfile .contain')!.innerHTML =
          '<div class="lft"><p>Upload File...</p></div><div class="rgt"><p>No file selected</p><i class="fa-solid fa-circle-info"></i></div>';
        document.querySelector('.mydraganddrop .contain')!.innerHTML =
          '<i class="fa-solid fa-upload"></i><p>Drag and drop or click to upload transaction File.<a>Browse file</a></p>';
      }
    }
  }
  private getVehicles(obj:any){
   

    const RegistrationNo =  obj[0].substring(90,98)
    const Driver =  obj[0].substring(60,90)

    const transcations = [];
    for (var i = 1; i < obj.length; i++) {
        if(obj[i].substring(143,148).includes('FUEL')){
          const Date = obj[i].substring(20,33);
          const DateConverted = Date.substring(6,8)+"/"+Date.substring(4,6)+"/"+Date.substring(0,4);
          const Time = obj[i].substring(28,33);
          transcations.push({
            "RegistrationNo": RegistrationNo,
            "Driver": Driver,
            "Date": DateConverted,
            "Time": Time,
            "FuelStation": obj[i].substring(56,81),
            "FuelCompany": obj[i].substring(81,97),
            "Litres": (Number(obj[i].substring(105,111))/100).toString(),
            "Amount": (Number(obj[i].substring(153,167))/100).toString(),
            "OdoReading": obj[i].substring(97,104)
          })   
        }
      
    }
    return transcations;

  }
  toggleSkipRow() {
    if (this.isSkipFirstRow==="1") {
      this.isSkipFirstRow = "0";
      document
        .querySelector(
          '.importFileModal .handsontable table tbody tr:first-child'
        )!
        .classList.remove('skipped');
    } else {
      this.isSkipFirstRow = "1";
      document
        .querySelector(
          '.importFileModal .handsontable table tbody tr:first-child'
        )!
        .classList.add('skipped');
    }
  }
  selectDictionary(d: any) {
    this.selected_layout = null;
    this.selected_dictionary = d;
    this.isSkipFirstRow = "0";
    this.selected_date_format = "";
    this.selected_time_format = "";
    this.formatDataset();

  }

  formatDataset() {
    if (this.raw_dataset.length > 0) {

      if(this.selected_layout && this.selected_layout.dictionary!==''){
        this.selected_dictionary =  this.selected_layout.dictionary;
      }

      if (this.selected_dictionary === '1') {
        const dataset = this.raw_dataset;
        const new_headers = [];
        for (var i = 0; i < dataset[0].length; i++) {
          if (i + 1 === 2) {
            new_headers.push('Registration No');
          } else if (i + 1 === 3) {
            new_headers.push('Card No');
          } else if (i + 1 === 4) {
            new_headers.push('Driver');
          } else if (i + 1 === 11) {
            new_headers.push('Date');
          } else if (i + 1 === 12) {
            new_headers.push('Voucher No');
          }else if (i + 1 === 13) {
            new_headers.push('Time');
          } else if (i + 1 === 14) {
            new_headers.push('Fuel Station');
          } else if (i + 1 === 15) {
            new_headers.push('Town');
          } else if (i + 1 === 16) {
            new_headers.push('Purchase Type');
          } else if (i + 1 === 19) {
            new_headers.push('Fuel Company');
          }else if (i + 1 === 20) {
            new_headers.push('Odo Reading');
          } else if (i + 1 === 21) {
            new_headers.push('Litres');
          }else if (i + 1 === 22) {
            new_headers.push('Amount');
          } else {
            new_headers.push('Col ' + (i + 1));
          }
        }

        this.colHeaders = new_headers;

        const new_data = [];
        // new_data.push(dataset[0])
        for (var i = 0; i < dataset.length; i++) {
          new_data.push(dataset[i]);
        }
        this.dataset = new_data;
        this.selected_date_format = 'XXXXX';
        this.selected_time_format = '13:30:55';
        this.DateFormat(false);
        this.TimeFormat(false);
      } else if (this.selected_dictionary === '2') {
        const dataset = this.raw_dataset;
        const new_headers = [];
        for (var i = 0; i < dataset[0].length; i++) {
          if (i + 1 === 5) {
            new_headers.push('Date');
          } else if (i + 1 === 6) {
            new_headers.push('Time');
          }else if (i + 1 === 7) {
            new_headers.push('Registration No');
          }else if (i + 1 === 9) {
            new_headers.push('Driver');
          } else if (i + 1 === 10) {
            new_headers.push('Fuel Station');
          }else if (i + 1 === 11) {
            new_headers.push('Purchase Type');
          } else if (i + 1 === 12) {
            new_headers.push('Odo Reading');
          } else if (i + 1 === 13) {
            new_headers.push('Litres');
          } else if (i + 1 === 14) {
            new_headers.push('Amount');
          } else if (i + 1 === 19) {
            new_headers.push('Oil Company');
          } else if (i + 1 === 20) {
            new_headers.push('Voucher No');
          }else if (i + 1 === 22) {
            new_headers.push('Town');
          } else {
            new_headers.push('Col ' + (i + 1));
          }
        }

        this.colHeaders = new_headers;
        const new_data = [];
        new_data.push(dataset[0]);
        for (var i = 0; i < dataset.length; i++) {
          const CLNT_CODE = dataset[i][0] ? dataset[i][0].toString().replaceAll(' ','') : 'N/A';
          // const DESCRIPTION = dataset[i][10]? dataset[i][10].toString(): 'N/A';
          // if (CLNT_CODE === this.avis_wesbank_id && DESCRIPTION === 'FUEL') {
          //   new_data.push(dataset[i]);
          // }
          if(!dataset[i][0].toString().toLowerCase().includes('clnt')){
              if ((this.avis_wesbank_id!=="" && this.avis_wesbank_id === CLNT_CODE ) || this.avis_wesbank_id==="") {
                new_data.push(dataset[i]);
              }
          }
      
        }
        this.dataset = new_data;
        this.selected_date_format = 'XXXXX';
        this.selected_time_format = '1330';
        this.DateFormat(false);
        this.TimeFormat(false);
        
      } else if (this.selected_dictionary === '3') {
        this.colHeaders = [
          'Registration No',
          'Driver',
          'Date',
          'Time',
          'Fuel Station',
          'Odo Reading',
          'Litres',
          'Price Per Litre',
          'Amount',
        ];

        const dataset = this.raw_dataset;
        const new_data = [];
        for (var i = 0; i < dataset.length; i++) {
          const single_line = dataset[i][0];
          const segments = single_line.split(/(\s+)/);
          this.selected_date_format = 'yyyy-mm-dd';
          const Date =
            segments[0].substring(8, 10) +
            '/' +
            segments[0].substring(5, 7) +
            '/' +
            segments[0].substring(0, 4);

          const Reg_Odo_L_PPU_Amount = segments[4].split('.');
          const RegistrationNo = Reg_Odo_L_PPU_Amount[0]
            .toString()
            .substring(0, Reg_Odo_L_PPU_Amount[0].length - 13);
          const Driver = segments[2];

          const Time = segments[0].toString().substring(10, 16);
          const FuelStation = this._companyServ.getCompanyName();
          const OdoReading = Reg_Odo_L_PPU_Amount[0]
            .toString()
            .substring(Reg_Odo_L_PPU_Amount[0].length - 13)
            .toString()
            .substring(0, 8);
          const Litres =
            Reg_Odo_L_PPU_Amount[0]
              .toString()
              .substring(Reg_Odo_L_PPU_Amount[0].length - 5) +
            '.' +
            Reg_Odo_L_PPU_Amount[1].toString().substring(0, 1);
          const Price_Per_Unit = Reg_Odo_L_PPU_Amount[1]
            .toString()
            .substring(Reg_Odo_L_PPU_Amount[1].length - 4);
          const PricePerUnit = (Number(Price_Per_Unit) / 100).toString();
          const Amount =
            Reg_Odo_L_PPU_Amount[2]
              .toString()
              .substring(Reg_Odo_L_PPU_Amount[2].length - 5) +
            '.' +
            Reg_Odo_L_PPU_Amount[3];

          new_data.push([
            RegistrationNo,
            Driver,
            Date,
            Time,
            FuelStation,
            OdoReading,
            Litres,
            PricePerUnit,
            Amount,
          ]);
        }
        this.dataset = new_data;
        
        this.selected_date_format = 'yyyymmdd';
        this.selected_time_format = '13:30';
        this.DateFormat(false);
        this.TimeFormat(false);
      } else if (this.selected_dictionary === '4') {
        const dataset = this.raw_dataset;
        const new_headers = [];
        for (var i = 0; i < dataset[0].length; i++) {
          if (i + 1 === 5) {
            new_headers.push('Date');
          }else if (i + 1 === 6) {
            new_headers.push('Registration No');
          }else if (i + 1 === 7) {
            new_headers.push('Driver');
          } else if (i + 1 === 8) {
            new_headers.push('Fuel Station');
          }else if (i + 1 === 10) {
            new_headers.push('Purchase Type');
          } else if (i + 1 === 11) {
            new_headers.push('Odo Reading');
          } else if (i + 1 === 12) {
            new_headers.push('Litres');
          } else if (i + 1 === 13) {
            new_headers.push('Amount');
          } else {
            new_headers.push('Col ' + (i + 1));
          }
        }

        this.colHeaders = new_headers;
        const new_data = [];
        new_data.push(dataset[0]);
        for (var i = 0; i < dataset.length; i++) {
          const CLNT_CODE = dataset[i][0] ? dataset[i][0].toString().replaceAll(' ','') : 'N/A';
          // const DESCRIPTION = dataset[i][10]? dataset[i][10].toString(): 'N/A';
          // if (CLNT_CODE === this.avis_wesbank_id && DESCRIPTION === 'FUEL') {
          //   new_data.push(dataset[i]);
          // }
          if(!dataset[i][0].toString().toLowerCase().includes('client')){
              if ((this.avis_wesbank_id!=="" && CLNT_CODE === this.avis_wesbank_id) || this.avis_wesbank_id==="") {
                new_data.push(dataset[i]);
              }
          }
      
        }
        this.dataset = new_data;
        this.selected_date_format = 'XXXXX';
        this.DateFormat(false);
        
      }  else if (this.selected_dictionary === '10') {
        this.colHeaders = [
          'Registration No',
          'Date',
          'Card No',
          'Voucher No',
          'Fuel Station',
          'Town',
          'Odo Reading',
          'Litres',
          'Amount',
          'Invoice No',
        ];
        const dataset = this.raw_dataset;
        const new_data = [];
        for (var i = 1; i < dataset.length; i++) {
            //Unformatted
            if(dataset[i][8]){
              const Date = dataset[i][7].toString();
              const RegistrationNo = dataset[i][8]
                ? dataset[i][8].toString().replaceAll(' ', '')
                : '';
              const OdoReading = dataset[i][21] ? dataset[i][21].toString() : '';
              const Litres = dataset[i][16] ? dataset[i][16].toString() : '';
              const Amount = dataset[i][18] ? dataset[i][18].toString() : 0;
              const FuelStation = dataset[i][4] ? dataset[i][4].toString() : '';
              const Town = dataset[i][5] ? dataset[i][5].toString() : '';
              const CardNo = dataset[i][9] ? dataset[i][9].toString() : '';
              const VoucherNo = dataset[i][10] ? dataset[i][10].toString() : '';
              const InvoiceNo = dataset[i][13] ? dataset[i][13].toString() : '';
              new_data.push([
                RegistrationNo,
                Date,
                CardNo,
                VoucherNo,
                FuelStation,
                Town,
                OdoReading,
                Litres,
                Amount,
                InvoiceNo,
              ]);
            }
            
          
        }
        this.dataset = new_data;
        this.selected_date_format = 'yyyymmdd';
        this.DateFormat(false);

      } else if (this.selected_dictionary === '30') {
        const dataset = this.raw_dataset;
        const new_headers = [];
        for (var i = 0; i < dataset[0].length; i++) {
          if (i + 1 === 6) {
            new_headers.push('Registration No');
          } else if (i + 1 === 7) {
            new_headers.push('Card No');
          } else if (i + 1 === 10) {
            new_headers.push('Voucher No');
          } else if (i + 1 === 12) {
            new_headers.push('Date');
          } else if (i + 1 === 13) {
            new_headers.push('Time');
          } else if (i + 1 === 15) {
            new_headers.push('Fuel Station');
          }else if (i + 1 === 16) {
            new_headers.push('Amount');
          } else if (i + 1 === 17) {
            new_headers.push('Odo Reading');
          } else if (i + 1 === 18) {
            new_headers.push('Litres');
          } else if (i + 1 === 37) {
            new_headers.push('Purchase Type');
          }else if (i + 1 === 81) {
            new_headers.push('Town');
          } else if (i + 1 === 82) {
            new_headers.push('Oil Company');
          } else {
            new_headers.push('Col ' + (i + 1));
          }
        }

        this.colHeaders = new_headers;
        this.dataset = dataset;

        this.selected_date_format = 'yyyymmdd';
        this.selected_time_format = '13:30:55';
        this.DateFormat(false);
        this.TimeFormat(false);
      } else if (this.selected_dictionary === '31') {
        const dataset = this.raw_dataset;
        const new_data = [];
        this.colHeaders = [
          'Registration No',
          'Driver',
          'Date',
          'Time',
          'Fuel Station',
          'Fuel Company',
          'Litres',
          'Amount',
          'Odo Reading',
          'Purchase Type',
        ];
        var raw_text = '';
        for (var i = 0; i < dataset.length; i++) {
          raw_text = raw_text + dataset[i]
        }
        const segments = raw_text.split("000100000000000000000000");
        for (var r = 0; r < segments.length; r++) {
          
          if(segments[r].includes('FUEL')){
            const vehicles = segments[r].split('GN1');
            for (var i = 0; i < this.getVehicles(vehicles).length; i++) {
              new_data.push([
                this.getVehicles(vehicles)[i].RegistrationNo,
                this.getVehicles(vehicles)[i].Driver,
                this.getVehicles(vehicles)[i].Date,
                this.getVehicles(vehicles)[i].Time,
                this.getVehicles(vehicles)[i].FuelStation,
                this.getVehicles(vehicles)[i].FuelCompany,
                this.getVehicles(vehicles)[i].Litres,
                this.getVehicles(vehicles)[i].Amount,
                this.getVehicles(vehicles)[i].OdoReading,
                "Fuel",
              ]);
            }
           
          }
        }
        this.dataset = new_data;
        this.selected_date_format = 'yyyymmdd';
        this.selected_time_format = '13:30';
        this.DateFormat(false);
        this.TimeFormat(false);
      }else if (this.selected_dictionary === '21') {
        this.colHeaders = [
          'Registration No',
          'Driver',
          'Date',
          'Time',
          'Card No',
          'Voucher No',
          'Fuel Station',
          'Odo Reading',
          'Litres',
          'Amount',
          'Purchase Type',
        ];

        const dataset = this.raw_dataset.slice(1, -1);
        const new_data = [];
        for (var i = 0; i < dataset.length; i++) {
          const single_line = dataset[i][0] ? dataset[i][0].toString() : '';

          const Date = single_line.substring(20, 26);
          const Date_Converted =Date.substring(0, 6);

          const Time = single_line.substring(26, 30);
          const Time_Converted = Time.substring(0, 4);

          const RegistrationNo = single_line
            .substring(118, 126)
            .replaceAll(' ', '');
          const Driver = single_line.substring(84, 91);
          const CardNo = single_line.substring(1, 20);

          const VoucherNo = single_line.substring(74, 84);
          const FuelStation = single_line.substring(46, 74);
          const OdoReading = single_line.substring(103, 110);
          const Litres = (Number(single_line.substring(110, 117)) / 100).toString();

          const Amount = (Number(single_line.substring(92, 103)) / 100).toString();

          const PurchaseType = single_line.substring(32, 34);
          if (PurchaseType == '01') {
            new_data.push([
              RegistrationNo,
              Driver,
              Date_Converted,
              Time_Converted,
              CardNo,
              VoucherNo,
              FuelStation,
              OdoReading,
              Litres,
              Amount,
              "Fuel",
            ]);
          }
        }
        this.dataset = new_data;
        this.selected_date_format = 'yymmdd';
        this.selected_time_format = '1330';
        this.DateFormat(false);
        this.TimeFormat(false);
      } else if (this.selected_dictionary === '11') {
        this.colHeaders = [
          'Registration No',
          'Date',
          'Time',
          'Voucher No',
          'Fuel Station',
          'Town',
          'Odo Reading',
          'Litres',
          'Amount',
        ];

        const dataset = this.raw_dataset.slice(1, -1);
        const new_data = [];
        for (var i = 0; i < dataset.length; i++) {
          const single_line = dataset[i][0] ? dataset[i][0].toString() : '';

          //Auto Format Date and Time
          this.selected_date_format = 'yyyymmdd';
          this.selected_time_format = '1330';

          const Date = single_line.substring(84, 92);
          const Date_Converted =
            Date.substring(6, 8) +
            '/' +
            Date.substring(4, 6) +
            '/' +
            Date.substring(0, 4);

          const Time = single_line.substring(145, 149);
          const Time_Converted =
            Time.substring(0, 2) + ':' + Time.substring(2, 4);

          const RegistrationNo = single_line
            .substring(0, 10)
            .replaceAll(' ', '');

          const VoucherNo = single_line.substring(108, 122);
          const FuelStation = single_line.substring(34, 64);
          const Town = single_line.substring(64, 84);
          const OdoReading = single_line.substring(92, 98);
          const Litres = (
            Number(single_line.substring(132, 139).toString()) / 100
          ).toString();

          const Amount = (
            Number(single_line.substring(123, 131)) / 100
          ).toString();

          new_data.push([
            RegistrationNo,
            Date_Converted,
            Time_Converted,
            VoucherNo,
            FuelStation,
            Town,
            OdoReading,
            Litres,
            Amount,
          ]);
        }
        this.dataset = new_data;
      } else if(this.selected_dictionary === '22'){
        this.isLoading = true;
        this.tableHidden = true;
        const new_headers:any = [];
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          /* read workbook */
          const ab = e.target.result;
          const wb = read(ab);
    
          /* grab first sheet */
          if(wb.SheetNames[1]){
            const wsname: string = wb.SheetNames[1];
        
            const ws = wb.Sheets[wsname];
      
            // const data:any = utils.sheet_to_json(ws, {header: 1});
            const data: any = utils.sheet_to_json(ws, { header: 1 }).slice(1);


            // for (var i = 0; i < data[0].length; i++) {
            //   new_headers.push('Col ' + (i + 1));
            // }
            this.colHeaders = [
              'Registration No',
              'Date',
              'Time',
              'Card No',
              'Fuel Station',
              'Odo Reading',
              'Litres',
              'Amount',
            ];  

            this.dataset = data;
            this.raw_dataset = data;
            this.isLoading = false;
            this.tableHidden = false;

            const new_data = [];
            for (var i = 1; i < data.length; i++) {
             
              if(data[i][6] && data[i][6].toString().length>2){
                
                const RegistrationNo = data[i][6] && data[i][6]!==''?data[i][6].toString().replaceAll(' ',''):"";
                const Date = data[i][2].length>=15?data[i][2].toString().substring(0,9):'01/01/2001';
                const Time = data[i][2].length>=15?data[i][2].toString().substring(10,15):'00:00';
                const FleetNo  =  data[i][7] && data[i][7]!==""?data[i][7].toString():"";
                const OdoReading = data[i][15] && data[i][15]!==''? data[i][15].toString().replaceAll(' ',''):"0";
                const Litres = data[i][19] &&  data[i][19]!==''? data[i][19].toString().replaceAll(' ',''):"0";
                const Amount = data[i][11] && data[i][11]!==''? data[i][11].toString().replaceAll('-',''):"0";
                const CardNo = data[i][5] &&  data[i][5]!==''? data[i][5].toString():"";  
                const FuelStation = data[i][8] && data[i][8]!==''? data[i][8].toString():""; 

                new_data.push([
                  RegistrationNo,
                  Date,
                  Time,
                  CardNo,
                  FuelStation,
                  OdoReading,
                  Litres,
                  Amount,
                ]);
              }
              
            
            }
            this.dataset = new_data;
            this.selected_date_format = 'ddmmmyyy';
            this.selected_time_format = '1330';
            this.DateFormat(false);
            this.TimeFormat(false);
              
          }else{
            this.selected_dictionary = '100'
            this.preview_file(this.File);
          }
          
        };
        reader.readAsArrayBuffer(this.File);
      } else if (this.selected_dictionary === '100') {
        const dataset = this.raw_dataset;
        const new_headers = [];
        for (var i = 0; i < dataset[0].length; i++) {
          new_headers.push('Col ' + (i + 1));
        }

        this.colHeaders = new_headers;
        this.dataset = dataset;
      }else if (this.selected_dictionary === '23') {

        this.dataset = this.raw_dataset.slice(7);
        // this.selected_date_format = 'yymmdd';
        // this.selected_time_format = '1330';
        // this.DateFormat(false);
        // this.TimeFormat(false);
      }else{
        const dataset = this.raw_dataset;
        const new_headers = [];
        for (var i = 0; i < dataset[0].length; i++) {
          new_headers.push('Col ' + (i + 1));
        }

        this.colHeaders = new_headers;
        this.selected_dictionary = '100';
        this.dataset = dataset;
      }


      if(this.selected_layout && this.selected_layout.date_format!==''){
        this.selected_date_format = this.selected_layout.date_format;
        this.DateFormat(false)
      }
      if(this.selected_layout && this.selected_layout.time_format!==''){
        this.selected_time_format = this.selected_layout.time_format;
        this.TimeFormat(false)
      }
    } else {
      this.openSnackBar('No file uploaded', 'Okay', 'error');
    }
  }

  validateAvisWesbankId(event: any) {
    this.avis_wesbank_id = event.value.toString().replaceAll(' ','');
    if(this.selected_dictionary==="2" || this.selected_dictionary==="4"){
      this.formatDataset()
    }else{
      this.openSnackBar('Wesbank dictionary is not selected', 'Okay', 'error');
    }

  }
  selectHeader(event: any) {
    this.selected_heading = event.value;
  }

  selectColumn(event: any) {
    this.selected_column_position = event.value;
  }

  assignHeader() {
    const position = Number(this.selected_column_position);
    const new_data = [];
    const selected_heading_position =  this.colHeaders.findIndex((x) => x === this.selected_heading);
    if (selected_heading_position !== -1) {
      this.colHeaders[selected_heading_position] = 'Col '+(selected_heading_position+1);
    }
    for (var i = 0; i < this.colHeaders.length; i++) {
      if (i === position) {
        new_data.push(this.selected_heading);
      } else {
        new_data.push(this.colHeaders[i]);
      }
    }
    this.colHeaders = new_data;
    this.selected_heading = '';
  }

  selectDateFormat(event: any) {
    this.selected_date_format = event.value;
  }

  // date_unformatted_dataset = [];
  DateFormat(isFocusColumn:any) {
    const datefromformat = this.selected_date_format;
    const columnHeaders = this.colHeaders;
    if (columnHeaders.findIndex((x) => x === 'Date') !== -1) {
      const Date_Position = columnHeaders.findIndex((x) => x === 'Date');
      if (datefromformat === 'XXXXX') {
        const dataset = this.dataset;
        function SerialDateConvert(s: any) {
          if (!isNaN(s) && !s.includes('date')) {
            var serial_date = new Date(Math.round((s - 25569) * 86400 * 1000)).toLocaleDateString('en-US', {timeZone: 'Africa/Johannesburg',day: '2-digit',month: '2-digit',year: 'numeric',});
            var date =
              serial_date.substring(3, 5) +
              '/' +
              serial_date.substring(0, 2) +
              '/' +
              serial_date.substring(6, 10);
            return date;
          } else {
            return s;
          }
        }
        const newdata = [];
        for (var i = 0; i < dataset.length; i++) {
          const record = dataset[i].map((x: any, d: any) => {
            const item = x? x.toString().length > 0  ? x.toString()  : ' ': ' ';
            if (d === Date_Position) {
              return SerialDateConvert(item);
            } else {
              return item;
            }
          });
          newdata.push(record);
        }
        this.dataset = newdata;
        if(isFocusColumn){
          this.hotRegisterer.getInstance(this.id).selectColumns(Date_Position);
        }
      } else if (datefromformat === 'yyyymmdd') {
        const dataset = this.dataset;

        const newdata = [];
        for (var i = 0; i < dataset.length; i++) {
          const record = dataset[i].map((x: any, d: any) => {
            const item = x? x.toString().length > 0  ? x.toString()  : ' ': ' ';
            if (d === Date_Position) {
              if (!isNaN(item) && !item.includes('date')) {
                const modified_date = item
                  ? item.toString().substring(6, 8) +
                    '/' +
                    item.toString().substring(4, 6) +
                    '/' +
                    item.toString().substring(0, 4)
                  : '';
                return modified_date;
              } else {
                return item;
              }
            } else {
              return item;
            }
          });
          newdata.push(record);
        }
        this.dataset = newdata;
        if(isFocusColumn){
          this.hotRegisterer.getInstance(this.id).selectColumns(Date_Position);
        };
      } else if (datefromformat === 'yyyy/mm/dd hh:mm') {
        const dataset = this.dataset;

        const newdata = [];
        for (var i = 0; i < dataset.length; i++) {
          const record = dataset[i].map((x: any, d: any) => {
            const item = x? x.toString().length > 0  ? x.toString()  : ' ': ' ';
            if (d === Date_Position) {
              if (item.length >=10 && (item.includes("/") || item.includes("-")|| item.includes(".")|| item.includes(":")) && !item.includes('date')) {
                return (item.substring(8, 10) +'/' +item.substring(5, 7) +'/' +item.substring(0, 4)
                );
              } else {
                return item;
              }
            } else {
              return item;
            }
          });
          newdata.push(record);
        }
        this.dataset = newdata;
        this.hotRegisterer.getInstance(this.id).selectColumns(Date_Position);
      } else if (datefromformat === 'yymmdd') {
        const dataset = this.dataset;

        const newdata = [];
        for (var i = 0; i < dataset.length; i++) {
          const record = dataset[i].map((x: any, d: any) => {
            const item = x
              ? x.toString().length > 0
                ? x.toString()
                : ' '
              : ' ';
            if (d === Date_Position) {
              if (item.length === 6 && !item.includes('date')) {
                return (item.substring(4, 6)+'/'+item.substring(2, 4)+'/'+'20' +item.substring(0, 2));
              } else {
                return item;
              }
            } else {
              return item;
            }
          });
          newdata.push(record);
        }
        this.dataset = newdata;
        if(isFocusColumn){
          this.hotRegisterer.getInstance(this.id).selectColumns(Date_Position);
        }
      } else if (datefromformat === 'ddmmyyyy') {
        const dataset = this.dataset;

        const newdata = [];
        for (var i = 0; i < dataset.length; i++) {
          const record = dataset[i].map((x: any, d: any) => {
            const item = x
              ? x.toString().length > 0
                ? x.toString()
                : ' '
              : ' ';
            if (d === Date_Position) {
              if (item.length === 10 && !item.includes('date')) {
                return item.substring(0, 2) + '/' + item.substring(3, 5) + '/' + item.substring(6, 10);
              } else if(item.length === 9){
                return "0"+item.substring(0, 1)+"/"+item.substring(2, 4)+"/"+item.substring(5, 9);
              } else {
                return item;
              }
            } else {
              return item;
            }
          });
          newdata.push(record);
        }
        this.dataset = newdata;
        if(isFocusColumn){
          this.hotRegisterer.getInstance(this.id).selectColumns(Date_Position);
        }
      } else if (datefromformat === 'ddmmmyyy') {
        const dataset = this.dataset;

        const newdata = [];
        for (var i = 0; i < dataset.length; i++) {
          const record = dataset[i].map((x: any, d: any) => {
            const item = x ? x.toString().length > 0? x.toString()   : ' ' : ' ';
            if (d === Date_Position) {
              if (item.length >= 9 && !item.includes('date')) {
                const today = new Date(item);
                const yyyy = today.getFullYear();
                let mm:any = today.getMonth() + 1; // Months start at 0!
                let dd:any = today.getDate();

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                const formattedToday = dd + '/' + mm + '/' + yyyy;
                return formattedToday;

              } else {
                return item;
              }
            } else {
              return item;
            }
          });
          newdata.push(record);
        }
        this.dataset = newdata;
        if(isFocusColumn){
          this.hotRegisterer.getInstance(this.id).selectColumns(Date_Position);
        }
      }else {
        this.openSnackBar(
          'We are still working on this feature',
          'Okay',
          'success'
        );
      }
    } else {
      this.selected_date_format = "";
      this.openSnackBar('Date header not assigned', 'Okay', 'error');
    }
  }
  selectTimeFormat(event: any) {
    this.selected_time_format = event.value;
  }
  TimeFormat(isFocusColumn:any) {
    const timefromformat = this.selected_time_format;
    const columnHeaders = this.colHeaders;

    if (columnHeaders.findIndex((x) => x === 'Time') !== -1) {
      const Time_Position = columnHeaders.findIndex((x) => x === 'Time');
      if (timefromformat === '1330') {
        const dataset = this.dataset;

        const newdata = [];
        for (var i = 0; i < dataset.length; i++) {
          const record = dataset[i].map((x: any, d: any) => {
            const item = x? x.toString().length > 0? x.toString(): ' ': ' ';
            if (d === Time_Position) {
              if(item.length === 4) {
                return item.substring(0, 2) + ':' + item.substring(2, 4);
              } else if(item.length === 3){
                  return "0"+item.substring(0, 1) +":" + item.substring(1, 3);
              } else {
                return item;
              }
            } else {
              return item;
            }
          });
          newdata.push(record);
        }
        this.dataset = newdata;
        if(isFocusColumn){
          this.hotRegisterer.getInstance(this.id).selectColumns(Time_Position);
        }
      } else if(timefromformat === '13:30') {
          const dataset = this.dataset;
  
          const newdata = [];
          for (var i = 0; i < dataset.length; i++) {
            const record = dataset[i].map((x: any, d: any) => {
              const item = x? x.toString().length > 0? x.toString(): ' ': ' ';
              if (d === Time_Position) {
                if (item.length === 5 && item.includes(":")) {
                  return item.substring(0, 2) + ':' + item.substring(3, 5);
                } else {
                  return item;
                }
              } else {
                return item;
              }
            });
            newdata.push(record);
          }
          this.dataset = newdata;
          if(isFocusColumn){
            this.hotRegisterer.getInstance(this.id).selectColumns(Time_Position);
          }
        
      }else if(timefromformat === '13:30:55') {
        const dataset = this.dataset;

        const newdata = [];
        for (var i = 0; i < dataset.length; i++) {
          const record = dataset[i].map((x: any, d: any) => {
            const item = x? x.toString().length > 0? x.toString(): ' ': ' ';
            if (d === Time_Position) {
              if (item.length === 8 && item.includes(":")) {
                return item.substring(0, 2) + ':' + item.substring(3, 5);
              } else {
                return item;
              }
            } else {
              return item;
            }
          });
          newdata.push(record);
        }
        this.dataset = newdata;
        if(isFocusColumn){
          this.hotRegisterer.getInstance(this.id).selectColumns(Time_Position);
        } 
      
    }else if(timefromformat === '0.999999') {
      const dataset = this.dataset;
      function convertNumToTime(number:any) {
        var serial = number;
        var fractional_day = serial - Math.floor(serial) + 0.0000001;
        var total_seconds = Math.floor(86400 * fractional_day);
        var seconds = total_seconds % 60;
        total_seconds -= seconds;
        var hours = Math.floor(total_seconds / (60 * 60));
        var minutes = Math.floor(total_seconds / 60) % 60;

        var hourrs = hours<10?"0"+hours:hours;
        var minutess = minutes<10?"0"+minutes:minutes;
        return hourrs +":"+minutess;
      }

      const newdata = [];
      for (var i = 0; i < dataset.length; i++) {
        const record = dataset[i].map((x: any, d: any) => {
          const item = x? x.toString().length > 0? x.toString(): ' ': ' ';
          if (d === Time_Position) {
            if (item.length>1 && item.includes(".")) {
              return convertNumToTime(item);
            } else {
              return item;
            }
          } else {
            return item;
          }
        });
        newdata.push(record);
      }
      this.dataset = newdata;
      if(isFocusColumn){
        this.hotRegisterer.getInstance(this.id).selectColumns(Time_Position);
      }
    
    }else {
        this.openSnackBar('We are still working on this feature','Okay','success'
        );
      }
    } else {
      this.selected_time_format = "";
      this.openSnackBar('Time header not assigned', 'Okay', 'error');
    }
  }

  response = '';
  isDialogData: any = null;

  closeDialog(){
    this.isDialogData = null;
  }
  ViewReport(FILE_NAME: any) {
    const dialogRef = this.dialog.open(ViewReportComponent, {
      data: {
        FILE_NAME:FILE_NAME,
        data: null,
      },
    });
  }
  Import(confirm: any) {
    this.isDialogData = null;
    const columnHeaders = this.colHeaders;
    const Amount_Position = columnHeaders.findIndex((x) => x === 'Amount');
    const FuelStation_Position = columnHeaders.findIndex((x) => x === 'Fuel Station');
    const Liters_Position = columnHeaders.findIndex((x) => x === 'Litres');
    const OdoReading_Position = columnHeaders.findIndex((x) => x === 'Odo Reading');
    const Date_Position = columnHeaders.findIndex((x) => x === 'Date');
    const RegistrationNo_Position = columnHeaders.findIndex((x) => x === 'Registration No');
    const DriverName_Position = columnHeaders.findIndex((x) => x === 'Driver');
    const Time_Position = columnHeaders.findIndex((x) => x === 'Time');
    const Town_Position = columnHeaders.findIndex((x) => x === 'Town');
    const OilCompany_Position = columnHeaders.findIndex((x) => x === 'Oil Company');
    const PricePerLitre_Position = columnHeaders.findIndex((x) => x === 'Price Per Litre');


    const VoucherNo_Position = columnHeaders.findIndex((x) => x === 'Voucher No');
    const FuelCompany_Position  = columnHeaders.findIndex((x) => x === 'Fuel Company');
    const CardNo_Position  = columnHeaders.findIndex((x) => x === 'Card No');
    const PumpAttendant_Position  = columnHeaders.findIndex((x) => x === 'Pump Attendant');
    const TankFull_Position  = columnHeaders.findIndex((x) => x === 'Tank Full');
    const DistTravelled_Position  = columnHeaders.findIndex((x) => x === 'Dist Travelled');
    const L100_Position  = columnHeaders.findIndex((x) => x === 'L/100');
    const KML_Position  = columnHeaders.findIndex((x) => x === 'Km/L');
    const FromLocation_Position  = columnHeaders.findIndex((x) => x === 'From Location');
    const ToLocation_Position  = columnHeaders.findIndex((x) => x === 'To Location');
    const FridgeLitres_Position  = columnHeaders.findIndex((x) => x === 'Fridge Litres');
    const Comments_Position  = columnHeaders.findIndex((x) => x === 'Comments');
    const InvoiceNo_Position  = columnHeaders.findIndex((x) => x === 'Invoice No');
    const PickupType_Position  = columnHeaders.findIndex((x) => x === 'Pickup Type');
    const Home_Position  = columnHeaders.findIndex((x) => x === 'Home');
    const Seal1_Position  = columnHeaders.findIndex((x) => x === 'Seal 1');
    const Seal2_Position  = columnHeaders.findIndex((x) => x === 'Seal 2');

    const PurchaseType_Position  = columnHeaders.findIndex((x) => x === 'Purchase Type');

    const payload:any = [];
    const dataset = this.isSkipFirstRow==="1"? this.dataset.slice(1): this.dataset;

    if (RegistrationNo_Position === -1) {
      this.isDialogData = {
        "type":"import-validations",
        "heading": "The following header is not assigned:",
        "message":"Registration No"
      };
    } else if (Date_Position === -1) {
      this.isDialogData = {
        "type":"import-validations",
        "heading": "The following header is not assigned:",
        "message":"Date"
      };
    } else if (FuelStation_Position === -1) {
      this.isDialogData = {
        "type":"import-validations",
        "heading": "The following header is not assigned:",
        "message":"Fuel Station"
      };
    } else if (OdoReading_Position === -1) {
      this.isDialogData = {
        "type":"import-validations",
        "heading": "The following header is not assigned:",
        "message":"Odo Reading"
      };
    } else if (Liters_Position === -1) {
      this.isDialogData = {
        "type" : "import-validations",
        "heading" : "The following header is not assigned:",
        "message" : "Litres"
      };
    }else if (Amount_Position === -1) {
      this.isDialogData = {
        "type":"import-validations",
        "heading": "The following header is not assigned:",
        "message":"Amount"
      };
    }else if (dataset.length>3 && dataset[2][Date_Position].length!==10) {
      this.isDialogData = {
        "type" : "import-validations",
        "heading" : "Date is not formatted correctly",
        "message" : "Date should be in this format: dd/mm/yyyy"
      };
    }else if (this.selected_dictionary==="2" && this.avis_wesbank_id==="") {
      this.isDialogData = {
        "type" : "import-validations",
        "heading" : "Wesbank Id is required",
        "message" : "This is used to retrieve transactions that matches CLNT_CODE only."
      };
    }else if ((dataset.length>0) && (dataset[0][Liters_Position].toString().toLowerCase().includes("litres") || dataset[0][Date_Position].toString().toLowerCase().includes("time") || dataset[0][Date_Position].toString().toLowerCase().includes("date") || dataset[0][RegistrationNo_Position].toString().toLowerCase().includes("regis") || dataset[0][OdoReading_Position].toString().toLowerCase().includes("odo")) ) {
      this.isDialogData = {
        "type":"import-validations",
        "heading": "Cannot import with headers",
        "message":"Skip the headers to processed"
      };
    }else {
      var errorList = [];
      var Line = this.isSkipFirstRow==="1"?1:0;
      for (var i = 0; i < dataset.length; i++) {
          Line ++;
          const RegistrationNo = dataset[i][RegistrationNo_Position] && dataset[i][RegistrationNo_Position].toString().replaceAll(' ','')!==''?dataset[i][RegistrationNo_Position].toString().replaceAll(' ',''):"";
          const Time = dataset[i][Time_Position] && dataset[i][Time_Position]!==''?dataset[i][Time_Position].toString().replaceAll(' ','')+":00.0":"00:00:00.0";
          const Date = dataset[i][Date_Position] && dataset[i][Date_Position]!==''?dataset[i][Date_Position].substring(6, 10)+"-"+dataset[i][Date_Position].substring(3, 5)+"-"+dataset[i][Date_Position].substring(0, 2):"2000-01-01";
          const DateTime = Date+" "+Time;
          const Amount = dataset[i][Amount_Position] && dataset[i][Amount_Position].toString().replaceAll(' ','')!==''?dataset[i][Amount_Position].toString().replaceAll(' ',''):"0";
          const FuelStation =  dataset[i][FuelStation_Position] && dataset[i][FuelStation_Position]!==''?dataset[i][FuelStation_Position].toString():"";
          const Litres = dataset[i][Liters_Position] && dataset[i][Liters_Position].toString().replaceAll(' ','')!==''?dataset[i][Liters_Position].toString().replaceAll(' ',''):"0";
          const OdoReading = dataset[i][OdoReading_Position] && dataset[i][OdoReading_Position].toString().replaceAll(' ','')!==''?dataset[i][OdoReading_Position].toString().replaceAll(' ',''):"0";
          const DriverName = dataset[i][DriverName_Position] && dataset[i][DriverName_Position]!==''?dataset[i][DriverName_Position].toString():"";
          const Town = dataset[i][Town_Position]?dataset[i][Town_Position].toString():"";
          const OilCompany = dataset[i][OilCompany_Position] && dataset[i][OilCompany_Position]!==""?dataset[i][OilCompany_Position].toString():"";
          const CompanyName = this._companyServ.getCompanyName()!.toString();
          const calculatedPricePerLitre = (Number(Amount)/Number(Litres)).toFixed(2);
          const PricePerLitre = dataset[i][PricePerLitre_Position] && dataset[i][PricePerLitre_Position].toString().replaceAll(' ','')!==''?dataset[i][PricePerLitre_Position].toString().replaceAll(' ',''):calculatedPricePerLitre.toString();
          const VoucherNo = dataset[i][VoucherNo_Position]?dataset[i][VoucherNo_Position].toString():"";
          const FuelCompany = dataset[i][FuelCompany_Position]?dataset[i][FuelCompany_Position].toString():"";
          const CardNo = dataset[i][CardNo_Position]?dataset[i][CardNo_Position].toString():"";
          const PumpAttendant = dataset[i][PumpAttendant_Position]?dataset[i][PumpAttendant_Position].toString():"";
          const TankFull = dataset[i][TankFull_Position]?dataset[i][TankFull_Position].toString():"";
          const DistTravelled = dataset[i][DistTravelled_Position]?dataset[i][DistTravelled_Position].toString():"";
          const L100 = dataset[i][L100_Position]?dataset[i][L100_Position].toString():"";
          const KML = dataset[i][KML_Position]?dataset[i][KML_Position].toString():"";
          const FromLocation = dataset[i][FromLocation_Position]?dataset[i][FromLocation_Position].toString():"";
          const ToLocation = dataset[i][ToLocation_Position]?dataset[i][ToLocation_Position].toString():"";
          const FridgeLitres = dataset[i][FridgeLitres_Position]?dataset[i][FridgeLitres_Position].toString():"";
          const Comments = dataset[i][Comments_Position]?dataset[i][Comments_Position].toString():"";
          const InvoiceNo = dataset[i][InvoiceNo_Position]?dataset[i][InvoiceNo_Position].toString():"";
          const PickupType = dataset[i][PickupType_Position]?dataset[i][PickupType_Position].toString():"";
          const Home = dataset[i][Home_Position]?dataset[i][Home_Position].toString():"";
          const Seal1 = dataset[i][Seal1_Position]?dataset[i][Seal1_Position].toString():"";
          const Seal2 = dataset[i][Seal2_Position]?dataset[i][Seal2_Position].toString():"";

          

          function pushToTransactions(){
            payload.push({
              "acceptTerms": "",
              "Amount": Amount,
              "FuelStation": FuelStation,
              "fullCompany": FuelCompany,
              "FROMFUELSTATIONNAME": "",
              "litrePrice": PricePerLitre,
              "Litres": Litres,
              "OdoReading": OdoReading,
              "ReceiptDate": DateTime,
              "RegistrationNo": RegistrationNo,
              "ReceiptNo":"",
              "driverName": DriverName,
              "time": Time,
              "makeRange": "",
              "Action":"INSERT",
              "CardNo": CardNo,
              "VoucherNo": VoucherNo,
              "OilCompany": OilCompany,
              "Town": Town,
              "PumpAttendant": PumpAttendant,
              "TankFull": TankFull,
              "DistTravelled": DistTravelled,
              "L/100": L100,
              "Km/L":KML,
              "FromLocation": FromLocation,
              "ToLocation": ToLocation,
              "FridgeLitres": FridgeLitres,
              "Comments": Comments,
              "InvoiceNo": InvoiceNo,
              "PickupType": PickupType,
              "Home": Home,
              "Seal1": Seal2,
              "Seal2": Seal1,
            }); 
          }
        if(confirm==='force-import'){
          errorList = [];
          
          if(this.selected_dictionary==='1'){
          
            const TRANSACTION_DESCRIPTION = dataset[i][15]? dataset[i][15].toString().replaceAll(' ','').toLowerCase(): 'N/A';
            if(TRANSACTION_DESCRIPTION.includes("fuel")){
              pushToTransactions();
            }
            
          }else if(this.selected_dictionary==='2'){
          
            const CLNT_CODE = dataset[i][0]? dataset[i][0].toString().replaceAll(' ','') : 'N/A';
            const DESCRIPTION = dataset[i][10]? dataset[i][10].toString().replaceAll(' ','').toLowerCase(): 'N/A';
            if(DESCRIPTION.includes("fuel") && this.avis_wesbank_id ===CLNT_CODE){
              pushToTransactions();
            }
            
          }else if(this.selected_dictionary==='4'){
          
            const CLIENT_CODE = dataset[i][0]? dataset[i][0].toString().replaceAll(' ','') : 'N/A';
            const DESCRIPTION = dataset[i][9]? dataset[i][9].toString().replaceAll(' ','').toLowerCase(): 'N/A';
            if(DESCRIPTION.includes("fuel") && this.avis_wesbank_id ===CLIENT_CODE){
              pushToTransactions();
            }
            
          }else if(this.selected_dictionary==='30'){
          
            const PURCHASE_DESCRIPTION = dataset[i][36]? dataset[i][36].toString().replaceAll(' ','').toLowerCase(): 'N/A';
            if(PURCHASE_DESCRIPTION.includes("fuel")){
              pushToTransactions();
            }
            
          }else{
            pushToTransactions();
          }
        }else{
          if(RegistrationNo==="Not Provided"){
            errorList.push({
              "Line": Line,
              "Error": "Registration No is empty, will set 'Not Provided' by default."

              })
          }
          if(Date==='2000-01-01' ){
            errorList.push({
              "Line": Line,
              "Error": "Date is empty, will set '01/01/2000' by default."

              })
          }
          if(FuelStation==='Not Provided'){
            errorList.push({
              "Line": Line,
              "Error": "Fuel Station is empty, will set 'Not Provided' by default."

            })
          }
          if(OdoReading==='0'){
              errorList.push({
                "Line": Line,
                "Error": "Odo Reading is empty, will set 0 by default."

              })
          }
          if(Litres==='0'){
              errorList.push({
                "Line": Line,
                "Error": "Litres is empty, will set 0 by default."

              })
          }
          if(Amount==='0'){
            errorList.push({
              "Line": Line,
              "Error": "Amount is empty, will set 0 by default."

            })
          }
          if(this.selected_dictionary==='2'){

            const CLNT_CODE = dataset[i][0] ? dataset[i][0].toString().replaceAll(' ','') : 'N/A';
            const DESCRIPTION = dataset[i][10]? dataset[i][10].toString().replaceAll(' ','').toLowerCase(): 'N/A';
            
            if(this.avis_wesbank_id !==CLNT_CODE){
              const newlist:any = errorList.filter(function (x:any) {
                if (x.Line!==Line) {
                  return x;
                }
              });
              errorList = newlist;
              errorList.push({
                "Line": Line,
                "Error": "Ignored, system will skip this line. Wesbank id not correspond."
              })
            }
            if(!DESCRIPTION.includes("fuel")){
              const newlist:any = errorList.filter(function (x:any) {
                if (x.Line!==Line) {
                  return x;
                }
              });
              errorList = newlist;
              errorList.push({
                "Line": Line,
                "Error": "Ignored, system will skip this line. Purchase type is not FUEL."
  
              })
            }
          }
          if(this.selected_dictionary==='4'){

            const CLIENT_CODE = dataset[i][0] ? dataset[i][0].toString().replaceAll(' ','') : 'N/A';
            const DESCRIPTION = dataset[i][9]? dataset[i][9].toString().replaceAll(' ','').toLowerCase(): 'N/A';
            
            if(this.avis_wesbank_id !==CLIENT_CODE){
              const newlist:any = errorList.filter(function (x:any) {
                if (x.Line!==Line) {
                  return x;
                }
              });
              errorList = newlist;
              errorList.push({
                "Line": Line,
                "Error": "Ignored, system will skip this line. Wesbank id not correspond."
              })
            }
            if(!DESCRIPTION.includes("fuel")){
              const newlist:any = errorList.filter(function (x:any) {
                if (x.Line!==Line) {
                  return x;
                }
              });
              errorList = newlist;
              errorList.push({
                "Line": Line,
                "Error": "Ignored, system will skip this line. Purchase type is not FUEL."
  
              })
            }
          }
          if(this.selected_dictionary==='1'){

            const TRANSACTION_DESCRIPTION = dataset[i][15]? dataset[i][15].toString().replaceAll(' ','').toLowerCase(): 'N/A';
            if(!TRANSACTION_DESCRIPTION.includes("fuel")){
              const newlist:any = errorList.filter(function (x:any) {
                if (x.Line!==Line) {
                  return x;
                }
              });
              errorList = newlist;
              errorList.push({
                "Line": Line,
                "Error": "Ignored, system will skip this line. Purchase type is not FUEL."
  
              })
            }
          }
          if(this.selected_dictionary==='30'){

            const PURCHASE_DESCRIPTION = dataset[i][36]? dataset[i][36].toString().replaceAll(' ','').toLowerCase(): 'N/A';
            if(!PURCHASE_DESCRIPTION.includes("fuel")){
              const newlist:any = errorList.filter(function (x:any) {
                if (x.Line!==Line) {
                  return x;
                }
              });
              errorList = newlist;
              errorList.push({
                "Line": Line,
                "Error": "Ignored, system will skip this line. Purchase type is not FUEL."
  
              })
            }
          }
          pushToTransactions();
        }
          

          
          
      }
      if(errorList.length>0 && confirm==="yes"){
        this.isDialogData = {
          "type":"importing-warnings",
          "heading": "",
          "message":"",
          "errorList": errorList
        };
      }else{
        if (payload.length > 0) {
          this.response = 'loading';
          this.isLoading = true;
          this.tableHidden = true;
          const fileName = this.File.name;
          this._transactionServ.PostFuelTransactions(payload,this._companyServ.getCompanyId(),this.getFileName()).subscribe({
              next: (data: any) => {
                if(data.statusMessage.includes('successfully')){
                  this._snackBar.open('Imported Files: '+ payload.length, 'Okay', {
                    panelClass: 'success',
                  });

                  this.response = 'success';
                  this.closeModal();
                  // this.ViewReport(this.getFileName())
                  document.getElementById("reload_transaction_table")!.click();
                  document.getElementById("reload_recon_report")!.click();
                }else{
                  this.tableHidden = false;
                  this.isLoading = false;
                  this.response = 'network_err';
                  this.openSnackBar(
                    'Something went wrong',
                    'Okay',
                    'error'
                  );
                }
          
              },
              error: (error: any) => {
                this.tableHidden = false;
                this.isLoading = false;
                this.response = 'network_err';
                this.openSnackBar(
                  'Ops, Check your network connectivity',
                  'Okay',
                  'error'
                );
              },
            });
        } else {
          this.openSnackBar('No transcations', 'Okay', 'error');
        }
      }
      
    }
  }

  private getFileName() {
    let formData = new FormData();
    // this.openSnackBar(response + '', 'OK', 'success');
    let companyName = this._companyServ.getCompanyName();
    let fileName = 'Capture';
    let uploadTime = new Date();
    let refFileName: any;
    let timeValue =
      uploadTime.getFullYear() +
      '_' +
      (uploadTime.getMonth() + 1) +
      '_' +
      uploadTime.getDate() +
      '_' +
      uploadTime.getHours() +
      uploadTime.getMinutes() +
      uploadTime.getSeconds();
    fileName = fileName.replaceAll(' ', '');
    return (refFileName =
      this._companyServ.getCompanyId() +
      '_' +
      companyName?.replaceAll(' ', '') +
      '_' +
      fileName.replaceAll(/[^a-zA-Z ]/g, '') +
      '_' +
      timeValue?.replaceAll(' ', ''));
  }


  handle_layout_name(d:any){
    this.layout_name = d.value;
  }
  FetchLayouts(){
    this.response_layouts_data = this._transactionServ.getLayouts(this._StorageServ.getUserId()).results;
  }
  saveLayout(){
  
    if(this.layout_name===''){
      (document.querySelector('.confirm-modal .layouts input') as HTMLElement)!.focus();
    }else{
      const headers = [];
      for (var i = 0; i < this.colHeaders.length; i++) {
        if(
          this.colHeaders[i]==='Registration No' || 
          this.colHeaders[i]==='Driver' ||
          this.colHeaders[i]==='Date' ||
          this.colHeaders[i]==='Time' ||
          this.colHeaders[i]==='Card No' ||
          this.colHeaders[i]==='Fuel Company' ||
          this.colHeaders[i]==='Voucher No' ||
          this.colHeaders[i]==='Fuel Station' ||
          this.colHeaders[i]==='Oil Company' ||
          this.colHeaders[i]==='Town' ||
          this.colHeaders[i]==='Pump Attendant' ||
          this.colHeaders[i]==='Odo Reading' ||
          this.colHeaders[i]==='Tank Full' ||
          this.colHeaders[i]==='Dist Travelled' ||
          this.colHeaders[i]==='L/100' ||
          this.colHeaders[i]==='Km/L' ||
          this.colHeaders[i]==='Litres' ||
          this.colHeaders[i]==='Price Per Litre' ||
          this.colHeaders[i]==='Amount' ||
          this.colHeaders[i]==='From Location' ||
          this.colHeaders[i]==='To Location' ||
          this.colHeaders[i]==='Fridge Litres' ||
          this.colHeaders[i]==='Comments' ||
          this.colHeaders[i]==='Invoice No' ||
          this.colHeaders[i]==='Pickup Type' ||
          this.colHeaders[i]==='Home' ||
          this.colHeaders[i]==='Seal 1' ||
          this.colHeaders[i]==='Seal 2'
        ){
          headers.push({
            "column_name": this.colHeaders[i],
            "column_position": i
          });
        }
          
      }
      const payload = {
        "id": Math.floor(Math.random() * 9999) + 99999,
        "user_id": this._StorageServ.getUserId(),
        "name": this.layout_name,
        "dictionary": this.selected_dictionary,
        "headers": headers,
        "skip_first_row": this.isSkipFirstRow,
        "time_format": this.selected_time_format,
        "date_format": this.selected_date_format
      }
      if(this._transactionServ.addLayout(payload)==='success'){
        this.openSnackBar('Layout saved', 'Okay', 'success');
        this.layout_name = '';
        this.isDialogData = null;
        this.FetchLayouts()
      }else{
        this.openSnackBar('Failed to add layout', 'Okay', 'error');
      } 
    }
    
  } 
  addNewLayout(){
    this.isDialogData = {
      "type":"add-new-layout",
      "heading": "",
      "message":""
    };
  }
  deleteLayout(r:any){
    if(this._transactionServ.deleteLayout(r.id)==='success'){
      if(this.selected_layout && this.selected_layout.id===r.id){
        this.selected_layout = null;
        this.selected_dictionary = '100';
        this.isSkipFirstRow = "0";
        this.selected_date_format = "";
        this.selected_time_format = "";
        this.formatDataset();
      }
      this.FetchLayouts();
      
    }else{
      this.openSnackBar('Failed to delete layout', 'Okay', 'error');
    }
    
  }
  
  selectLayout(r:any){
    if(this.raw_dataset.length > 0){
      if(this.selected_layout && this.selected_layout.id===r.id){
        this.selected_layout = null;
        this.selected_dictionary = '100';
        this.isSkipFirstRow = '';
        this.selected_date_format = '';
        this.selected_time_format = '';
        this.formatDataset();
        // const header = r.headers;
        // for (var i = 0; i < header.length; i++) {
        //   var index = this.colHeaders.indexOf(header[i].column_position);
        //   if (index !== -1) {
        //     this.colHeaders[index] = header[i].column_name;
        //   }
        // }
      
      }else{
        this.selected_layout = r;
        this.selected_dictionary = r.dictionary;
        this.formatDataset();
        if(r.skip_first_row==="1"){
          this.isSkipFirstRow = "1"
          document.querySelector('.importFileModal .handsontable table tbody tr:first-child')!.classList.add('skipped');
        }else{
          this.isSkipFirstRow = "0";
          document.querySelector('.importFileModal .handsontable table tbody tr:first-child')!.classList.remove('skipped');
        }
      }
    
      
  
      
    }else{
      this.openSnackBar('No file uploaded', 'Okay', 'error');
    }
  }
  getCustomHeaders(){
    const headers = [];
      for (var i = 0; i < this.colHeaders.length; i++) {
        if(
          this.colHeaders[i]==='Registration No' || 
          this.colHeaders[i]==='Driver' ||
          this.colHeaders[i]==='Date' ||
          this.colHeaders[i]==='Time' ||
          this.colHeaders[i]==='Card No' ||
          this.colHeaders[i]==='Fuel Company' ||
          this.colHeaders[i]==='Voucher No' ||
          this.colHeaders[i]==='Fuel Station' ||
          this.colHeaders[i]==='Oil Company' ||
          this.colHeaders[i]==='Town' ||
          this.colHeaders[i]==='Pump Attendant' ||
          this.colHeaders[i]==='Odo Reading' ||
          this.colHeaders[i]==='Tank Full' ||
          this.colHeaders[i]==='Dist Travelled' ||
          this.colHeaders[i]==='L/100' ||
          this.colHeaders[i]==='Km/L' ||
          this.colHeaders[i]==='Litres' ||
          this.colHeaders[i]==='Price Per Litre' ||
          this.colHeaders[i]==='Amount' ||
          this.colHeaders[i]==='From Location' ||
          this.colHeaders[i]==='To Location' ||
          this.colHeaders[i]==='Fridge Litres' ||
          this.colHeaders[i]==='Comments' ||
          this.colHeaders[i]==='Invoice No' ||
          this.colHeaders[i]==='Pickup Type' ||
          this.colHeaders[i]==='Home' ||
          this.colHeaders[i]==='Seal 1' ||
          this.colHeaders[i]==='Seal 2'
        ){
          headers.push(this.colHeaders[i]);
        }
          
      }

      return headers;
  }
  ngOnInit() {
    this.FetchLayouts();
    if (this._companyServ.getAvisWesbankId()) {
      this.avis_wesbank_id = this._companyServ.getAvisWesbankId()!.toString().replaceAll(' ','');
    }
  }
}
