<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="lft">
            <p>{{data.action == 'create'? 'Add Scorecard':'Manage Scorecard'}}</p>
            <p>Add description here</p>
        </div>
        <div class="rgt">
            <button mat-flat-button (click)="closeModal()">
                <i class="fa-solid fa-xmark"></i>
            </button>
        </div>
    </div>
    <div class="modal-contain">
        <form class="myform">
            <div class="row">
                <div class="col-12 col-md-7">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <!-- <mat-form-field >
                                <mat-label>Company Name</mat-label>
                                <input matInput placeholder="Ex. Company Name" [value]="data.form.CompanyName" (keyup)="onChangeValue('CompanyName', $event.target.value)">
                            </mat-form-field> -->

                            <mat-form-field>
                                <mat-label>Company Name</mat-label>
                                <input type="text"
                                       placeholder=""
                                       aria-label="Number"
                                       matInput
                                       [formControl]="myControl"
                                       [matAutocomplete]="auto">
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option *ngFor="let r of filteredOptions | async; track option" [value]="r.COMPANYNAME">{{r.COMPANYNAME}}</mat-option>
                                </mat-autocomplete>
                              </mat-form-field>

                        </div>
                        <div class="col-12 col-md-6">
                            <mat-form-field >
                                <mat-label>Division Name</mat-label>
                                <input matInput placeholder="Ex. Division Name" [value]="data.form.DivisionName" (keyup)="onChangeValue('DivisionName', $event.target.value)">
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-md-5">
                    <div class="row">

                        <div class="col-12 col-md-4">
                            <mat-form-field >
                                <mat-label>AS400 CustomerId</mat-label>
                                <input matInput placeholder="Ex. 1234" [value]="data.form.AS400CUSTOMERID">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field >
                                <mat-label>AS400 AccountId</mat-label>
                                <input matInput placeholder="Ex. 1234" [value]="data.form.AS400ACCOUNTID">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field >
                                <mat-label>AS400 CostCentreId</mat-label>
                                <input matInput placeholder="Ex. 1234" [value]="data.form.AS400COSTCENTREID">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-7">
                    <mat-form-field >
                        <mat-label>Losses %</mat-label>
                        <input matInput placeholder="0.000%" name="LossesPer" [value]="data.form.LossesPer" type="number" (keyup)="onChangeValue('LossesPer', $event.target.value)">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-5">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <mat-form-field >
                                <mat-label>IFM Id</mat-label>
                                <input matInput placeholder="Ex. 1234" name="IfmId" [value]="data.form.IfmId" (keyup)="onChangeValue('IfmId', $event.target.value)">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field >
                                <mat-label>AS400 MVA</mat-label>
                                <input matInput placeholder="Ex. 1234" name="AS400MVA" [value]="data.form.AS400MVA" (keyup)="onChangeValue('AS400MVA',$event.target.value)">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field >
                                <mat-label>Level 123456</mat-label>
                                <input matInput type="number" maxlength="1" placeholder="Ex. 1234" name="Level123456" [value]="data.form.Level123456" (keyup)="onChangeValue('Level123456', $event.target.value)">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="allocate-card">
                        <div class="block">
                            <p>Normal Weekend</p>
                            <div class="input-control">
                                <input type="number" maxlength="4" type="text" placeholder="0.000%" name="NormalWeekendPer" [disabled]="data.form.NormalWeekend == '1'? false : true" [value]="data.form.NormalWeekend == '1'?data.form.NormalWeekendPer:''" (keyup)="onChangeValue('NormalWeekendPer', $event.target.value)"/>
                                <mat-checkbox color="primary" (change)="onChangeCheckbox('NormalWeekend', $event.checked)" [checked]="data.form.NormalWeekend == '1'? true : false"></mat-checkbox>
                            </div>
                        </div>
                        <div class="block">
                            <p>Extended Weekend</p>
                            <div class="input-control">
                                <input type="number" maxlength="4" type="text" placeholder="0.000%" name="ExtendedWeekendPer" [disabled]="data.form.ExtendedWeekend == '1'? false : true" [value]="data.form.ExtendedWeekend == '1'?data.form.ExtendedWeekendPer:''" (keyup)="onChangeValue('ExtendedWeekendPer', $event.target.value)"/>
                                <mat-checkbox color="primary" (change)="onChangeCheckbox('ExtendedWeekend', $event.checked)" [checked]="data.form.ExtendedWeekend == '1'? true : false"></mat-checkbox>
                            </div>
                        </div>
                        <div class="block">
                            <p>Monthly Litres</p>
                            <div class="input-control">
                                <input type="number" maxlength="4" type="text" placeholder="0.000%" name="MonthlyLitresSetPer" [disabled]="data.form.MonthlyLitresSet == '1'? false : true" [value]="data.form.MonthlyLitresSet == '1'?data.form.MonthlyLitresSetPer:''" (keyup)="onChangeValue('MonthlyLitresSetPer', $event.target.value)"/>
                                <mat-checkbox color="primary" (change)="onChangeCheckbox('MonthlyLitresSet', $event.checked)" [checked]="data.form.MonthlyLitresSet == '1'? true : false"></mat-checkbox>
                            </div>
                            <div class="input-control">
                                <input type="number" maxlength="4" type="text" placeholder="Max" [value]="data.form.MaxLitres" (keyup)="onChangeValue('MaxLitres', $event.target.value)"/>
                                <span>L</span>
                            </div>
                        </div>
                        <div class="block">
                            <p>FS Out of Bounds</p>
                            <div class="input-control">
                                <input type="number" maxlength="4" type="text" placeholder="0.000%" name="FS_OutOfBoundsPer" [disabled]="data.form.FS_OutOfBounds == '1'? false : true" [value]="data.form.FS_OutOfBounds == '1'?data.form.FS_OutOfBoundsPer:''" (keyup)="onChangeValue('FS_OutOfBoundsPer', $event.target.value)"/>
                                <mat-checkbox color="primary" (change)="onChangeCheckbox('FS_OutOfBounds', $event.checked)" [checked]="data.form.FS_OutOfBounds == '1'? true : false"></mat-checkbox>
                            </div>
                        </div>
                        <div class="block">
                            <p>Same Day Refill</p>
                            <div class="input-control">
                                <input type="number" maxlength="4" type="text" placeholder="0.000%" name="SameDayRefillPer" [disabled]="data.form.SameDayRefill == '1'? false : true" [value]="data.form.SameDayRefill == '1'?data.form.SameDayRefillPer:''" (keyup)="onChangeValue('SameDayRefillPer', $event.target.value)"/>
                                <mat-checkbox color="primary" (change)="onChangeCheckbox('SameDayRefill', $event.checked)" [checked]="data.form.SameDayRefill == '1'? true : false"></mat-checkbox>
                            </div>
                        </div>
                        <div class="block">
                            <p>Tank Cap Exceed</p>
                            <div class="input-control">
                                <input type="number" maxlength="4" type="text" placeholder="0.000%" name="TankCapExceedPer" [disabled]="data.form.TankCapExceed == '1'? false : true" [value]="data.form.TankCapExceed == '1'?data.form.TankCapExceedPer:''" (keyup)="onChangeValue('TankCapExceedPer', $event.target.value)"/>
                                <mat-checkbox color="primary" (change)="onChangeCheckbox('TankCapExceed', $event.checked)" [checked]="data.form.TankCapExceed == '1'? true : false"></mat-checkbox>
                            </div>
                        </div>
                        <div class="block">
                            <p>Drivers Response</p>
                            <div class="input-control">
                                <input type="number" maxlength="4" type="text" placeholder="0.000%" name="DriverResponsePer" [disabled]="data.form.DriverResponse == '1'? false : true" [value]="data.form.DriverResponse == '1'?data.form.DriverResponsePer:''" (keyup)="onChangeValue('DriverResponsePer', $event.target.value)"/>
                                <mat-checkbox color="primary" (change)="onChangeCheckbox('DriverResponse', $event.checked)" [checked]="data.form.DriverResponse == '1'? true : false"></mat-checkbox>
                            </div>
                        </div>
                        <div class="block">
                            <p>Monthly KM</p>
                            <div class="input-control">
                                <input type="number" maxlength="4" type="text" placeholder="0.000%" name="MonthlyKmSetPer" [disabled]="data.form.MonthlyKmSet == '1'? false : true" [value]="data.form.MonthlyKmSet == '1'?data.form.MonthlyKmSetPer:''" (keyup)="onChangeValue('MonthlyKmSetPer', $event.target.value)"/>
                                <mat-checkbox color="primary" (change)="onChangeCheckbox('MonthlyKmSet', $event.checked)" [checked]="data.form.MonthlyKmSet == '1'? true : false"></mat-checkbox>
                            </div>
                            <div class="input-control">
                                <input type="text" placeholder="Max" [value]="data.form.MaxKM" (keyup)="onChangeValue('MaxKM', $event.target.value)"/>
                                <span>KM</span>
                            </div>
                        </div>
                        <div class="block">
                            <p>Cash Trans</p>
                            <div class="input-control">
                                <input type="number" maxlength="4" type="text" placeholder="0.000%" name="CashTransPer" [disabled]="data.form.CashTrans == '1'? false : true" [value]="data.form.CashTrans == '1'?data.form.CashTransPer:''" (keyup)="onChangeValue('CashTransPer', $event.target.value)"/>
                                <mat-checkbox color="primary" (change)="onChangeCheckbox('CashTrans', $event.checked)" [checked]="data.form.CashTrans == '1'? true : false"></mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <button class="mybtn" *ngIf="data.action == 'create'" (click)="addScorecardParameter()" type="button" mat-flat-button color="primary">Add Scorecard</button>
                    <button class="mybtn" *ngIf="data.action == 'update'" (click)="updateScorecardParameter()" type="button" mat-flat-button color="primary">Update Scorecard</button>
                </div>
            </div>
        </form>
    </div>
  </div>

