import {
  FuelStationEnquiry,
  MonthlyPermittedLimitExceeded,
  WeekendRefuel,
  IrregularFuelTransactions2,
  StaticOdoReading,
  FaultyOdoReading,
  DuplicateTransaction,
  ConsumptionNormVehicle,
  Comment,
  OdoMaintenance,
} from './../../../interfaces/emails';
import { Transaction } from './../../../models/transaction';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  ViewChild,
  inject,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  MatChipEditedEvent,
  MatChipInputEvent,
  MatChipsModule,
} from '@angular/material/chips';
import { NgFor } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MailsService } from 'src/app/services/notifications/mails.service';
import {
  IrregularFuelTransactions,
  PossibleCloneCard,
  SuspectHighOdo,
  SuspectLowConsumption,
  SuspectLowOdo,
  SuspectRegistrationNumber,
  TankCapacityExceeded,
} from 'src/app/interfaces/emails';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { VehicleService } from 'src/app/services/vehicles/vehicle.service';


export interface Email {
  address: string;
}
export class ConfirmDialogModel {
  constructor(public title: string, public message: string) { }

}
@Component({
  selector: 'app-email-buttons',
  templateUrl: './email-buttons.component.html',
  styleUrls: ['./email-buttons.component.css'],
})
export class EmailButtonsComponent {
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  emails: Email[] = [];

  selectedTransactions: any;

  announcer = inject(LiveAnnouncer);
  dialog: any;
  result: any;
  response: any;
  mail_type: any;
  template: any = '';

  constructor(
    public dialogRef: MatDialogRef<EmailButtonsComponent>,
    public confirmationDialog: MatDialog,
    private _vehicleServ: VehicleService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mail: MailsService,
    private _snackbar: SnackbarsService,
    private sanitizer: DomSanitizer
  ) {
    this.selectedTransactions = data;
  }
  getInnerHTMLValue() {
    return this.sanitizer.bypassSecurityTrustHtml(this.template);
  }
  htmlContent1 = '';
  htmlContent2 = '';

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: '350px',
    width: '100%',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
  };
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add  Email
    if (value) {
      this.emails.push({ address: value });
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(email: Email): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
      this.announcer.announce('Removed ${email}');
    }
  }
  edit(email: Email, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove email if it no longer has a address
    if (!value) {
      this.remove(email);
      return;
    }

    // Edit existing email
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails[index].address = value;
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  is_attachment = true;
  toggleAttachment(r: any) {
    this.is_attachment = r;
  }

  confirmDialog(): void {
    const message = `Are you sure you want to do this?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message,);

    let dialogRef = this.confirmationDialog.open(ConfirmationDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      this.result = dialogResult;
    });
  }

  public suspectRegistrationNumber() {
    let transaction = new SuspectRegistrationNumber(
      this.selectedTransactions[0].REGISTRATIONNO,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].FROMFUELSTATIONNAME,
      this.selectedTransactions[0].TRIPLITRES,
      this.selectedTransactions[0].amount,
      this.selectedTransactions[0].REGISTRATIONNO
    );
    this.emails.forEach((email: any) => {
      this.mail
        .suspectRegistrationNumber(
          transaction,
          'SUSPECTREGISTRATIONNUMBER',
          email.address
        )
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public suspectHighOdo() {
    const transaction = new SuspectHighOdo(
      this.selectedTransactions[0].REGISTRATIONNO,
      this.selectedTransactions[0].COMPANYNAME,
      this.selectedTransactions[0].COSTCENTRENAME,
      this.selectedTransactions[0].FLEETNO,
      this.selectedTransactions[0].DRIVERNAME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].VOUCHERNO,
      this.selectedTransactions[0].CURRENTODO.toString(),
      this.selectedTransactions[0].PREVIOUSODO.toString(),
      this.selectedTransactions[0].TOFUELSTATIONNAME,
      this.selectedTransactions[0].TRIPLITRES.toString(),
      this.selectedTransactions[0].AMOUNT.toString()
    );
    this.emails.forEach((email: any) => {
      this.response = 'loading';
      this.mail
        .suspectHighOdo(transaction, '', email.address)
        .subscribe((response: any) => {
          this.response = 'success';
          this.template = response.HTML;

          this.mail_type = 'Suspected High Odo Reading';
        });
    });
  }

  private getVehicleModel(registrationNo: any) {
    let result: any;
    this._vehicleServ.getVehicleModel(registrationNo).subscribe((response) => {
      result = response
    })

    alert(JSON.stringify(result));
    // return result;
  }

  public suspectLowConsumption() {
    this.getVehicleModel(this.selectedTransactions[0].REGISTRATIONNO);
    const transaction = new SuspectLowConsumption(
      this.selectedTransactions[0].REGISTRATIONNO,
      this.selectedTransactions[0].COMPANYNAME,
      this.selectedTransactions[0].COSTCENTRENAME,
      this.selectedTransactions[0].FLEETNO,
      this.selectedTransactions[0].DRIVERNAME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].VOUCHERNO,
      this.selectedTransactions[0].CURRENTODO
        ? this.selectedTransactions[0].CURRENTODO.toString()
        : 0,
      this.selectedTransactions[0].PREVIOUSODO
        ? this.selectedTransactions[0].PREVIOUSODO.toString()
        : 0,
      this.selectedTransactions[0].FROMFUELSTATIONNAME,
      this.selectedTransactions[0].TRIPLITRES
        ? this.selectedTransactions[0].TRIPLITRES.toString()
        : 0,
      this.selectedTransactions[0].AMOUNT
        ? this.selectedTransactions[0].AMOUNT.toString()
        : 0,
      this.selectedTransactions[0].CONSUMPTIONL100KM
        ? this.selectedTransactions[0].CONSUMPTIONL100KM.toString()
        : '0',
      'model here'
    );

    this.emails.forEach((email: any) => {
      this.mail
        .suspectLowConsumption(transaction, '', email.address)
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public irregularFuelTransactions() {
    const transaction = new IrregularFuelTransactions(
      this.selectedTransactions[0].REGISTRATIONNO,
      this.selectedTransactions[0].COMPANYNAME,
      this.selectedTransactions[0].COSTCENTRENAME,
      this.selectedTransactions[0].FLEETNO,
      this.selectedTransactions[0].DRIVERNAME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].VOUCHERNO,
      this.selectedTransactions[0].CURRENTODO,
      this.selectedTransactions[0].PREVIOUSODO,
      this.selectedTransactions[0].TRIPKM,
      this.selectedTransactions[0].FROMFUELSTATIONNAME,
      this.selectedTransactions[0].TRIPLITRES,
      this.selectedTransactions[0].AMOUNT,
      this.selectedTransactions[0].CONSUMPTIONL100,
      this.selectedTransactions[0].CONSUMPTIONKML,
      '0.00',
      '0.00',
      '0.00',
      '0.00',
      this.selectedTransactions[0].CARDNO
    );

    this.emails.forEach((email: any) => {
      this.mail
        .irregularFuelTransactions(
          transaction,
          'IRREGULARFUELTRANSACTIONS',
          email.address
        )
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public tankCapacityExceeded() {
    const transaction = new TankCapacityExceeded(
      this.selectedTransactions[0].REGISTRATIONNO,
      this.selectedTransactions[0].COMPANYNAME,
      this.selectedTransactions[0].COSTCENTRENAME,
      this.selectedTransactions[0].FLEETNO,
      this.selectedTransactions[0].DRIVERNAME,
      this.selectedTransactions[0].DATETINE,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].VOCHERNO,
      this.selectedTransactions[0].CURRENTODO,
      this.selectedTransactions[0].PREVIOUSODO,
      this.selectedTransactions[0].FROMFUELSTATIONNAME,
      this.selectedTransactions[0].TRIPLITRES,
      this.selectedTransactions[0].AMOUNT,
      this.selectedTransactions[0].TANKCAPACITY,
      '0.00'
    );

    this.emails.forEach((email: any) => {
      this.mail
        .tankCapacityExceeded(transaction, '', email.address)
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public suspectLowOdo() {
    const transaction = new SuspectLowOdo(
      this.selectedTransactions[0].REGISTRATIONNO,
      this.selectedTransactions[0].COMPANYNAME,
      this.selectedTransactions[0].COSTCENTRENAME,
      this.selectedTransactions[0].FLEETNO,
      this.selectedTransactions[0].DRIVERNAME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].VOUCHERNO,
      this.selectedTransactions[0].CURRENTODO,
      this.selectedTransactions[0].PREVIOUSODO,
      this.selectedTransactions[0].FROMFUELSTATIONNAME,
      this.selectedTransactions[0].TRIPLITRES,
      this.selectedTransactions[0].AMOUNT
    );

    this.emails.forEach((email: any) => {
      this.mail
        .suspectLowOdo(transaction, '', email.address)
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public weekendRefuel() {
    const transaction = new WeekendRefuel(
      this.selectedTransactions[0].REGISTRATIONNO,
      this.selectedTransactions[0].COMPANYNAME,
      this.selectedTransactions[0].COSTCENTRENAME,
      this.selectedTransactions[0].FLEETNO,
      this.selectedTransactions[0].DRIVERNAME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].VOUCHERNO,
      this.selectedTransactions[0].CURRENTODO,
      this.selectedTransactions[0].PREVIOUSODO,
      this.selectedTransactions[0].FROMFUELSTATIONNAME,
      this.selectedTransactions[0].TRIPLITRES,
      this.selectedTransactions[0].AMOUNT,
      this.selectedTransactions[0].DATETIME
    );

    this.emails.forEach((email: any) => {
      this.mail
        .weekendRefuel(transaction, 'SUSPECTREGISTRATIONNUMBER', email.address)
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public fuelStationEnquiry() {
    const transaction = new FuelStationEnquiry(
      this.selectedTransactions[0].REGISTRATIONNO,
      this.selectedTransactions[0].COMPANYNAME,
      this.selectedTransactions[0].COSTCENTRENAME,
      this.selectedTransactions[0].FLEETNO,
      this.selectedTransactions[0].DRIVERNAME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].VOUCHERNO,
      this.selectedTransactions[0].CURRENTODO,
      this.selectedTransactions[0].PREVIOUSODO,
      this.selectedTransactions[0].FROMFUELSTATIONNAME,
      this.selectedTransactions[0].TRIPLITRES,
      this.selectedTransactions[0].AMOUNT,
      this.selectedTransactions[0].CARDNO
    );

    this.emails.forEach((email: any) => {
      this.mail
        .fuelStationEnquiry(
          transaction,
          'SUSPECTREGISTRATIONNUMBER',
          email.address
        )
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public irregularFuelTransactions2() {
    const transaction = new IrregularFuelTransactions2(
      this.selectedTransactions[0].REGISTRATIONNO,
      this.selectedTransactions[0].COMPANYNAME,
      this.selectedTransactions[0].COSTCENTRENAME,
      this.selectedTransactions[0].FLEETNO,
      this.selectedTransactions[0].DRIVERNAME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].VOUCHERNO,
      this.selectedTransactions[0].PREVIOUSODO,
      this.selectedTransactions[0].CURRENTODO,
      this.selectedTransactions[0].TRIPKM,
      this.selectedTransactions[0].FROMFUELSTATIONNAME,
      this.selectedTransactions[0].TRIPLITRES,
      this.selectedTransactions[0].AMOUNT,
      this.selectedTransactions[0].CARDNO,
      this.selectedTransactions[0].CALCLITRES,
      this.selectedTransactions[0].FRIDGELITRES,
      this.selectedTransactions[0].FRIDGEHOURS,
      this.selectedTransactions[0].CONSUMPTIONL100KM,
      this.selectedTransactions[0].CONSUMPTIONKML,
      this.selectedTransactions[0].VEHICLENORML100KM,
      this.selectedTransactions[0].this.selectedTransactions[0].VEHICLENORMKML,
      '0,00',
      '0,00'
    );

    this.emails.forEach((email: any) => {
      this.mail
        .irregularFuelTransactions2(
          transaction,
          'SUSPECTREGISTRATIONNUMBER',
          email.address
        )
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public possibleCloneCard() {
    const transaction = new PossibleCloneCard(
      this.selectedTransactions[0].REGISTRATIONNO,
      this.selectedTransactions[0].DATETIME,
      'stop_val'
    );

    this.emails.forEach((email: any) => {
      this.mail
        .possibleCloneCard(transaction, 'POSSIBECLONECARD', email.address)
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public monthlyPermittedLimitExceeded() {
    const transaction = new MonthlyPermittedLimitExceeded(
      this.selectedTransactions[0].REGISTRATIONNO,
      this.selectedTransactions[0].DATETIME
    );

    this.emails.forEach((email: any) => {
      this.mail
        .monthlyPermittedLimitExceeded(
          transaction,
          'SUSPECTREGISTRATIONNUMBER',
          email.address
        )
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public staticOdoReading() {
    const transaction = new StaticOdoReading(
      this.selectedTransactions[0].REGISTRATIONNO
    );
    this.emails.forEach((email: any) => {
      this.mail
        .staticOdoReading(
          transaction,
          'SUSPECTREGISTRATIONNUMBER',
          email.address
        )
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public faultyOdoReading() {
    const transactions: FaultyOdoReading[] = [];
    this.selectedTransactions.forEach((record: any) => {
      const transaction = new FaultyOdoReading(record.REGISTRATIONNO);
      transactions.push(transaction);
    });
    alert(JSON.stringify(this.emails));
    this.emails.forEach((email: any) => {
      alert(JSON.stringify(email.address));
      this.mail
        .faultyOdoReading(
          transactions,
          'SUSPECTREGISTRATIONNUMBER',
          email.address
        )
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public duplicateTransaction() {
    const transaction = new DuplicateTransaction(
      this.selectedTransactions[0].REGISTRATIONNO,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].DATETIME,
      this.selectedTransactions[0].AMOUNT
    );

    this.emails.forEach((email: any) => {
      this.mail
        .duplicateTransaction(
          transaction,
          'SUSPECTREGISTRATIONNUMBER',
          email.address
        )
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public consumptionNormVehicle() {
    const transactions: ConsumptionNormVehicle[] = [];
    this.selectedTransactions.forEach((record: any) => {
      const transaction = new ConsumptionNormVehicle(
        this.selectedTransactions[0].REGISTRATIONNO
      );
      transactions.push(transaction);
    });
    this.emails.forEach((email: any) => {
      this.mail
        .consumptionNormVehicle(
          transactions,
          'SUSPECTREGISTRATIONNUMBER',
          email.address
        )
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public comment() {
    const transaction = new Comment(
      this.selectedTransactions[0].REGISTRATIONNO
    );

    this.emails.forEach((email: any) => {
      this.mail
        .comment(transaction, 'SUSPECTREGISTRATIONNUMBER', email.address)
        .subscribe((response: any) => {
          this.template = response.HTML;
        });
    });
  }
  public sendMail() {
    console.log(this.emails);
    this.emails.forEach((email: any) => {
      let data = {
        to: email.address,
        html: this.template,
      };
      this.mail.sendNotification(data).subscribe((next) => {
        console.log(next);
      });
      this._snackbar.success(
        'Notification sent to' + JSON.stringify(this.emails) + ' successfully'
      );
      this.closeModal();
    });
  }

  public odoMaintenance() {

    const transaction = new OdoMaintenance(
      this.selectedTransactions[0].REGISTRATIONNO,
    );

    this.emails.forEach((email: any) => {
      this.mail
        .odoMaintenance(
          transaction,
          'SUSPECTREGISTRATIONNUMBER',
          email.address
        )
        .subscribe((response: any) => {
          this._snackbar.success(response.StatusMessage);
        });
    });
  }
  // public confirmCredit() {
  //   const transactions: SuspectRegistrationNumber[] = [];
  //   this.selectedTransactions.forEach((record: any) => {
  //     const transaction = new SuspectRegistrationNumber(
  //       record.REGISTRATIONNO,
  //       record.DATETIME,
  //       record.FROMFUELSTATIONNAME,
  //       record.TRIPLITRES,
  //       record.amount,
  //       record.REGISTRATIONNO
  //     );
  //     transactions.push(transaction);
  //   });
  //   alert(JSON.stringify(this.emails));
  //   this.emails.forEach((email: any) => {
  //     alert(JSON.stringify(email.address));
  //     this.mail
  //       .suspectRegistrationNumber(
  //         transactions,
  //         'SUSPECTREGISTRATIONNUMBER',
  //         email.address
  //       )
  //       .subscribe((response: any) => {
  //         this._snackbar.success(response.StatusMessage);
  //       });
  //   });
  // }
  // public suspectOdoReadings() {
  //   const transactions: SuspectRegistrationNumber[] = [];
  //   this.selectedTransactions.forEach((record: any) => {
  //     const transaction = new SuspectRegistrationNumber(
  //       record.REGISTRATIONNO,
  //       record.DATETIME,
  //       record.FROMFUELSTATIONNAME,
  //       record.TRIPLITRES,
  //       record.amount,
  //       record.REGISTRATIONNO
  //     );
  //     transactions.push(transaction);
  //   });
  //   alert(JSON.stringify(this.emails));
  //   this.emails.forEach((email: any) => {
  //     alert(JSON.stringify(email.address));
  //     this.mail
  //       .suspectRegistrationNumber(
  //         transactions,
  //         'SUSPECTREGISTRATIONNUMBER',
  //         email.address
  //       )
  //       .subscribe((response: any) => {
  //         this._snackbar.success(response.StatusMessage);
  //       });
  //   });
  // }
  // public possibleDuplicateCard() {
  //   const transactions: SuspectRegistrationNumber[] = [];
  //   this.selectedTransactions.forEach((record: any) => {
  //     const transaction = new SuspectRegistrationNumber(
  //       record.REGISTRATIONNO,
  //       record.DATETIME,
  //       record.FROMFUELSTATIONNAME,
  //       record.TRIPLITRES,
  //       record.amount,
  //       record.REGISTRATIONNO
  //     );
  //     transactions.push(transaction);
  //   });
  //   alert(JSON.stringify(this.emails));
  //   this.emails.forEach((email: any) => {
  //     alert(JSON.stringify(email.address));
  //     this.mail
  //       .suspectRegistrationNumber(
  //         transactions,
  //         'SUSPECTREGISTRATIONNUMBER',
  //         email.address
  //       )
  //       .subscribe((response: any) => {
  //         this._snackbar.success(response.StatusMessage);
  //       });
  //   });
  // }
  // public possibleSplit() {
  //   const transactions: SuspectRegistrationNumber[] = [];
  //   this.selectedTransactions.forEach((record: any) => {
  //     const transaction = new SuspectRegistrationNumber(
  //       record.REGISTRATIONNO,
  //       record.DATETIME,
  //       record.FROMFUELSTATIONNAME,
  //       record.TRIPLITRES,
  //       record.amount,
  //       record.REGISTRATIONNO
  //     );
  //     transactions.push(transaction);
  //   });
  //   alert(JSON.stringify(this.emails));
  //   this.emails.forEach((email: any) => {
  //     alert(JSON.stringify(email.address));
  //     this.mail
  //       .suspectRegistrationNumber(
  //         transactions,
  //         'SUSPECTREGISTRATIONNUMBER',
  //         email.address
  //       )
  //       .subscribe((response: any) => {
  //         this._snackbar.success(response.StatusMessage);
  //       });
  //   });
  // }
  // public tankNotFull() {
  //   const transactions: SuspectRegistrationNumber[] = [];
  //   this.selectedTransactions.forEach((record: any) => {
  //     const transaction = new SuspectRegistrationNumber(
  //       record.REGISTRATIONNO,
  //       record.DATETIME,
  //       record.FROMFUELSTATIONNAME,
  //       record.TRIPLITRES,
  //       record.amount,
  //       record.REGISTRATIONNO
  //     );
  //     transactions.push(transaction);
  //   });
  //   alert(JSON.stringify(this.emails));
  //   this.emails.forEach((email: any) => {
  //     alert(JSON.stringify(email.address));
  //     this.mail
  //       .suspectRegistrationNumber(
  //         transactions,
  //         'SUSPECTREGISTRATIONNUMBER',
  //         email.address
  //       )
  //       .subscribe((response: any) => {
  //         this._snackbar.success(response.StatusMessage);
  //       });
  //   });
  // }
  // public possibleCalculatedFills() {
  //   const transactions: SuspectRegistrationNumber[] = [];
  //   this.selectedTransactions.forEach((record: any) => {
  //     const transaction = new SuspectRegistrationNumber(
  //       record.REGISTRATIONNO,
  //       record.DATETIME,
  //       record.FROMFUELSTATIONNAME,
  //       record.TRIPLITRES,
  //       record.amount,
  //       record.REGISTRATIONNO
  //     );
  //     transactions.push(transaction);
  //   });
  //   alert(JSON.stringify(this.emails));
  //   this.emails.forEach((email: any) => {
  //     alert(JSON.stringify(email.address));
  //     this.mail
  //       .suspectRegistrationNumber(
  //         transactions,
  //         'SUSPECTREGISTRATIONNUMBER',
  //         email.address
  //       )
  //       .subscribe((response: any) => {
  //         this._snackbar.success(response.StatusMessage);
  //       });
  //   });
  // }
  // public bereauServiceWeeklyReport() {
  //   const transactions: SuspectRegistrationNumber[] = [];
  //   this.selectedTransactions.forEach((record: any) => {
  //     const transaction = new SuspectRegistrationNumber(
  //       record.REGISTRATIONNO,
  //       record.DATETIME,
  //       record.FROMFUELSTATIONNAME,
  //       record.TRIPLITRES,
  //       record.amount,
  //       record.REGISTRATIONNO
  //     );
  //     transactions.push(transaction);
  //   });
  //   alert(JSON.stringify(this.emails));
  //   this.emails.forEach((email: any) => {
  //     alert(JSON.stringify(email.address));
  //     this.mail
  //       .suspectRegistrationNumber(
  //         transactions,
  //         'SUSPECTREGISTRATIONNUMBER',
  //         email.address
  //       )
  //       .subscribe((response: any) => {
  //         this._snackbar.success(response.StatusMessage);
  //       });
  //   });
  // }
  // public reTankCapacityExceeded() {
  //   const transactions: SuspectRegistrationNumber[] = [];
  //   this.selectedTransactions.forEach((record: any) => {
  //     const transaction = new SuspectRegistrationNumber(
  //       record.REGISTRATIONNO,
  //       record.DATETIME,
  //       record.FROMFUELSTATIONNAME,
  //       record.TRIPLITRES,
  //       record.amount,
  //       record.REGISTRATIONNO
  //     );
  //     transactions.push(transaction);
  //   });
  //   alert(JSON.stringify(this.emails));
  //   this.emails.forEach((email: any) => {
  //     alert(JSON.stringify(email.address));
  //     this.mail
  //       .suspectRegistrationNumber(
  //         transactions,
  //         'SUSPECTREGISTRATIONNUMBER',
  //         email.address
  //       )
  //       .subscribe((response: any) => {
  //         this._snackbar.success(response.StatusMessage);
  //       });
  //   });
  // }
  // public reTankCapacity() {
  //   const transactions: SuspectRegistrationNumber[] = [];
  //   this.selectedTransactions.forEach((record: any) => {
  //     const transaction = new SuspectRegistrationNumber(
  //       record.REGISTRATIONNO,
  //       record.DATETIME,
  //       record.FROMFUELSTATIONNAME,
  //       record.TRIPLITRES,
  //       record.amount,
  //       record.REGISTRATIONNO
  //     );
  //     transactions.push(transaction);
  //   });
  //   alert(JSON.stringify(this.emails));
  //   this.emails.forEach((email: any) => {
  //     alert(JSON.stringify(email.address));
  //     this.mail
  //       .suspectRegistrationNumber(
  //         transactions,
  //         'SUSPECTREGISTRATIONNUMBER',
  //         email.address
  //       )
  //       .subscribe((response: any) => {
  //         this._snackbar.success(response.StatusMessage);
  //       });
  //   });
  // }
  // public staticOdoReadingForVehicle() {
  //   const transactions: SuspectRegistrationNumber[] = [];
  //   this.selectedTransactions.forEach((record: any) => {
  //     const transaction = new SuspectRegistrationNumber(
  //       record.REGISTRATIONNO,
  //       record.DATETIME,
  //       record.FROMFUELSTATIONNAME,
  //       record.TRIPLITRES,
  //       record.amount,
  //       record.REGISTRATIONNO
  //     );
  //     transactions.push(transaction);
  //   });
  //   alert(JSON.stringify(this.emails));
  //   this.emails.forEach((email: any) => {
  //     alert(JSON.stringify(email.address));
  //     this.mail
  //       .suspectRegistrationNumber(
  //         transactions,
  //         'SUSPECTREGISTRATIONNUMBER',
  //         email.address
  //       )
  //       .subscribe((response: any) => {
  //         this._snackbar.success(response.StatusMessage);
  //       });
  //   });
  // }
  // public possibleCloneCardForVehicle() {
  //   const transactions: SuspectRegistrationNumber[] = [];
  //   this.selectedTransactions.forEach((record: any) => {
  //     const transaction = new SuspectRegistrationNumber(
  //       record.REGISTRATIONNO,
  //       record.DATETIME,
  //       record.FROMFUELSTATIONNAME,
  //       record.TRIPLITRES,
  //       record.amount,
  //       record.REGISTRATIONNO
  //     );
  //     transactions.push(transaction);
  //   });
  //   alert(JSON.stringify(this.emails));
  //   this.emails.forEach((email: any) => {
  //     alert(JSON.stringify(email.address));
  //     this.mail
  //       .suspectRegistrationNumber(
  //         transactions,
  //         'SUSPECTREGISTRATIONNUMBER',
  //         email.address
  //       )
  //       .subscribe((response: any) => {
  //         this._snackbar.success(response.StatusMessage);
  //       });
  //   });
  // }
  // public reDuplicateTransactionForVehicle() {
  //   const transactions: SuspectRegistrationNumber[] = [];
  //   this.selectedTransactions.forEach((record: any) => {
  //     const transaction = new SuspectRegistrationNumber(
  //       record.REGISTRATIONNO,
  //       record.DATETIME,
  //       record.FROMFUELSTATIONNAME,
  //       record.TRIPLITRES,
  //       record.amount,
  //       record.REGISTRATIONNO
  //     );
  //     transactions.push(transaction);
  //   });
  //   alert(JSON.stringify(this.emails));
  //   this.emails.forEach((email: any) => {
  //     alert(JSON.stringify(email.address));
  //     this.mail
  //       .suspectRegistrationNumber(
  //         transactions,
  //         'SUSPECTREGISTRATIONNUMBER',
  //         email.address
  //       )
  //       .subscribe((response: any) => {
  //         this._snackbar.success(response.StatusMessage);
  //       });
  //   });
  // }
  // public unknownRegistration() {
  //   const transactions: SuspectRegistrationNumber[] = [];
  //   this.selectedTransactions.forEach((record: any) => {
  //     const transaction = new SuspectRegistrationNumber(
  //       record.REGISTRATIONNO,
  //       record.DATETIME,
  //       record.FROMFUELSTATIONNAME,
  //       record.TRIPLITRES,
  //       record.amount,
  //       record.REGISTRATIONNO
  //     );
  //     transactions.push(transaction);
  //   });
  //   alert(JSON.stringify(this.emails));
  //   this.emails.forEach((email: any) => {
  //     alert(JSON.stringify(email.address));
  //     this.mail
  //       .suspectRegistrationNumber(
  //         transactions,
  //         'SUSPECTREGISTRATIONNUMBER',
  //         email.address
  //       )
  //       .subscribe((response: any) => {
  //         this._snackbar.success(response.StatusMessage);
  //       });
  //   });
  // }
}
