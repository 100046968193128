<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="lft">
        <p>{{ !data ? 'Add ' : 'Update ' }} Fuel Station</p>
        <p>........................................</p>
      </div>
      <div class="rgt">
        <button mat-flat-button (click)="closeModal('close')">
        <i class="fa-solid fa-xmark"></i>
      </button>
      </div>
    </div>

    <div class="modal-contain">

        <form class="myform" [formGroup]="fuelStationForm" (ngSubmit)="onSubmit()">
            <!-- <div class="htitle">
                <p class="FxLine">
                    Vehicle Information
                </p>
            </div> -->
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Fuel Station Master ID</mat-label>
                <input matInput placeholder="Ex. 1234" formControlName="fuel_station_master_id">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Registered Name</mat-label>
                <input matInput placeholder="Ex. Registered Name" formControlName="registered_name">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Trading Name</mat-label>
                <input matInput placeholder="Ex. Trading Name" formControlName="trading_name">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Town</mat-label>
                <input matInput placeholder="Ex. Town Name" formControlName="town">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Merchant Number</mat-label>
                <input matInput placeholder="Ex. 1234" formControlName="merchant_number">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Email Address</mat-label>
                <input matInput placeholder="Ex. emailaddress@gmail.com" formControlName="email_address">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Province</mat-label>
                <input matInput placeholder="Ex. Province Name" formControlName="province">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Zone</mat-label>
                <input matInput placeholder="Ex. Zone Name" formControlName="zone">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Telephone</mat-label>
                <input matInput type="number" placeholder="Ex. 1234" formControlName="telephone">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Cellphone</mat-label>
                <input matInput type="number" placeholder="Ex. 08123456789" formControlName="cellphone">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Fax</mat-label>
                <input matInput placeholder="Ex. 0123456789" formControlName="fax">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Magisterial District</mat-label>
                <input matInput placeholder="Ex. Magisterial District" formControlName="magisterial_district">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Contact Person</mat-label>
                <input matInput placeholder="Ex. John Doe" formControlName="contact_person">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Longitude</mat-label>
                <input matInput placeholder="Ex. 123.456" formControlName="longitude">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Latitude</mat-label>
                <input matInput placeholder="Ex. 45.6789" formControlName="latitude">
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-3">
              <mat-form-field>
                <mat-label>Fuel Type</mat-label>
                <mat-select
                  formControlName="fuel_types"
                  multiple
                >
                  <mat-option *ngFor="let fuelType of fuelTypes" [value]="fuelType.uuid">
                    {{ fuelType.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xs-12">
              <button class="mybtn" mat-flat-button color="primary" type="submit">{{ !data ? 'Add ' : 'Update ' }} Fuel Station</button>
            </div>
          </div>
        </form>
    </div>
  </div>
