<div class="myModal capture-modal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p>Capture Fuel  Transaction</p>
      <p>Capturing Transaction For {{ companyName }}</p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>

  <div class="modal-contain">
    <form
      (ngSubmit)="SubmitTranscation()"
      class="myform"
      *ngIf="showing == 'transcations_list'"
    >
      <div class="myheader">
        <div class="htitle">
          <p class="FxLine">You are capturing transcations</p>
        </div>
        <button (click)="AddMore()" mat-flat-button>
          Add more <i class="fa-solid fa-plus"></i>
        </button>
      </div>

      <mat-accordion>
        <mat-expansion-panel *ngFor="let r of TranscationList; let i = index">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p>
                <b>{{ i + 1 }}. Registration No:</b> {{ r.RegistrationNo }}
              </p>
            </mat-panel-title>
            <mat-panel-description>
              <p>Make Range: {{ r.makeRange }}</p>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="moreinfo">
            <div class="row">
              <div class="col-12 col-md-4">
                <p><b>Registration No:</b> {{ r.RegistrationNo }}</p>
                <p><b>Make:</b> {{ r.makeRange }}</p>
                <p><b>Driver Name:</b> {{ r.driverName }}</p>
                <p><b>OdoReading:</b> {{ r.OdoReading }}</p>
              </div>
              <div class="col-12 col-md-4">
                <p><b>Fuel Station:</b> {{ r.FuelStation }}</p>
                <p><b>Town:</b> {{ r.town }}</p>
                <p><b>Oil Company</b> {{ r.oilCompany }}</p>
                <p><b>Litres:</b> {{ r.Litres }}</p>
              </div>
              <div class="col-12 col-md-4">
                <p><b>Price Per Litre</b> {{ r.litrePrice }}</p>
                <p><b>Amount:</b> {{ r.Amount }}</p>
                <p><b>Receipt Number:</b> {{ r.ReceiptNo }}</p>
                <p><b>Receipt Date:</b> {{ r.ReceiptDate }}</p>
              </div>
            </div>
            <div class="actionbtns">
              <button
                mat-flat-button
                color="accent"
                (click)="EditTranscation(i)"
              >
                <i class="fa-solid fa-pen"></i>
              </button>
              <button
                mat-flat-button
                color="primary"
                (click)="RemoveTranscation(i)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="errorMsg" *ngIf="response == 'network_err'">
        <i class="fa-solid fa-circle-exclamation"></i>
        <p>Ops, Check your internet connectivity</p>
      </div>

      <div class="loading-progress-1" *ngIf="response == 'loading'">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <p>Please wait while we are processing your transcations</p>
      </div>
      <button
        class="mybtn"
        mat-flat-button
        color="primary"
        [disabled]="response == 'loading' ? true : false"
        type="submit"
      >
        Save and submit
      </button>
    </form>

    <form
      *ngIf="
        showing == 'form' ||
        showing == 'editing_transcation' ||
        showing == 'adding_more'
      "
      class="myform"
      #Form="ngForm"
      (ngSubmit)="AddTranscation()"
    >
      <!-- <p>
                {{FormModel | json}}
                    <hr/>

            </p> -->

      <div *ngIf="showing == 'form'" class="htitle">
        <p class="FxLine">You are capturing transcations</p>
      </div>

      <div class="myheader" *ngIf="showing == 'editing_transcation'">
        <div class="htitle">
          <p class="FxLine">You are editing transcation</p>
        </div>
        <button (click)="ViewTranscationList()" mat-flat-button>
          <i class="fa-solid fa-list-ul"></i>Captured list ({{
            TranscationList.length
          }})
        </button>
      </div>
      <div class="myheader" *ngIf="showing == 'adding_more'">
        <div class="htitle">
          <p class="FxLine">You are adding more transcation</p>
        </div>
        <button (click)="ViewTranscationList()" mat-flat-button>
          <i class="fa-solid fa-list-ul"></i>Captured list ({{
            TranscationList.length
          }})
        </button>
      </div>

      <div class="row">
        <!-- <div class="col-xs-12 col-md-4">
                    <mat-form-field >
                        <mat-label>Make Range</mat-label>
                        <input matInput (keyup)="validateMakeRange($event.target)" value="" placeholder="Ex. VW Polo TSI" #makeRange = "ngModel" [(ngModel)]="FormModel.makeRange" name="makeRange">
                    </mat-form-field>
                </div> -->
        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>Vehicle Registration</mat-label>
            <mat-select
              (selectionChange)="validateRegistrationNo($event)"
              #RegistrationNo="ngModel"
              [(ngModel)]="FormModel.RegistrationNo"
              name="CaptureRegistrationNo"
              id="RegistrationNo"
            >
              <mat-option value="" selected>Select Registration</mat-option>
              <mat-option
                value=""
                *ngIf="fetch_registration_response === 'loading'"
                >Loading</mat-option
              >
              <mat-option
                value=""
                *ngIf="fetch_registration_response === 'no_results'"
                >No results</mat-option
              >
              <mat-option
                value=""
                *ngIf="fetch_registration_response === 'network_err'"
                >Failed to fetch</mat-option
              >
              <ng-container *ngIf="fetch_registration_response === 'success'">
                <mat-option
                  *ngFor="let r of fetch_registration_data"
                  [value]="r.REGISTRATIONNO"
                  >{{ r.REGISTRATIONNO }}</mat-option
                >
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>Driver Name</mat-label>
            <input
              matInput
              (keyup)="validateDriverName($event.target)"
              value=""
              placeholder="Ex. John Smith"
              #driverName="ngModel"
              [(ngModel)]="FormModel.driverName"
              name="CaptureDriverName"
            />
          </mat-form-field>
        </div>

        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>Receipt Number</mat-label>
            <input
              matInput
              (keyup)="validateReceiptNumber($event.target)"
              value=""
              placeholder="Ex. ABC1234"
              #ReceiptNo="ngModel"
              [(ngModel)]="FormModel.ReceiptNo"
              name="CaptureReceiptNo"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-4">
          <!-- <mat-form-field >
                        <mat-label>Fuel Station</mat-label>
                        <input matInput (keyup)="validateFuelStation($event.target)" value="" placeholder="Ex. Station Name" #FuelStation = "ngModel" [(ngModel)]="FormModel.FuelStation" name="FuelStation">
                    </mat-form-field> -->
          <mat-form-field appearance="fill">
            <mat-label>Fuel Station</mat-label>
            <mat-select
              (selectionChange)="selectFuelStation($event)"
              #FuelStation="ngModel"
              [(ngModel)]="FormModel.FuelStation"
              name="FuelStation"
              id="CaptureSelectFuelStation"
            >
              <mat-option value="" selected>Select Fuel Station</mat-option>
              <mat-option
                value=""
                *ngIf="fetch_fuel_stations_response === 'loading'"
                >Loading</mat-option
              >
              <mat-option
                value=""
                *ngIf="fetch_fuel_stations_response === 'no_results'"
                >No results</mat-option
              >
              <mat-option
                value=""
                *ngIf="fetch_fuel_stations_response === 'network_err'"
                >Failed to fetch</mat-option
              >
              <ng-container *ngIf="fetch_fuel_stations_response === 'success'">
                <mat-option
                  *ngFor="let r of fetch_fuel_stations_data"
                  [value]="r.FUELSTATIONNAME"
                  >{{ r.FUELSTATIONNAME }}</mat-option
                >
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-4">
          <!-- <mat-form-field >
                    <mat-label>Town</mat-label>
                    <input matInput (keyup)="validateTown($event.target)" value="" placeholder="Ex. Kempton Park" #town = "ngModel" [(ngModel)]="FormModel.town" name="town">
                </mat-form-field> -->

          <mat-form-field appearance="fill">
            <mat-label>Town</mat-label>
            <mat-select
              (selectionChange)="selectTown($event)"
              [(ngModel)]="FormModel.town"
              #town="ngModel"
              [(ngModel)]="FormModel.town"
              name="town"
              id="CaptureTown"
            >
              <mat-option value="" selected>Select Town</mat-option>
              <mat-option value="" *ngIf="fetch_towns_response === 'loading'"
                >Loading</mat-option
              >
              <mat-option value="" *ngIf="fetch_towns_response === 'no_results'"
                >No results</mat-option
              >
              <mat-option
                value=""
                *ngIf="fetch_towns_response === 'network_err'"
                >Failed to fetch</mat-option
              >
              <ng-container *ngIf="fetch_towns_response === 'success'">
                <mat-option
                  *ngFor="let r of fetch_towns_data"
                  [value]="r.TOWNNAME"
                  >{{ r.TOWNNAME }}</mat-option
                >
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>Oil Company</mat-label>
            <input
              matInput
              (keyup)="validateOilCompany($event.target)"
              value=""
              placeholder="Ex. Company Name"
              #oilCompany="ngModel"
              [(ngModel)]="FormModel.oilCompany"
              name="CaptureOilCompany"
            />
          </mat-form-field>

          <!-- <mat-form-field appearance="fill">
                        <mat-label>Oil Company</mat-label>
                        <mat-select (selectionChange)="selectOilCompany($event)" #oilCompany = "ngModel" [(ngModel)]="FormModel.oilCompany" name="oilCompany" id="select_oil_company">
                        </mat-select>
                    </mat-form-field> -->
        </div>

        <div class="col-xs-12 col-md-2">
          <mat-form-field>
            <mat-label>Litres</mat-label>
            <input
              matInput
              type="number"
              (keyup)="validateLitres($event.target)"
              value=""
              placeholder="Ex. 0.00"
              #Litres="ngModel"
              [(ngModel)]="FormModel.Litres"
              name="CaptureLitres"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-2">
          <mat-form-field>
            <mat-label>Price Per Litre</mat-label>
            <input
              matInput
              type="number"
              (keyup)="validatePricePerLitre($event.target)"
              value=""
              placeholder="Ex. 0.00"
              #litrePrice="ngModel"
              [(ngModel)]="FormModel.litrePrice"
              name="CapturePricePerLitre"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-2">
          <mat-form-field>
            <mat-label>Amount</mat-label>
            <input
              matInput
              type="number"
              [readonly]="true"
              (keyup)="validateAmount($event.target)"
              placeholder="Ex. R0.00"
              #Amount="ngModel"
              [(ngModel)]="FormModel.Amount"
              name="CaptureAmount"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-2">
          <mat-form-field>
            <mat-label>Odo Reading</mat-label>
            <input
              matInput
              type="number"
              (keyup)="validateOdometer($event.target)"
              value=""
              placeholder="Ex. 0.000 (KM)"
              #OdoReading="ngModel"
              [(ngModel)]="FormModel.OdoReading"
              name="CaptureOdoReading"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-2">
          <mat-form-field class="withprefix">
            <mat-label>Time</mat-label>
            <input
              matInput
              type="time"
              (keyup)="validateTime($event.target)"
              matInput
              min="09:00"
              max="23:00"
              value=""
              placeholder="Ex. 12:00"
              #Time="ngModel"
              [(ngModel)]="FormModel.time"
              name="CaptureTime"
            />
            <span *ngIf="TimeFormat === 'AM'" matTextPrefix>AM&nbsp;</span>
            <span *ngIf="TimeFormat === 'PM'" matTextPrefix>PM&nbsp;</span>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-2">
          <mat-form-field appearance="fill">
            <mat-label>Receipt Date</mat-label>

            <mat-form-field>
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Ex. 2020/02/20">
                <input matEndDate placeholder="Ex. 2020/02/20">
              </mat-date-range-input>
              <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle
              matIconSuffix
              [for]="StartDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #StartDatePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div
          class="col-xs-12"
          *ngIf="showing == 'form' || showing == 'adding_more'"
        >
          <button class="mybtn" mat-flat-button color="primary" type="submit">
            Add Transaction
          </button>
        </div>
        <div class="col-xs-12" *ngIf="showing == 'editing_transcation'">
          <button class="mybtn" mat-flat-button color="primary" type="submit">
            Save Changes
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<app-confirmation-dialog
  #confirmationModal
  (newConfirmationEvent)="getConfirmationValue($event)"
  [modalStyle]="modalStyle"
  [modalTitle]="modalTitle"
  [modalBody]="modalBody"
  [modalButtonColor]="modalButtonColor"
>
</app-confirmation-dialog>
