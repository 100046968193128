import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CacheService } from '../utilities/cache/cache.service';
import {AuthService} from "../auth/auth.service";
import {CompanyService} from "../companies/company.service";
@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(
    private http: HttpClient,
    private _stateServ: CacheService,
    private _authService: AuthService,
    private _companyService: CompanyService
  ) { }

  public service_base = this._authService.service_base;

  private headers = new HttpHeaders({
    'x-Gateway-APIKey': 'c2c22314-ca55-4b42-b472-494a6c180706',
    'Access-Control-Allow-Origin': '*',
  });

  addDevice(vehicleDetails: any) {
    const url = this.service_base + 'device/store';
    const result = this.http.post<any>(url, vehicleDetails, {
      headers: this.headers,
    });
    return result;
  }

  updateDevice(vehicleDetails: any) {
    const url = this.service_base + 'device/update';
    const result = this.http.post<any>(url, vehicleDetails, {
      headers: this.headers,
    });
    return result;
  }

  getDevicesByCompanyUuid() {
    const url = `device/get-devices-by-company-uuid/${this._companyService.getCompanyId()}`;
    const result = this.http.get<any>(this.service_base + url, {
      headers: this.headers,
    });
    return result;
  }
}
