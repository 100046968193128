
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-mails-configurations',
  templateUrl: './mails-configurations.component.html',
  styleUrls: ['./mails-configurations.component.css']
})
export class MailsConfigurationsComponent {
  constructor(
    public dialogRef: MatDialogRef<MailsConfigurationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    dataSource_Cost_Center = [
        {
            "CUSTOMER_ID": 48513,
            "ACCOUNT_NAME": 'AKACIA MEDICAL',
            "COST_CENTRE_NAME": 'PENTAX',
        },
        {
            "CUSTOMER_ID": 3232,
            "ACCOUNT_NAME": 'AKACIA SALES',
            "COST_CENTRE_NAME": 'THEBE SALES',
        },
        {
            "CUSTOMER_ID": 1234,
            "ACCOUNT_NAME": 'AKACIA MEDICAL',
            "COST_CENTRE_NAME": 'JHB',
        },
        {
          "CUSTOMER_ID": 48513,
          "ACCOUNT_NAME": 'AKACIA MEDICAL',
          "COST_CENTRE_NAME": 'PENTAX',
        },
        {
            "CUSTOMER_ID": 3232,
            "ACCOUNT_NAME": 'AKACIA SALES',
            "COST_CENTRE_NAME": 'THEBE SALES',
        },
        {
            "CUSTOMER_ID": 1234,
            "ACCOUNT_NAME": 'AKACIA MEDICAL',
            "COST_CENTRE_NAME": 'JHB',
        },
        {
          "CUSTOMER_ID": 48513,
            "ACCOUNT_NAME": 'AKACIA MEDICAL',
            "COST_CENTRE_NAME": 'PENTAX',
        },
        {
            "CUSTOMER_ID": 3232,
            "ACCOUNT_NAME": 'AKACIA SALES',
            "COST_CENTRE_NAME": 'THEBE SALES',
        },
        {
            "CUSTOMER_ID": 1234,
            "ACCOUNT_NAME": 'AKACIA MEDICAL',
            "COST_CENTRE_NAME": 'JHB',
        },
        {
          "CUSTOMER_ID": 48513,
          "ACCOUNT_NAME": 'AKACIA MEDICAL',
          "COST_CENTRE_NAME": 'PENTAX',
        },
        {
          "CUSTOMER_ID": 3232,
          "ACCOUNT_NAME": 'AKACIA SALES',
          "COST_CENTRE_NAME": 'THEBE SALES',
        },
        {
          "CUSTOMER_ID": 1234,
          "ACCOUNT_NAME": 'AKACIA MEDICAL',
          "COST_CENTRE_NAME": 'JHB',
        },
    ]
    displayedColumns_Cost_Center: string[] = [
      'CUSTOMER_ID',
      'ACCOUNT_NAME',
      'COST_CENTRE_NAME',
    ];





    dataSource_Email_Addresses = [
      {
          "EMAIL_ADDRESS": "name_surname@zeda.co.za",
          "EMAIL_TYPE_DESCRIPTION": 'High Suspected Odo',
      },
      {
          "EMAIL_ADDRESS": "name_surname@zeda.co.za",
          "EMAIL_TYPE_DESCRIPTION": 'High Suspected Odo',
      }
    ]

    displayedColumns_Email_Addresses: string[] = [
      'EMAIL_ADDRESS',
      'EMAIL_TYPE_DESCRIPTION',
    ];


    closeModal() {
      this.dialogRef.close();
    }
}
