<div class="myModal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p class="heading">
        <i class="fa fa-calculator"></i> Recalculate Transaction
      </p>
      <p>{{ data[0].REGISTRATIONNO }}</p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>

  <div class="modal-contain">
    <!-- {{data[0] | json}} -->
    <form
      class="myform"
      [formGroup]="updateTransactionForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="row" *ngIf="calc_status == 'complete'">
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Registration</mat-label>
            <input
              matInput
              value="{{ data[0].REGISTRATIONNO }}"
              formControlName="RegistrationNo"
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>

        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Litres</mat-label>
            <input
              formControlName="Litres"
              matInput
              value="{{ data[0].Litres }}"
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <!--
              <div class="col-xs-12 col-md-3">
                <mat-slide-toggle color="primary" class="myswitch"
                  ><p>Litre Per Hour</p></mat-slide-toggle
                >
              </div> -->

        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Litre Price</mat-label>
            <input
              matInput
              value="{{ data[0].litrePrice }}"
              formControlName="litrePrice"
              placeholder="Ex. 0.00"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Total Amount</mat-label>
            <input
              matInput
              value="{{ data[0].Amount }}"
              [readonly]="true"
              formControlName="Amount"
              placeholder="Ex. 0.00"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Odo Reading</mat-label>
            <input
              matInput
              value="{{ data[0].OdoRedaingID }}"
              formControlName="OdoReading"
              placeholder="Ex. 123,456.00"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12">
          <button
            class="mybtn"
            mat-flat-button
            color="primary"
            type="submit"
            (click)="onSubmit()"
          >
            Save
          </button>
        </div>
      </div>
      <div class="row myrecalculations" *ngIf="calc_status == 'loading'">
        <div class="contain">
          <div class="mychip" *ngFor="let r of data" [matTooltip]="r">
            <p>Reg: {{ r.REGISTRATIONNO }}</p>
          </div>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          <p class="message">Please wait while recalculating transaction(s)</p>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-xs-12">
            <button class="mybtn" (click)="closeModal()" type="button" mat-flat-button color="primary">Recalculate</button>
        </div>
    </div> -->
    </form>
  </div>
</div>
