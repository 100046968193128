<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="lft">
        <p>{{data.VEHICLEMAKE}}</p>
        <p>{{data.VEHICLERANGE}}</p>
        </div>
        <div class="rgt">
        <button mat-flat-button (click)="closeModal()" cdkFocusInitial >
            <i class="fa-solid fa-xmark"></i>
        </button>
        </div>
    </div>

    <div class="modal-contain">
        <!-- <p>
            {{data | json}}
        </p> -->
        <form class="myform">
            <div class="row">
                  <div class="col-xs-12 col-md-3">
                      <mat-form-field >
                          <mat-label>Vehicle Id</mat-label>
                          <input matInput value={{data.VEHICLEID}} placeholder="Ex. 1234">
                      </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-md-3">
                      <mat-form-field >
                          <mat-label>Company Id</mat-label>
                          <input matInput value={{data.company_id}} placeholder="Ex. 1234">
                      </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-md-3">
                    <mat-form-field>
                      <mat-label>AS400 Vehicle Id</mat-label>
                      <input matInput value={{data.AS400VEHICLEID}} placeholder="Ex. 1234" />
                    </mat-form-field>
                  </div>

                  <div class="col-xs-12 col-md-3">
                    <mat-form-field>
                      <mat-label>MVA Number</mat-label>
                      <input matInput value={{data.AS400VEHICLEID}} placeholder="Ex. 1234" />
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-md-3">
                    <mat-form-field>
                      <mat-label>Registration No</mat-label>
                      <input matInput value={{data.REGISTRATIONNO}} placeholder="Ex. 1234" />
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-md-3">
                    <mat-form-field>
                      <mat-label>Fleet No</mat-label>
                      <input matInput value={{data.FLEETNO}} placeholder="Ex. 1234" />
                    </mat-form-field>
                  </div>
<!--
                  <div class="col-xs-12 col-md-3">
                    <mat-slide-toggle color="primary" class="myswitch"
                      ><p>Litre Per Hour</p></mat-slide-toggle
                    >
                  </div> -->


              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>Vehicle Type</mat-label>
                  <input matInput value={{data.VehType}} placeholder="Ex. ABCD" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>Make Id</mat-label>
                  <input
                    matInput
                    value={{data.MAKEID}}
                    disabled="true"
                    placeholder="Ex. ABCD"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>Range Id</mat-label>
                  <input
                    matInput
                    value={{data.RANGEID}}
                    disabled="true"
                    placeholder="Ex. ABCD"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>Model Id</mat-label>
                  <input
                    matInput
                    value={{data.MODELID}}
                    disabled="true"
                    placeholder="Ex. ABCD"
                  />
                </mat-form-field>
              </div>

              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>Fuel Type Id</mat-label>
                  <input matInput value={{data.FUELTYPEID}} placeholder="Ex. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>Vehicle Industry Type</mat-label>
                  <input matInput value={{data.INDUSTRYTYPE}} placeholder="Ex. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>Year</mat-label>
                  <input matInput value={{data.YEAR}} placeholder="Ex. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>Tank Capacity</mat-label>
                  <input matInput value={{data.TANKCAPACITY}} placeholder="Ex. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>CO2 Emissions</mat-label>
                  <input matInput value={{data.TANKCAPACITY}} placeholder="Ex. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>CO2 Emissions Class</mat-label>
                  <input matInput value="" placeholder="Ex. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>Industry Norm</mat-label>
                  <input matInput value={{data.INDUSTRYNORM}} placeholder="Ex. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>Month Rands</mat-label>
                  <input matInput value="" placeholder="Ex. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>Card No</mat-label>
                  <input matInput value={{data.CardNo}} placeholder="Ex. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>Customer Id</mat-label>
                  <input matInput value={{data.CUSTOMERID}} placeholder="Ex. 1234" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>Customer Name</mat-label>
                  <input matInput value={{data.CUSTOMERNAME}} placeholder="Ex. ABCD" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>AS400 Company Id</mat-label>
                  <input matInput value={{data.AS400CompanyId}} placeholder="Ex. 1234" />
                </mat-form-field>
              </div>

              <div class="col-xs-12 col-md-3">
                <mat-slide-toggle color="primary" class="myswitch"
                  ><p>AS400 Vehicle</p></mat-slide-toggle
                >
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-slide-toggle color="primary" class="myswitch"
                  ><p>Extended Weekened</p></mat-slide-toggle
                >
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-slide-toggle color="primary" class="myswitch"
                  ><p>Manage this vehicle</p></mat-slide-toggle
                >
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-slide-toggle color="primary" class="myswitch"
                  ><p>No Weekend</p></mat-slide-toggle
                >
              </div>
              <div class="col-xs-12 col-md-3">
                <mat-form-field>
                  <mat-label>Photo Path</mat-label>
                  <input matInput value={{data.PHOTOPATH}} placeholder="Ex. /path/" />
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-3">
                    <div class="myswitches">
                        <mat-slide-toggle color="primary" class="myswitch"><p>Use AS400 Drivers</p></mat-slide-toggle>
                    </div>
                </div>

              <div class="col-xs-12">
                <button class="mybtn" mat-flat-button color="primary" type="submit">
                  Save Changes
                </button>
              </div>
            </div>
        </form>
    </div>
</div>
