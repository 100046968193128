<div class="myModal setup">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p>Add New {{ data.setfor }} Email</p>
      <p>You are assigning new email</p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>
  <div class="modal-contain">
    <form
      class="myform addnewuser"
      [formGroup]="addEmailForm"
      (ngSubmit)="onSubmit()"
    >
      <!-- <div class="feedback" *ngIf="response === 'success'">
        <div class="__contain">
          <i class="fa-regular fa-circle-check"></i>
          <div class="details">
            <p>Email Added successfully</p>
          </div>
        </div>
      </div>
      <div *ngIf="response === 'loading'" class="feedback">
        <div class="__contain">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          <p>Please wait while we are proccessing</p>
        </div>
      </div> -->
      <ng-container
        *ngIf="
          response === '' ||
          response === 'network_err' ||
          response === 'user-exists'
        "
      >
        <div class="userpp">
          <i class="fa-regular fa-envelope"></i>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="">
              <mat-label>Select Email Address</mat-label>
              <input
                type="text"
                matInput
                [formControl]="emailAddressControl"
                [matAutocomplete]="auto3"
              />
              <mat-autocomplete #auto3="matAutocomplete">
                <mat-option
                  class="row"
                  *ngFor="let email of email_addresses"
                  [value]="email.EMAILADDRESS"
                  (onSelectionChange)="selectedEmail(email)"
                >
                  <div>
                    {{ email.EMAILADDRESS }}
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <mat-form-field class="">
              <mat-label>Select Mail Type</mat-label>
              <input
                type="text"
                matInput
                [formControl]="emailTypesControl"
                [matAutocomplete]="auto2"
              />
              <mat-autocomplete #auto2="matAutocomplete">
                <mat-option
                  class="row"
                  *ngFor="let mail_type of mail_types"
                  [value]="mail_type.MAILTYPEDESCRIPTION"
                  (onSelectionChange)="selectedEmailType(mail_type)"
                >
                  <div>
                    {{ mail_type.MAILTYPEDESCRIPTION }}
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <button class="mybtn" mat-flat-button color="primary" type="submit">
              Save <i class="fa fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</div>
