<div class="myModal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft"
      *ngIf="!data"
    >
      <p>Add Vehicle</p>
      <small>Fill all the fields</small>
    </div>
    <div class="lft"
      *ngIf="data"
    >
      <p>Update Vehicle</p>
      <p>{{ data?.make_vehicle_type?.make?.name + ': ' + data?.range?.name + '-' + data?.car_model?.name }}</p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal('close')">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>

  <div class="modal-contain">
    <div class="myuitabs">
      <div class="lft">
        <button mat-stroked-button type="button"
          [ngClass]="tab==='1'?'active':''"
          (click)="selectTab('1')"
        ><p>Vehicle Details</p>
        </button>
        <button mat-stroked-button type="button"
          [ngClass]="tab==='2'?'active':''"
          (click)="selectTab('2')"
          *ngIf="data"
        ><p>Vehicle Device</p>
        </button>
        <button mat-stroked-button type="button"
          [ngClass]="tab==='3'?'active':''"
          (click)="selectTab('3')"
          *ngIf="data"
        ><p>Vehicle Driver</p>
        </button>
      </div>
    </div>

    <!--TAB 1 (Vehicle)-->
    <ng-container *ngIf="tab === '1'">
      <form class="myform"
            [formGroup]="addVehicleForm"
            (ngSubmit)="onSubmit()"
      >
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>MVA Number</mat-label>
                  <input matInput formControlName="mva_number" placeholder="Ex. 1234">
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Registration No</mat-label>
                  <input matInput formControlName="registration_number" placeholder="Ex. 1234">
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>Year</mat-label>
                  <input
                    readonly
                    matInput
                    [matDatepicker]="yearPicker"
                    formControlName="year"
                    placeholder="Ex. 2024"
                    (focus)="yearPicker.open()"
                  >
                  <mat-datepicker-toggle matSuffix [for]="yearPicker"></mat-datepicker-toggle>
                  <mat-datepicker
                    #yearPicker
                    startView="multi-year"
                    (yearSelected)="chosenYearHandler($event, yearPicker)"
                    panelClass="year-picker"
                  ></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Tank Capacity</mat-label>
                  <input matInput formControlName="tank_capacity" placeholder="Ex. 1234">
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Vehicle Type</mat-label>
                  <mat-select formControlName="vehicle_type_uuid"
                              (selectionChange)="selectVehicleType($event.value)"
                  >
                    <mat-option
                      *ngFor="let type of vehicleTypes"
                      [value]="type.uuid">
                      {{ type.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Make</mat-label>
                  <mat-select
                    formControlName="make_uuid"
                    (click)="checkSelectedVehicleType()"
                    (selectionChange)="selectMake($event.value)"
                  >
                    <mat-option
                      *ngFor="let make of makes"
                      [value]="make.make.uuid">
                      {{ make.make.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Range</mat-label>
                  <mat-select
                    formControlName="range_uuid"
                    (click)="checkSelectedMake()"
                    (selectionChange)="selectRange($event.value)"
                  >
                    <mat-option
                      *ngFor="let range of ranges"
                      [value]="range.uuid">
                      {{ range.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Model</mat-label>
                  <mat-select
                    formControlName="model_uuid"
                    (click)="checkSelectedRange()"
                  >
                    <mat-option
                      *ngFor="let model of carModels"
                      [value]="model.uuid">
                      {{ model.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Fuel Type</mat-label>
                  <mat-select formControlName="fuel_type_uuid">
                    <mat-option
                      *ngFor="let fuelType of fuelTypes"
                      [value]="fuelType.uuid">
                      {{ fuelType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Photo Path</mat-label>
                  <input matInput formControlName="photo" placeholder="Ex. /path/">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-xs-12">
            <button class="mybtn" mat-flat-button color="primary" type="submit">
              {{ !data ? 'Add ' : 'Update ' }} Vehicle
            </button>
          </div>
        </div>
      </form>
    </ng-container>

    <!--TAB 2 (Device)-->
    <ng-container *ngIf="tab === '2'">
      <form class="myform" [formGroup]="addDeviceForm" (ngSubmit)="onSubmitDeviceForm()">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>IMEI</mat-label>
                  <input matInput formControlName="imei" placeholder="Ex. 123456789012345">
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Device Name</mat-label>
                  <input matInput formControlName="device_name" placeholder="Ex. GPS Tracker">
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Device Type</mat-label>
                  <input matInput formControlName="device_type" placeholder="Ex. Tracker">
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>Plate Number</mat-label>
                  <input matInput formControlName="plate_number" placeholder="Ex. ABC1234">
                </mat-form-field>
              </div>
              <div class="col-xs-12 col-md-4">
                <mat-form-field>
                  <mat-label>SIM Card</mat-label>
                  <input matInput type="tel" formControlName="sim_card" placeholder="Ex. 0123456789">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-xs-12">
            <button class="mybtn" mat-flat-button color="primary" type="submit">
              {{ !data || !data?.device ? 'Add ' : 'Update ' }} Device
            </button>
          </div>
        </div>
      </form>
    </ng-container>

    <!--TAB 3 (Driver)-->
    <ng-container *ngIf="tab === '3'">
      <app-add-driver
        vehicleDriver="true"
      ></app-add-driver>
    </ng-container>
  </div>
</div>
