import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.css']
})
export class ActionButtonsComponent {
  constructor(
    public dialogRef: MatDialogRef<ActionButtonsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    isSetTankFull = '';
    isSetPreviousOdoEqualToZero = '';
    isSetCurrentEqualToPrevious = '';
    isSetCurrentEqualToZero = '';
    addMinutes = '00:00';
    addKMToOdo = '0';
    isTakeTwoDigitsOff = 'False';
    

    closeModal(): void {
      this.dialogRef.close();
    }
    //Table Data
    selected_transactions = this.data.selected_transactions;


    selectIsTankFull(value: any) {
      if(this.isSetTankFull===value){
        this.isSetTankFull = '';
      }else{
        this.isSetTankFull = value;
      }
    }

    selectIsSetPreviousOdoEqualToZero(value: any) {
      if(this.isSetPreviousOdoEqualToZero===value){
        this.isSetPreviousOdoEqualToZero = '';
      }else{
        this.isSetPreviousOdoEqualToZero = value;
      }
    }
    selectIsSetCurrentEqualPrevious(value: any) {
      if(this.isSetCurrentEqualToPrevious===value){
        this.isSetCurrentEqualToPrevious = '';
      }else{
        this.isSetCurrentEqualToPrevious = value;
      }
    }
    selectIsSetCurrentEqualToZero(value: any) {
      if(this.isSetCurrentEqualToZero===value){
        this.isSetCurrentEqualToZero = '';
      }else{
        this.isSetCurrentEqualToZero = value;
      }
    }


    AddMinutes(event: any) {
      this.addMinutes = event.value;
    }
    AddKMToOdo(event: any) {
      this.addKMToOdo = event.value;
    }
    selectTake2DigitsOff(event: any) {
      this.isTakeTwoDigitsOff = event.value;
    }

    SaveChanges(){
      const data = {
        "isSetTankFull": this.isSetTankFull,
        "isSetPreviousOdoEqualToZero": this.isSetPreviousOdoEqualToZero,
        "isSetCurrentEqualToPrevious": this.isSetCurrentEqualToPrevious,
        "isSetCurrentEqualToZero": this.isSetCurrentEqualToZero,
        "addMinutes": this.addMinutes,
        "addKMToOdo": this.addKMToOdo,
        "isTake2DigitsOff": this.isTakeTwoDigitsOff,
      }
      this.dialogRef.close({event:'update', data:data});
    }
    CombineTransactions(){
      this.dialogRef.close({event:'combine-transactions'});
    }
    RemoveCalculations(){
      this.dialogRef.close({event:'remove-calculations'});
    }

} 
