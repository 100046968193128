<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="lft">
            <p>Recalculate</p>
            <p>Recalculate transaction</p>
        </div>
        <div class="rgt">
            <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
        </div>
    </div>

    <div class="modal-contain">
        <form class="myform">

             <!-- Loading -->
            <div class="myrecalculations"  >
                <div class="contain">
                   
                    <div class="mychip" *ngFor="let r of dataSource.slice(0,5)"  [matTooltip]="r | json">
                        <p>Reg: {{r.REGISTRATIONNO}}</p>
                    </div>
                    <div class="mychip" *ngIf="dataSource.length>5">
                        <p>And more ({{dataSource.slice(5).length}})</p>
                    </div>
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    <p class="message">Please wait while recalculating transcation</p>
                </div>
            </div>
        </form>
    </div>
  </div>

