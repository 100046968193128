<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="lft">
        <p>Action buttons</p>
        <p>Action buttons</p>
      </div>
      <div class="rgt">
        <button mat-flat-button (click)="closeModal()" >
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>
  
    <div class="modal-contain">
      <form class="myform">
           
            <div class="row actionbtns">
                <div class="col-12 col-sm-12 col-md-7 lft">
                  <!-- <p class="mytitle FxLine">Selected Transactions</p> -->
                    <div class="mychips">
                        <div class="mychip" *ngFor="let r of selected_transactions.slice(0,3)"  [matTooltip]="r">
                          <p>Reg: {{r.REGISTRATIONNO}}</p>
                        </div>
                        <div class="mychip" *ngIf="selected_transactions.length>3">
                          <p>And more ({{selected_transactions.slice(3).length}})</p>
                      </div>
                    </div>
                    <div class="row">
                       
                        <div class="col-12 col-md-3">
                          <div class="card055">
                            <p class="title">Set Tank Full</p>
                            <div class="butons">
                                <button mat-stroked-button (click)="selectIsTankFull('Yes')" [ngClass]="isSetTankFull==='Yes'?'active':''"><p>Yes</p></button>
                                <button mat-stroked-button (click)="selectIsTankFull('No')" [ngClass]="isSetTankFull==='No'?'active':''"><p>No</p></button> 
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-md-3">
                          <div class="card055">
                            <p class="title">Previous Odo to 0</p>
                            <div class="butons">
                                <button mat-stroked-button (click)="selectIsSetPreviousOdoEqualToZero('Yes')" [ngClass]="isSetPreviousOdoEqualToZero==='Yes'?'active':''"><p>Yes</p></button>
                                <button mat-stroked-button (click)="selectIsSetPreviousOdoEqualToZero('No')" [ngClass]="isSetPreviousOdoEqualToZero==='No'?'active':''"><p>No</p></button> 
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-3">
                          <div class="card055">
                            <p class="title">Current = Previous</p>
                            <div class="butons">
                                <button mat-stroked-button (click)="selectIsSetCurrentEqualPrevious('Yes')" [ngClass]="isSetCurrentEqualToPrevious==='Yes'?'active':''"><p>Yes</p></button>
                                <button mat-stroked-button (click)="selectIsSetCurrentEqualPrevious('No')" [ngClass]="isSetCurrentEqualToPrevious==='No'?'active':''"><p>No</p></button> 
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-3">
                          <div class="card055">
                            <p class="title">Current = 0</p>
                            <div class="butons">
                                <button mat-stroked-button (click)="selectIsSetCurrentEqualToZero('Yes')" [ngClass]="isSetCurrentEqualToZero==='Yes'?'active':''"><p>Yes</p></button>
                                <button mat-stroked-button (click)="selectIsSetCurrentEqualToZero('No')" [ngClass]="isSetCurrentEqualToZero==='No'?'active':''"><p>No</p></button> 
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <mat-form-field >
                            <mat-label>Add KM to Odo</mat-label>
                            <input matInput type="number" (keyup)="AddKMToOdo($event.target)" value={{addKMToOdo}} placeholder="Ex. 10000">
                          </mat-form-field>
                        </div>
                        <div class="col-12 col-md-6">
                          <mat-form-field >
                              <mat-label>Add Minutes</mat-label>
                              <input matInput type="time" (keyup)="AddMinutes($event.target)" matInput min="09:00" max="23:00" value={{addMinutes}} placeholder="Ex. 12:00" placeholder="Ex. 2">
                          </mat-form-field>
                        </div>
                        
                        <div class="col-12 col-md-12">
                          <mat-form-field appearance="fill">
                            <mat-label>Take 2 Digits Off</mat-label>
                            <mat-select [value]="isTakeTwoDigitsOff" (selectionChange)="selectTake2DigitsOff($event)">
                              <mat-option value="No">No</mat-option>
                              <mat-option value="Yes">Yes</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                    </div>
                    <button class="mybtn" (click)="SaveChanges()" type="button" mat-flat-button color="primary">Update Changes</button>
                    
                </div> 
                <div class="col-12 col-sm-12 col-md-5 rgt">
                    <div class="menu">
                        <p class="mytitle FxLine">More actions</p>
                        <div class="row">
                          <div class="col-12 col-md-6">
                              <button mat-stroked-button> First x = N Last 1 = Y </button>
                          </div>
                          <div class="col-12 col-md-6">
                              <button mat-stroked-button (click)="CombineTransactions()"> Combine In to 1 </button>
                          </div>
                          <div class="col-12 col-md-6">
                              <button mat-stroked-button>Add Veh to No Calc</button>
                          </div>
                          <div class="col-12 col-md-6">
                            <button mat-stroked-button>Remove Matched Ind</button>
                          </div>
                          <div class="col-12 col-md-6">
                            <button mat-stroked-button>Move To Not Managed</button>
                          </div>
                          <div class="col-12 col-md-6">
                            <button mat-stroked-button>Make Initial</button>
                          </div>
                          <div class="col-12 col-md-6">
                            <button mat-stroked-button>Export to excel</button>
                          </div>
                          <div class="col-12 col-md-6">
                            <button mat-stroked-button>Export as Trip</button>
                          </div>
                          <div class="col-12 col-md-6">
                            <button mat-stroked-button (click)="RemoveCalculations()">Remove all calculations</button>
                          </div>
                          <div class="col-12 col-md-6">
                            <button mat-stroked-button class="red">Delete Transactions</button>
                          </div>
                        </div>
                        
                    </div>
                   
                </div>
            </div>
           
      </form>
    </div>
  </div>
  