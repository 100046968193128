<div class="myModal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p>Import Transaction Files</p>
      <p></p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>
  <div class="modal-contain">
    <form class="myform">
      <div class="htitle">
        <p class="FxLine">Import your files</p>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>Select Data Dictionary</mat-label>
          <mat-select [(value)]="selectedDictionary" name="dataDictionary">
            <mat-option>None</mat-option>
            <mat-option
              *ngFor="let dictionary of dictionaries"
              value={{dictionary.DataDictionaryID}}
              >{{ dictionary.DataDictionaryName }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <ngx-file-drop
            accept=".xls, .xlsx, .csv, .txt, .txn, .dat, .TXN"
            dropZoneLabel="Drop files here"
            (onFileDrop)="dropped($event)"
          >
            <ng-template
              ngx-file-drop-content-tmp
              let-openFileSelector="openFileSelector"
            >
              <label class="mydraganddrop" (click)="openFileSelector()">
                <div *ngIf="!uploadedFile" class="contain">
                  <mat-icon class="fa-1x">attachment</mat-icon>
                  <p>
                    Drag and drop or click to upload transaction File.
                    <a>Browse file</a>
                  </p>
                </div>
                <div *ngIf="uploadedFile" class="contain">
                  <ng-container *ngIf="fileError == 'success'">
                    <mat-icon class="success">insert_drive_file</mat-icon>
                    <p>
                      Your file has been attached.
                      <span class="success">{{ uploadedFile.name }}</span>
                    </p>
                    <a>Browse another file</a>
                  </ng-container>
                  <ng-container *ngIf="fileError == 'unsupported_file'">
                    <mat-icon class="error">error_outline</mat-icon>
                    <p>
                      <span class="error"
                        >Sorry, your file ({{ uploadedFile.name }}) is
                        unsupported, allowed files are:</span
                      >
                      {{ validFilesExtensions.join(", ") }}
                    </p>
                    <a>Browse another file</a>
                  </ng-container>
                </div>
              </label>
            </ng-template>
          </ngx-file-drop>
        </div>
        <div class="col-xs-12">
          <button
            id="submit-files"
            class="mybtn"
            mat-flat-button
            color="primary"
            (click)="uploadFiles()"
          >
            Import Transaction File(s)
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
