
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import exporter from '@byetool/json-exporter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-operators-report',
  templateUrl: './operators-report.component.html',
  styleUrls: ['./operators-report.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class OperatorsReportComponent {
  constructor(
    public dialogRef: MatDialogRef<OperatorsReportComponent>,
    private ReportsService: ReportsService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    openSnackBar(message: string, action: string, type: string) {
      this._snackBar.open(message, action, { duration: 5000, panelClass: type });
    }

    closeModal(): void {
      this.dialogRef.close();
    }

    displayedColumns = [
      'USERNAME',
      'COMPANYNAME',
      'LastArchiveDate',
      'LastImportDate',
      'NoEmailsSent',
      'NoEmailsReplied',
      'NoFSEmails',
      'NoFSEmailsReplied',
      'NoTelCalls',
      'NoOccurrences'
    ];

  startDate = '';
  endDate = '';
  dataSource = [];
  response = '';

  FetchOperatorReports() {
    if(this.startDate == '' || this.endDate == ''){
      return;
    }
    let data = {
      "startDate": this.startDate,
      "endDate": this.endDate,
    };
    this.response = 'loading';
    this.ReportsService.getOperatorsReport(data).subscribe({
      next: (data: any) => {
        if (data && data.length > 0) {
          this.response = 'success';
          this.dataSource = data;
        } else {
          this.response = 'no_results';
          this.openSnackBar('No results', 'Okay', 'success');
        }
      },
      error: (error:any) => {
        this.openSnackBar('Check internet connectivity', 'Okay', 'error');
        this.response = 'network_err';
      },
    });
  }

  handleStartDate(event: any) {
    const value = event.value;
    this.startDate = value.format('YYYY-MM-DD');
    this.FetchOperatorReports()
  }
  handleEndDate(event: any) {
    const value = event.value;
    this.endDate = value.format('YYYY-MM-DD');
    this.FetchOperatorReports()
  }
  getCurrentDate(){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  }

  ExportIt(){
    if(this.dataSource.length<1){
      this.openSnackBar('No Records', 'Okay', 'error');
      return;
    }
    if(this.dataSource.length>0){
        const filename = 'Operator Reports - ' + this.getCurrentDate();
        exporter.toXLSX(this.dataSource, filename, {
          headers: this.displayedColumns,
        })  
    }
    this.openSnackBar('Download started', 'Okay', 'success');
  }

  ngOnInit() {
    this.startDate = this.getCurrentDate();
    this.FetchOperatorReports()
  }
}
