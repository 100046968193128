

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-recalculate',
  templateUrl: './recalculate.component.html',
  styleUrls: ['./recalculate.component.css']
})
export class RecalculateComponent {
  constructor(
    public dialogRef: MatDialogRef<RecalculateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}


    dataSource = this.data;
    closeModal(data?: any): void {
      this.dialogRef.close({ event: data });
    }

}

