import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';
import { TransactionService } from 'src/app/services/transactions/transaction.service';
@Component({
  selector: 'app-combine-transaction',
  templateUrl: './combine-transaction.component.html',
  styleUrls: ['./combine-transaction.component.css'],
})
export class CombineTransactionComponent {
modalStyle: any;
modalTitle: any;
modalBody: any;
modalButtonColor: any;
getConfirmationValue($event: string) {
throw new Error('Method not implemented.');
}
  constructor(
    public dialogRef: MatDialogRef<CombineTransactionComponent>,
    private _transactionServ: TransactionService,
    private _snackBarServ: SnackbarsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeModal(data?: any): void {
    this.dialogRef.close({ event: data });
  }

  //Table Data
  dataSource = this.data;

  onSubmit() {
    this._transactionServ
      .mergeTransactions(this.dataSource)
      .subscribe((response: any) => {

        this.closeModal(true);
        this._snackBarServ.success('Transactions merged successfully');
      },);
  }
}
