import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-edit-transaction',
  templateUrl: './edit-transaction.component.html',
  styleUrls: ['./edit-transaction.component.css']
})
export class EditTransactionComponent {
  constructor(
    public dialogRef: MatDialogRef<EditTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  transaction = this.data;
  tab = '1';

  isSetTankFull = this.transaction.TANKFULL;
  isNewImport = this.transaction.NEWIMPORT;
  isMailSent = this.transaction.MailSent;
  isInternal = this.transaction.FstatInternal;
  isIgnoreCalculations = this.transaction.IGNORECALC;
  isMatched = this.transaction.MATCHED;
  isNewOdo = this.transaction.NEWODO;

  selectTab(value: any) {
    this.tab = value;
  }


  selectIsTankFull(value: any) {
    this.isSetTankFull = value;
    this.transaction.TANKFULL = Number(value);
  }
  selectIsNewImport(value: any) {
    this.isNewImport = value;
    this.transaction.NEWIMPORT = Number(value);
  }
  selectIsMailSent(value: any) {
    this.isMailSent = value;
    this.transaction.MailSent = Number(value);
  }
  selectIsInternal(value: any) {
    this.isInternal = value;
    this.transaction.FstatInternal = Number(value);
  }
  selectIsIgnoreCalculation(value: any) {
    this.isIgnoreCalculations = value;
    this.transaction.IGNORECALC = Number(value);
  }
  selectIsMatched(value: any) {
    this.isMatched = value;
    this.transaction.MATCHED = Number(value);
  }
  selectIsNewOdo(value: any) {
    this.isNewOdo = value;
    this.transaction.NEWODO = Number(value);
  }
  closeModal(){
    this.dialogRef.close({event:'close'});
  }
  isNumeric(num:any){
    return !isNaN(num)
  }
  validateInput(event:any,param:any){
    const value = this.isNumeric(event.value)?Number(event.value):event.value;
    this.transaction[param] = value;
  }
  saveChanges(){
    this.dialogRef.close({event:'update', data:this.transaction});
  }

}
