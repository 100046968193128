<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="lft">
            <p>Configurations</p>
            <p>Config email notifications</p>
        </div>
        <div class="rgt">
            <button mat-flat-button (click)="closeModal()">
                <i class="fa-solid fa-xmark"></i>
            </button>
        </div>
    </div>

    <div class="modal-contain">
        <form class="myform">
            <div class="myuitabs">
                <div class="lft">
                    <button mat-stroked-button class="active"><p>Cost Center</p></button>
                    <button mat-stroked-button><p>Account</p></button>
                    <button mat-stroked-button><p>Customer</p></button>   
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="card007 standlone">
                        <div class="mytable">
                            <table mat-table [dataSource]="dataSource_Cost_Center" class="mat-elevation-z8">
                                    <ng-container matColumnDef="CUSTOMER_ID">
                                        <th mat-header-cell *matHeaderCellDef>Customer Id</th>
                                        <td mat-cell *matCellDef="let row">
                                            {{ row.CUSTOMER_ID }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="ACCOUNT_NAME">
                                        <th mat-header-cell *matHeaderCellDef>Account Name</th>
                                        <td mat-cell *matCellDef="let row">
                                            {{ row.ACCOUNT_NAME }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="COST_CENTRE_NAME">
                                        <th mat-header-cell *matHeaderCellDef>Cost Centre Name</th>
                                        <td mat-cell *matCellDef="let row">
                                            {{ row.COST_CENTRE_NAME }}
                                        </td>
                                    </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns_Cost_Center; sticky:true"></tr>
                                <tr mat-row *matRowDef=" let row; columns: displayedColumns_Cost_Center"></tr>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-md-6">
                    <div class="card007">
                        <div class="mytable">
                            <table mat-table [dataSource]="dataSource_Email_Addresses" class="mat-elevation-z8">
                                <ng-container matColumnDef="EMAIL_ADDRESS">
                                    <th mat-header-cell *matHeaderCellDef>Email Address</th>
                                    <td mat-cell *matCellDef="let row">
                                        {{ row.EMAIL_ADDRESS }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="EMAIL_TYPE_DESCRIPTION">
                                    <th mat-header-cell *matHeaderCellDef>Email Type Description</th>
                                    <td mat-cell *matCellDef="let row">
                                        {{ row.EMAIL_TYPE_DESCRIPTION }}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns_Email_Addresses; sticky:true"></tr>
                                <tr mat-row *matRowDef=" let row; columns: displayedColumns_Email_Addresses"></tr>
                            </table>
                        </div>
                    </div>
                    <div class="actionsbtns">
                        <div class="myrow">
                            <button mat-stroked-button class="active"><p>Insert</p></button>
                            <button mat-stroked-button class="active"><p>Change</p></button>
                            <button mat-stroked-button class="active"><p>Delete</p></button>
                        </div>
                        <div class="myrow">
                            <button mat-stroked-button class="active"><p>Change Email</p></button>
                            <button mat-stroked-button class="active"><p>Dublicate Same Email</p></button>
                            <button mat-stroked-button class="active"><p>Delete Emails for CC</p></button>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
            
        </form>
    </div>
  </div>

