<div class="myModal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p>Export </p>
      <p>Your transcation will be exported to file</p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>

  <div class="modal-contain">
    <form class="myform">
      <mat-radio-group aria-label="Select an option">
        <div class="exportmethods" *ngIf="dataSource.length>0" [class]="export_method=='custom-selected' ? 'active' : ''" (click)="ExportMethod('custom-selected')">
            <mat-radio-button (change)="ExportMethod('custom-selected')"
                              [checked]="export_method=='custom-selected'?true:false" value="1" color="primary">
              <p>Selected Transactions</p>
            </mat-radio-button>
            <div class="contents" [class]="export_method=='custom-selected' ? 'active' : ''">

              <div class="mychip" *ngFor="let r of dataSource.slice(0,10)"  [matTooltip]="r | json">
                  <p>Reg: {{r.REGISTRATIONNO}}</p>
              </div>
              <div class="mychip" *ngIf="dataSource.length>10">
                  <p>And more ({{dataSource.slice(10).length}})</p>
              </div>

            </div>
        </div>

      

      </mat-radio-group>

      <div class="export_as">
        <p>Export As</p>
        <div class="row">
          <div class="col-xs-12 col-md-2">
            <button mat-stroked-button (click)="ExportAs('excel')" [class]="export_as=='excel' ? 'active' : ''">
              Excel
            </button>
          </div>
          <div class="col-xs-12 col-md-2">
            <button mat-stroked-button (click)="ExportAs('csv')" [class]="export_as=='csv' ? 'active' : ''">
              CSV
            </button>
          </div>
        
        </div>

      </div>

      <div class="row">
        <div class="col-xs-12">
          <button class="mybtn" (click)="ExportIt()" type="button" mat-flat-button color="primary">Export</button>
        </div>
      </div>
    </form>
  </div>
</div>
