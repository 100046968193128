import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CacheService } from '../utilities/cache/cache.service';
import {AuthService} from "../auth/auth.service";
const CACHE_KEY = {
  httpManagedVehiclesCache: 'httpManagedVehiclesCache',
  httpNotManagedVehiclesCache: 'httpNotManagedVehiclesCache',
  httpAwaitingVehiclesCache: 'httpAwaitingVehiclesCache',
  httpVehiclesCountsCache: 'httpTransactionCountsCache',
  httpVehicleDetailsCache: 'httpVehicleDetailsCache',
};
@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  constructor(
    private http: HttpClient,
    private _stateServ: CacheService,
    private _authService: AuthService
  ) { }

  public service_base = this._authService.service_base;

  private headers = new HttpHeaders({
    'x-Gateway-APIKey': 'c2c22314-ca55-4b42-b472-494a6c180706',
    'Access-Control-Allow-Origin': '*',
  });

  addVehicle(vehicleDetails: any) {
    const url = this.service_base + 'vehicle/store';
    const result = this.http.post<any>(url, vehicleDetails, {
      headers: this.headers,
    });
    return result;
  }

  getVehicleAccount(registration: string) {
    const url =
      this.service_base +
      'getVehicleAccount?RegistrationNumber=' +
      registration;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;
  }

  getAllVehices(id: any) {
    const url = this.service_base + 'getAllVehicle?companyId=' + id;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;
  }

  updateVehicle(vehicleDetails: any) {
    const url = this.service_base + 'vehicle/update';
    const result = this.http.post<any>(url, vehicleDetails, {
      headers: this.headers,
    });
    return result;
  }

  getVehicleByRegistration(registration: string) {
    const url =
      this.service_base +
      'getVehicleByRegNo?RegistrationNumber=' +
      registration;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;
  }

  getVehicleByCompany(companyId: any) {
    const url =
      this.service_base + 'vehicle/get-vehicles-by-company-uuid/' + companyId;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return this._stateServ.manageDataState(
      result,
      CACHE_KEY.httpManagedVehiclesCache
    );
  }

  getVehicleByID(vehicleId: string) {
    return null;
  }
  getNotManagedVehicles(companyName: string) {
    const url =
      this.service_base + 'getNotManagedVehicle?CompanyName=' + companyName;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return this._stateServ.manageDataState(
      result,
      CACHE_KEY.httpNotManagedVehiclesCache
    );
  }

  getAwaitingVehiclesByCompanyId(id: any): Observable<any> {
    const url = this.service_base + 'getNoVehicleByCompanyId?CompanyId=' + id;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return this._stateServ.manageDataState(
      result,
      CACHE_KEY.httpAwaitingVehiclesCache
    );
  }

  getVehicleKPI(id: any) {
    const url = this.service_base + 'vehicle/get-vehicle-kpi/' + id;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return this._stateServ.manageDataState(
      result,
      CACHE_KEY.httpVehiclesCountsCache
    );
  }

  getVehicleDetails(RegistrationNumber: any) {
    const url =
      this.service_base +
      '	getVehicleDetail?RegistrationNo=' +
      RegistrationNumber;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    // return this._stateServ.manageDataState(
    //   result,
    //   CACHE_KEY.httpVehicleDetailsCache
    // );
    return result;
  }

  getVehicleModel(registrationNo: any) {
    const url =
      '	/company/getVehicleModel/' +
      registrationNo;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;
  }

  getAllVehicleTypes() {
    const url = 'vehicle/get-vehicle-types';
    const result = this.http.get<any>(this.service_base + url, {
      headers: this.headers,
    });
    return result;
  }

  // @ts-ignore
  getVehicleTypeMakes(vehicle_type_uuid) {
    const url = `vehicle/get-vehicle-type-makes/${vehicle_type_uuid}`;
    const result = this.http.get<any>(this.service_base + url, {
      headers: this.headers,
    });
    return result;
  }

  // @ts-ignore
  getMakeRanges(make_uuid) {
    const url = `vehicle/get-make-ranges/${make_uuid}`;
    const result = this.http.get<any>(this.service_base + url, {
      headers: this.headers,
    });
    return result;
  }
  // @ts-ignore
  getRangeModels(range_uuid) {
    const url = `vehicle/get-range-models/${range_uuid}`;
    const result = this.http.get<any>(this.service_base + url, {
      headers: this.headers,
    });
    return result;
  }
  getFuelTypes() {
    const url = `fuel-type/get-all`;
    const result = this.http.get<any>(this.service_base + url, {
      headers: this.headers,
    });
    return result;
  }
}
