<div class="myModal importFileModal" [class]="isMaximized ? 'maximized' : ''">
  <div class="myhead" >
    <div class="lft">
      <p>Import Transaction File</p>
      <p>Upload your file</p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="maximizeModal()">
        <i *ngIf="!isMaximized" class="fa-solid fa-expand"></i>
        <i *ngIf="isMaximized" class="fa-solid fa-compress"></i>
      </button>
      <button mat-flat-button (click)="closeModal()" >
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>

  <div class="modal-contain">
    <form class="myform">
      <label
        class="importfile"
        for="file"
        matRipple
        matRippleColor="rgb(0 0 0 / 15%)"
      >
        <div class="contain">
          <div class="lft">
            <p>Upload File...</p>
          </div>
          <div class="rgt">
            <p>No file selected</p>
            <i class="fa-solid fa-circle-info"></i>
          </div>
        </div>
        <input
          type="file"
          id="file"
          (change)="onFileChange($event)"
          accept=".xls, .xlsx, .csv, .txt, .txn, .dat, .TXN"
        />
      </label>
      <hr />
      <div class="actionbtns" [ngClass]="isLoading?'disabled':''">
        <div class="mytool">
          <button
            [class]="selected_dictionary !== '' ? 'active' : ''"
            mat-flat-button
            color="warm"
            [matMenuTriggerFor]="choosedictionary"
          >
            <div class="details">
              <p *ngIf="selected_dictionary !== ''">
                Dictionary selected<i class="fa-solid fa-circle-check"></i>
              </p>
              <p *ngIf="selected_dictionary === ''">
                Choose dictionary <i class="fa-solid fa-caret-down"></i>
              </p>
            </div>
          </button>
          <button
            [class]="selected_layout ? 'active' : ''"
            mat-flat-button
            color="warm"
            [matMenuTriggerFor]="layouts"
          >
            <div class="details">
              <p *ngIf="selected_layout">Layouts <i class="fa-solid fa-circle-check"></i>
              <p *ngIf="!selected_layout">Layouts <i class="fa-solid fa-caret-down"></i></p>
            </div>
          </button>
          <button
            mat-flat-button
            color="warm"
            [matMenuTriggerFor]="assigncolumns"
          >
            <div class="details">
              <p>Assign Headers <i class="fa-solid fa-caret-down"></i></p>
            </div>
          </button>
          <button
            [class]="selected_date_format !== '' ? 'active' : ''"
            [matMenuTriggerFor]="assignDate"
            mat-flat-button
            color="warm"
          >
            <div class="details">
              <p *ngIf="this.selected_date_format !== ''">
                Date Formatted<i class="fa-solid fa-circle-check"></i>
              </p>
              <p *ngIf="this.selected_date_format === ''">
                Date Format <i class="fa-solid fa-caret-down"></i>
              </p>
            </div>
          </button>
          <button
            [class]="selected_time_format !== '' ? 'active' : ''"
            [matMenuTriggerFor]="assignTime"
            mat-flat-button
            color="warm"
          >
            <div class="details">
              <p *ngIf="this.selected_time_format !== ''">
                Time Formatted<i class="fa-solid fa-circle-check"></i>
              </p>
              <p *ngIf="this.selected_time_format === ''">
                Time Format <i class="fa-solid fa-caret-down"></i>
              </p>
            </div>
          </button>
          <button
            mat-flat-button
            color="warm"
            (click)="toggleSkipRow()"
          >
            <div class="details">
              <p>
                Skip First Row
                <mat-checkbox
                  color="primary"
                  [checked]="isSkipFirstRow==='1'?true:false"
                ></mat-checkbox>
              </p>
            </div>
          </button>
        </div>
        <button
          mat-flat-button
          color="primary"
          [disabled]="selected_dictionary === '' ? true : false"
          type="button"
          class="commitbtn"
          (click)="Import('yes')"
        >
          Import
        </button>
      </div>

      <div *ngIf="isLoading" class="fileloader">
        <div class="contain">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          <p>Please wait while we are proccessing the records</p>
        </div>
      </div>
      <hot-table
        id="testSheet"
        [data]="dataset"
        [colHeaders]="colHeaders"
        [hidden]="tableHidden"
        [settings]="tableSettings"
        [height]="isMaximized ? 'calc(100vh - 220px)' : '50vh'"
        [hotId]="id"
      >
      </hot-table>

      <ngx-file-drop
        [class]="dataset || isLoading ? 'hide' : ''"
        accept=".xls, .xlsx, .csv, .txt, .txn, .dat, .TXN"
        dropZoneLabel="Drop files here"
        (onFileDrop)="dropped($event)"
      >
        <ng-template
          ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector"
        >
          <label class="mydraganddrop" (click)="openFileSelector()">
            <div class="contain">
              <i class="fa-solid fa-upload"></i>
              <p>
                Drag and drop or click to upload transaction File.
                <a>Browse file</a>
              </p>
            </div>
          </label>
        </ng-template>
      </ngx-file-drop>
      <!-- <p *ngIf="ingoreList.length>0">Ignored line,
                <ng-container *ngFor="let r of ingoreList;let i = index">
                    {{r.Line + 1}}
                    <ng-container *ngIf="(i+1) < ingoreList.length">,</ng-container>
                </ng-container>
                 because {{ingoreList[0].Exception}}
            </p> -->
    </form>
  </div>
</div>
<mat-menu #assigncolumns="matMenu" xPosition="after" class="mytoolmenu">
  <div class="contain">
    <form class="myform">
      <div class="htitle">
        <p class="FxLine">Assign Headers</p>
      </div>
      <div class="row">
        <div class="col-12 col-lg-12" (click)="$event.stopPropagation()">
          <mat-form-field appearance="fill">
            <mat-label>Select heading</mat-label>
            <mat-select
              [value]="selected_heading"
              (selectionChange)="selectHeader($event)"
            >
              <mat-option value="Registration No">Registration No</mat-option>
              <mat-option value="Driver">Driver</mat-option>
              <mat-option value="Date">Date</mat-option>
              <mat-option value="Time">Time</mat-option>
              <mat-option value="Card No">Card No</mat-option>
              <mat-option value="Fuel Company">Fuel Company</mat-option>
              <mat-option value="Voucher No">Voucher No</mat-option>
              <mat-option value="Fuel Station">Fuel Station</mat-option>
              <mat-option value="Oil Company">Oil Company</mat-option>
              <mat-option value="Town">Town</mat-option>
              <mat-option value="Pump Attendant">Pump Attendant</mat-option>
              <mat-option value="Odo Reading">Odo Reading</mat-option>
              <mat-option value="Tank Full">Tank Full</mat-option>
              <mat-option value="Dist Travelled">Dist Travelled</mat-option>
              <mat-option value="L/100">L/100</mat-option>
              <mat-option value="Km/L">Km/L</mat-option>
              <mat-option value="Litres">Litres</mat-option>
              <mat-option value="Price Per Litre">Price Per Litre</mat-option>
              <mat-option value="Amount">Amount</mat-option>
              <mat-option value="From Location">From Location</mat-option>
              <mat-option value="To Location">To Location</mat-option>
              <mat-option value="Fridge Litres">Fridge Litres</mat-option>
              <mat-option value="Comments">Comments</mat-option>
              <mat-option value="Invoice No">Invoice No</mat-option>
              <mat-option value="Pickup Type">Pickup Type</mat-option>
              <mat-option value="Home">Home</mat-option>
              <mat-option value="Seal 1">Seal 1</mat-option>
              <mat-option value="Seal 2">Seal 2</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-12" (click)="$event.stopPropagation()">
          <mat-form-field appearance="fill">
            <mat-label>Select column</mat-label>
            <mat-select
              [value]="selected_column_position"
              (selectionChange)="selectColumn($event)"
            >
              <mat-option
                *ngFor="let r of colHeaders; let i = index"
                value="{{ i }}"
                >{{ r }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="groupedbtns">
          <button
            mat-flat-button
            color="primary"
            (click)="assignHeader()"
            class="commitbtn"
          >
            Assign
          </button>
          <button mat-flat-button color="warm"><p>Clear headings</p></button>
        </div>
      </div>
    </form>
  </div>
</mat-menu>

<mat-menu #layouts="matMenu" xPosition="after" class="mytoolmenu">
  <div class="contain">
    <form class="myform">
      
      
      <div class="mylayouts">
          <div class="htitle">
            <p>Layouts</p>
          </div>
          <div class="searchbox" (click)="$event.stopPropagation()">
              <i class="fa-solid fa-magnifying-glass"></i>
              <input type="text" placeholder="Search here"/>
          </div>
          <div class="cardscontain">
            <ng-container *ngIf="response_layouts_data.length>0" >
              <ng-container *ngFor="let r of response_layouts_data" >
                <div class="card001">
                  <div class="lft">
                    <p>{{r.name}}</p>
                  </div>
                  <div class="rgt">
                    <button mat-flat-button color="warm" (click)="$event.stopPropagation();deleteLayout(r)"><i class="fa-solid fa-trash"></i></button>
                    <button mat-stroked-button color="warm" [ngClass]="selected_layout?'active':''" (click)="selectLayout(r)"><p *ngIf="!selected_layout">Select</p><p *ngIf="selected_layout">Unselect</p></button>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            
            <div class="feedback" *ngIf="response_layouts_data.length<1">
              <div class="contain__">
                <i class="fa-regular fa-folder-open"></i>
                <p>You do not have layouts</p>
              </div>
            </div>

          </div>
          <div class="foot">
            <div class="lft">
              <p>Save Layouts</p>
              <p>This will save your current layout</p>
            </div>
            <div class="rgt">
              <button mat-flat-button color="primary" (click)="addNewLayout()">Add Layout</button>
            </div>
          </div>
      </div>
    </form>
  </div>
</mat-menu>

<mat-menu
  #choosedictionary="matMenu"
  xPosition="after"
  class="mytoolmenu datadict"
>
  <div class="contain">
    <form class="myform">
      <div class="htitle">
        <p class="FxLine">Choose dictionary</p>
      </div>
      <!-- <mat-form-field appearance="fill">
                <mat-label>Select</mat-label>
                <mat-select>
                    <mat-option value="yes">ABSA</mat-option>
                    <mat-option value="no">No</mat-option>
                </mat-select>
            </mat-form-field> -->

      <div class="dictionaries">
        <div class="row">
          <div class="col-6 col-lg-3">
           
            <button
              mat-stroked-button
              (click)="selectDictionary('100')"
              [class]="selected_dictionary === '100' ? 'active' : ''"
            >
              <div class="block">
                <div class="icon"></div>
                <p>Spreadsheet</p>
              </div>
            </button>
          </div>
          <div class="col-6 col-lg-3">
            <button
              mat-stroked-button
              (click)="selectDictionary('3')"
              [class]="selected_dictionary === '3' ? 'active' : ''"
            >
              <div class="block">
                <div class="icon"></div>
                <p>Microalert</p>
              </div>
            </button>
          </div>
          <div class="col-6 col-lg-3">
            <button
              mat-stroked-button
              (click)="selectDictionary('31')"
              [class]="selected_dictionary === '31' ? 'active' : ''"
            >
              <div class="block">
                <div class="icon"></div>
                <p>Standard Bank 1</p>
              </div>
            </button>
          </div>
          <div class="col-6 col-lg-3">
            <button
              mat-stroked-button
              (click)="selectDictionary('30')"
              [class]="selected_dictionary === '30' ? 'active' : ''"
            >
              <div class="block">
                <div class="icon"></div>
                <p>Standard Bank 2</p>
              </div>
            </button>
          </div>
          
          <div class="col-6 col-lg-3">
            <button
              mat-stroked-button
              (click)="selectDictionary('21')"
              [class]="selected_dictionary === '21' ? 'active' : ''"
            >
              <div class="block">
                <div class="icon"></div>
                <p>Nedfleet 1</p>
              </div>
            </button>
          </div>
          <div class="col-6 col-lg-3">
            <button
              mat-stroked-button
              (click)="selectDictionary('23')"
              [class]="selected_dictionary === '23' ? 'active' : ''"
            >
              <div class="block">
                <div class="icon"></div>
                <p>Nedfleet 2</p>
              </div>
            </button>
          </div>
          <div class="col-6 col-lg-3">
            <button
              mat-stroked-button
              (click)="selectDictionary('10')"
              [class]="selected_dictionary === '10' ? 'active' : ''"
            >
              <div class="block">
                <div class="icon"></div>
                <p>Engen</p>
              </div>
            </button>
          </div>
          <div class="col-6 col-lg-3">
            <button mat-stroked-button (click)="selectDictionary('22')" [class]="selected_dictionary === '22' ? 'active' : ''" matTooltip="FN" matTooltipPosition="above">
                <div class="block">
                  <div class="icon"></div>
                  <p>TFN</p>
                </div>
            </button>
          </div>
        </div>

        <!--<button mat-stroked-button (click)="selectDictionary('4')" [class]="selected_dictionary==='4' ? 'active' : ''" matTooltip="BP MASA" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>BP MASA</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('5')" [class]="selected_dictionary==='5' ? 'active' : ''" matTooltip="CALTEX" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>CALTEX</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('6')" [class]="selected_dictionary==='6' ? 'active' : ''" matTooltip="CASH" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>CASH</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('7')" [class]="selected_dictionary==='7' ? 'active' : ''" matTooltip="COLT" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>COLT</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('8')" [class]="selected_dictionary==='8' ? 'active' : ''" matTooltip="CROSS" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>CROSS</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('9')" [class]="selected_dictionary==='9' ? 'active' : ''" matTooltip="CUSTOMER_STATEMENT" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>CUSTOMER_STATEMENT</p></div></button>
                -->
        <!--
                <button mat-stroked-button (click)="selectDictionary('11')" [class]="selected_dictionary==='11' ? 'active' : ''" matTooltip="FNB" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>FNB</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('12')" [class]="selected_dictionary==='12' ? 'active' : ''" matTooltip="FUELMANAGER DAILY EXPORT" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>FUELMANAGER DAILY EXPORT</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('13')" [class]="selected_dictionary==='13' ? 'active' : ''" matTooltip="FUEL REGISTER CONTROL SHEET" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>FUEL REGISTER CONTROL SHEET</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('14')" [class]="selected_dictionary==='14' ? 'active' : ''" matTooltip="FUEL REMOTE EXPORT 1" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>FUEL REMOTE EXPORT 1</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('15')" [class]="selected_dictionary==='15' ? 'active' : ''" matTooltip="FUEL REMOTE EXPORT 2" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>FUEL REMOTE EXPORT 2</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('16')" [class]="selected_dictionary==='16' ? 'active' : ''" matTooltip="FUELUSAGE" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>FUELUSAGE</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('17')" [class]="selected_dictionary==='17' ? 'active' : ''" matTooltip="HLU CONSUMPTION REPORT" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>HLU  CONSUMPTION REPORT</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('18')" [class]="selected_dictionary==='18' ? 'active' : ''" matTooltip="KUBANDZULTRANSA_FDIESELENQUIRY" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>KUBANDZULTRANSA_FDIESELENQUIRY</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('19')" [class]="selected_dictionary==='19' ? 'active' : ''" matTooltip="LCS TRANS129" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>LCS TRANS129</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('20')" [class]="selected_dictionary==='20' ? 'active' : ''" matTooltip="MARKET PRODUCEFUEL REPORT" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>MARKET PRODUCEFUEL REPORT</p></div></button>



                <button mat-stroked-button (click)="selectDictionary('22')" [class]="selected_dictionary==='22' ? 'active' : ''" matTooltip="NEDFLEET-MICRO STRATEGY" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>NEDFLEET-MICRO STRATEGY</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('23')" [class]="selected_dictionary==='23' ? 'active' : ''" matTooltip="NEDFLEET" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>NEDFLEET</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('24')" [class]="selected_dictionary==='24' ? 'active' : ''" matTooltip="SAPPI FILE(1)" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>SAPPI FILE(1)</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('25')" [class]="selected_dictionary==='25' ? 'active' : ''" matTooltip="SAPPI FILE(2)" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>SAPPI FILE(2)</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('26')" [class]="selected_dictionary==='26' ? 'active' : ''" matTooltip="SAPPI FILE(3)" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>SAPPI FILE(3)</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('27')" [class]="selected_dictionary==='27' ? 'active' : ''" matTooltip="SAPPI FILE(4)" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>SAPPI FILE(4)</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('28')" [class]="selected_dictionary==='28' ? 'active' : ''" matTooltip="SAPPI FILE(5)" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>SAPPI FILE(5)</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('29')" [class]="selected_dictionary==='29' ? 'active' : ''" matTooltip="SHELL" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>SHELL</p></div></button>
                -->
        <!--
                <button mat-stroked-button (click)="selectDictionary('31')" [class]="selected_dictionary==='31' ? 'active' : ''" matTooltip="TFN" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>TFN</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('32')" [class]="selected_dictionary==='32' ? 'active' : ''" matTooltip="TOKEIN DAILY FUEL" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>TOKEIN DAILY FUEL</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('33')" [class]="selected_dictionary==='33' ? 'active' : ''" matTooltip="WAO" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>WAO</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('34')" [class]="selected_dictionary==='34' ? 'active' : ''" matTooltip="WBG" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>WBG</p></div></button>
                <button mat-stroked-button (click)="selectDictionary('35')" [class]="selected_dictionary==='35' ? 'active' : ''" matTooltip="ZULTRANS" matTooltipPosition="above"><div class="block"><div class="icon"></div><p>ZULTRANS</p></div></button> -->
      </div>
      <div class="row toolfooter">
        <div class="col-12 col-md-3">
          <button
            mat-stroked-button
            (click)="selectDictionary('1')"
            [class]="selected_dictionary === '1' ? 'active' : ''"
          >
            <div class="block">
              <div class="icon"></div>
              <p>Absa</p>
            </div>
          </button>
        </div>
        <div class="col-12 col-md-3">
          <button
            mat-stroked-button
            (click)="selectDictionary('11')"
            [class]="selected_dictionary === '11' ? 'active' : ''"
          >
            <div class="block">
              <div class="icon"></div>
              <p>FNB</p>
            </div>
          </button>
        </div>
        <div class="col-12 col-md-3" (click)="$event.stopPropagation()">
          <div class="paired">
                <button
                  mat-stroked-button
                  (click)="selectDictionary('2')"
                  [class]="selected_dictionary === '2' ? 'active' : ''"
                >
                  <div class="block">
                    <div class="icon"></div>
                    <p>Wesbank 1</p>
                  </div>
                </button>
                <button
                  mat-stroked-button
                  (click)="selectDictionary('4')"
                  [class]="selected_dictionary === '4' ? 'active' : ''"
                >
                  <div class="block">
                    <div class="icon"></div>
                    <p>Wesbank 2</p>
                  </div>
                </button>
          </div>
          
        </div>
        <div class="col-12 col-md-3" (click)="$event.stopPropagation()">
          <div class="wesbank-field">
            <div
              class="inputfield"
              [class]="selected_dictionary === '2' || selected_dictionary === '4' ? 'active' : ''"
            >
              <p>Wesbank Id</p>
              <input
                type="number"
                (keyup)="validateAvisWesbankId($event.target)"
                [value]="avis_wesbank_id"
                placeholder="Ex. 12345"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-menu>
<mat-menu #assignDate="matMenu" xPosition="after" class="mytoolmenu">
  <div class="contain">
    <form class="myform">
      <div class="htitle">
        <p class="FxLine">Date Formatting</p>
      </div>
      <div class="row">
        <div class="col-12 col-lg-12" (click)="$event.stopPropagation()">
          <mat-form-field appearance="fill">
            <mat-label>Select Format</mat-label>
            <mat-select
              [value]="selected_date_format"
              (selectionChange)="selectDateFormat($event)"
            >
              <!-- <mat-option value="d m y">d m y</mat-option>
                            <mat-option value="d y m">d y m</mat-option>
                            <mat-option value="m d y">m d y</mat-option>
                            <mat-option value="m y d">m y d</mat-option>

                            <mat-option value="y d m">y d m</mat-option> -->
              <mat-option value="ddmmmyyy">dd mmm yy</mat-option>
              <mat-option value="yymmdd">yymmdd</mat-option>
              
              <mat-option value="ddmmyyyy">ddmmyyyy</mat-option>
              <mat-option value="XXXXX">XXXXX</mat-option>
              <mat-option value="yyyymmdd">yyyymmdd</mat-option>
              
            <mat-option value="yyyy/mm/dd hh:mm">yyyy/mm/dd hh:mm</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-12">
          <div class="dateformatexample">
            <div class="hd">
              <p>From</p>
              <p>To</p>
            </div>
            <div class="md">
              <p>
                {{
                  selected_date_format !== ""
                    ? selected_date_format
                    : "Select format"
                }}
              </p>
              <hr />
              <p>dd/mm/yyyy</p>
            </div>
          </div>
        </div>
        <div class="groupedbtns">
          <button
            mat-flat-button
            color="primary"
            class="commitbtn"
            (click)="DateFormat(true)"
          >
            Format Date
          </button>
          <button mat-flat-button color="warm"><p>Clear format</p></button>
        </div>
      </div>
    </form>
  </div>
</mat-menu>
<mat-menu #assignTime="matMenu" xPosition="after" class="mytoolmenu">
  <div class="contain">
    <form class="myform">
      <div class="htitle">
        <p class="FxLine">Time Formatting</p>
      </div>
      <div class="row">
        <div class="col-12 col-lg-12" (click)="$event.stopPropagation()">
          <mat-form-field appearance="fill">
            <mat-label>Select Format</mat-label>
            <mat-select
              [value]="selected_time_format"
              (selectionChange)="selectTimeFormat($event)"
            >
              <mat-option value="1330">1330</mat-option>
              <mat-option value="13:30">13:30</mat-option>
              <mat-option value="13:30:55">13:30:55</mat-option>
              <!--<mat-option value="4">1:30 PM</mat-option>
                            <mat-option value="5">1:30:55 PM</mat-option>-->
              <mat-option value="0.999999">0.999999</mat-option> 
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-12">
          <div class="dateformatexample">
            <div class="hd">
              <p>From</p>
              <p>To</p>
            </div>
            <div class="md">
              <p>
                {{
                  selected_time_format !== ""
                    ? selected_time_format
                    : "Select format"
                }}
              </p>
              <hr />
              <p>13:30</p>
            </div>
          </div>
        </div>
        <div class="groupedbtns">
          <button
            mat-flat-button
            color="primary"
            class="commitbtn"
            (click)="TimeFormat(true)"
          >
            Format Time
          </button>
          <button mat-flat-button color="warm"><p>Clear format</p></button>
        </div>
      </div>
    </form>
  </div>
</mat-menu>

<div class="confirm-modal" *ngIf="isDialogData && isDialogData.type==='import-validations'">
  <div class="background"  (click)="closeDialog()"></div>
  <div class="contain">
      <div class="header">
        <div class="icon">
          <i class="fa-solid fa-circle-exclamation"></i>
        </div>
      </div>
      <div class="body">
        <div class="htitle"><p>{{isDialogData.heading}}</p></div>
        <div class="points">
          <p>{{isDialogData.message}}</p>
        </div>
        <div class="btns">
          <button mat-flat-button color="primary" (click)="closeDialog()"><p>Okay</p></button>
          <button mat-stroked-button color="warm" (click)="closeDialog()"><p>Close</p></button>
        </div>
      </div>
  </div>
</div>

<div class="confirm-modal" *ngIf="isDialogData && isDialogData.type==='add-new-layout'">
  <div class="background"  (click)="closeDialog()"></div>
  <div class="contain">
      <div class="header">
        <div class="heading">
          <p>Add New Layout</p>
        </div>
        <div class="icon">
          <button mat-flat-button color="primary" (click)="closeDialog()">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
      <div class="body">
        <div class="layouts">
          <div class="htitle"><p>The following parameters will be stored.</p></div>
          <div class="points">
            <p>
              Time Format:  <ng-container *ngIf="selected_time_format!==''">{{selected_time_format}}</ng-container><ng-container *ngIf="selected_time_format===''"><span class="active">Not formating</span></ng-container><br/>
              Date Format:  <ng-container *ngIf="selected_date_format!==''">{{selected_date_format}}</ng-container><ng-container *ngIf="selected_date_format===''"><span class="active">Not formating</span></ng-container><br/>
              Skip First Row:  <ng-container *ngIf="isSkipFirstRow==='1'">Yes</ng-container><ng-container *ngIf="isSkipFirstRow==='0'"><span class="active">Not skipping</span></ng-container><br/>
              Custom Headers:  <ng-container *ngIf="getCustomHeaders().length>0">
                <ng-container *ngFor="let r of getCustomHeaders();let i = index">
                    {{r}}<ng-container *ngIf="(i+1) < getCustomHeaders().length">,</ng-container>
              </ng-container>
              </ng-container><ng-container *ngIf="getCustomHeaders().length<1"><span class="active">Not assigning</span></ng-container><br/>
            </p>
            
            <hr/>
          </div>
          <div class="htitle"><p>Name Your Layout</p></div>
          <input type="text" placeholder="Type here" (keyup)="handle_layout_name($event.target)"/>
        </div>
        

        <div class="btns">
          <button mat-flat-button color="primary" (click)="saveLayout()"><p>Save Layout</p></button>
        </div>
      </div>
  </div>
</div>

<div class="confirm-modal" *ngIf="isDialogData && isDialogData.type==='importing-warnings'">
  <div class="background"  (click)="closeDialog()"></div>
  <div class="contain">
      <div class="header">
        <div class="icon">
          <i class="fa-solid fa-circle-exclamation"></i>
        </div>
      </div>
      <div class="body">
        <div class="htitle"><p>See the following warnings:</p></div>
        <div class="points">
          <p class="errorList">
            <ng-container *ngFor="let r of isDialogData.errorList;let i = index">
              <ng-container *ngIf="r.Error.includes('Ignored')"><span class="active">Line {{r.Line}}: {{r.Error}}</span><br/></ng-container>
              <ng-container *ngIf="!r.Error.includes('Ignored')">Line {{r.Line}}: {{r.Error}}<br/></ng-container>
            </ng-container>
          </p>
        </div>
        <div class="btns">
          <button mat-flat-button color="primary" (click)="Import('force-import')"><p>I undertand, proceed anyway</p></button>
          <button mat-stroked-button color="warm" (click)="closeDialog()"><p>Close</p></button>
        </div>
      </div>
  </div>
</div>
