import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';
import { UserService } from 'src/app/services/users/user.service';
import { AssignUserCompaniesComponent } from '../assign-user-companies/assign-user-companies.component';
import {RoleService} from "../../../services/roles/role.service";

@Component({
  selector: 'app-user-config',
  templateUrl: './role-config.component.html',
  styleUrls: ['./role-config.component.css']
})
export class RoleConfigComponent {
  addDriverForm: any;
  constructor(
    public dialogRef: MatDialogRef<RoleConfigComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackbar: SnackbarsService,
    private _roleService: RoleService
  ) {}

  isValidEmail(email:any){
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  onChangeValue(fieldName: string, value: any) {
    this.data.form[fieldName] = value;
  }
  isValidForm(){
    if(this.data.form.role.length < 1){
      (document.querySelector("input[name=role]") as HTMLInputElement).focus();
      return false;
    }
    return true;
  }

  response = '';
  response_data:any = null;
  createRole() {
    if(!this.isValidForm()){
      return;
    }
    this.response = 'loading';
    this.response_data = null;

    let payload = this.data.form;
    this._roleService.createRole(payload).subscribe({
      next: (data: any) => {
        if (data.status.message == "success") {
          this.response = 'success';
          this._snackbar.success('Successfully created the role '+ payload.role);
          this.dialogRef.close({event:'create', data: payload});
        } else {
          this.response = 'network_err';
          if (data.status.message.includes('Role already exists')) {
            this._snackbar.error('Role already exists');
            (document.querySelector("input[name=role]") as HTMLInputElement).focus();
          } else {
            this._snackbar.error(data.status.message);
          }
        }
      },
      error: (err: any) => {
        this.response = 'network_err';
        this._snackbar.error(err.message);
      },
    });
  }

  updateRole() {
    if(!this.isValidForm()){
      return;
    }

    this.response = 'loading';
    this.response_data = null;

    let payload = this.data.form;
    this._roleService.updateRole(payload).subscribe({
      next: (data: any) => {
        if (data.status.message == "success") {
          this.response = 'success';
          this._snackbar.success('Successfully updated the role '+ payload.role);
          this.dialogRef.close({event:'update', data: payload});
        } else {
          this.response = 'network_err';
          if (data.status.message.includes('Role already exists')) {
            this._snackbar.error('Role already exists');
            (document.querySelector("input[name=USERNAME]") as HTMLInputElement).focus();
          } else {
            this._snackbar.error(data.status.message);
          }
        }
      },
      error: (err: any) => {
        this.response = 'network_err';
        this._snackbar.error(err.message);
      },
    });
  }

  closeModal(): void {
    this.dialogRef.close({event:'close'});
  }
}
