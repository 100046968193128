import { Injectable } from '@angular/core';
import { Company } from '../../models/company';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import {AuthService} from "../auth/auth.service";
const CACHE_KEY = {
  httpUserCompaniesCache: 'httpUserCompaniesCache',
  httpAllCompaniesCache: 'httpAllCompaniesCache',
  httpAwaitingVehiclesCache: 'httpAwaitingVehiclesCache',
  httpCompanyCustomersCache: 'httpCompanyCustomersCache',
  httpCompanyKPICache: 'httpCompanyKPICache',
};
@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private http: HttpClient,
    private _authService: AuthService
  ) { }
  private service_base = this._authService.service_base;

  private headers = new HttpHeaders({
    'x-Gateway-APIKey': 'c2c22314-ca55-4b42-b472-494a6c180706',
    'Access-Control-Allow-Origin': '*',
  });

  insertCompany(data: any): Observable<any> {
    const url = this.service_base + "company/store";
    const result = this.http.post<any>(url, data, {
      headers: this.headers,
    });
    return result;
  }

  updateCompany(data: any): Observable<any> {
    const url = this.service_base + "company/update";
    const result = this.http.post<any>(url, data, {
      headers: this.headers,
    });
    return result;
  }

  deleteCompany(companyId: any): Observable<any> {
    let data = {
      "COMPANYID": companyId
    }
    const url = this.service_base + "company/deleteCompany/";
    const result = this.http.post<any>(url, data, {
      headers: this.headers,
    });
    return result;
  }

  getAllCompanies(){
    let url = this.service_base + 'company/getAllCompanies';
    return this.http.get<any[]>(url, {
      headers: this.headers,
    });
  }
  getUserCompanies(user_uuid: any) {
    const url = this.service_base + `company/get-user-companies/${user_uuid}`;
    const result = this.http.get<any>(url, {
      headers: this.headers,
    });
    return result;

  }
  getCompanyInfo(companyId: string): Observable<any> {
    let url = this.service_base + 'restv2/getCustomerByCompanyID?';
    return this.http.get<any[]>(url.concat('id=').concat(companyId), {
      headers: this.headers,
    });
  }
  getCompanyName() {
    return sessionStorage.getItem('company_selected');
  }
  getCompanyId() {
    return sessionStorage.getItem('company_id');
  }

  getAvisWesbankId() {
    return sessionStorage.getItem('wesbank_id');
  }

  getAllCustomers(): Observable<any> {
    let url = this.service_base + 'getAllCustomers';
    return this.http.get<any>(url, {
      headers: this.headers,
    });
  }

  getCompanyKPI(id: any): Observable<any> {
    let url = this.service_base + 'getCompanyIdByUserId?USERID=' + id;
    return this.http.get<any>(url, {
      headers: this.headers,
    });
  }

  getFromTowns(): Observable<any> {
    let url = this.service_base + 'getDistanceTownFrom';
    return this.http.get<any>(url, {
      headers: this.headers,
    });
  }

  getToTowns(): Observable<any> {
    let url = this.service_base + 'getDistanceTownTo';
    return this.http.get<any>(url, {
      headers: this.headers,
    });
  }

  getAllDistances(): Observable<any> {
    let url = this.service_base + 'company/getAllDistances';
    return this.http.get<any>(url, {
      headers: this.headers,
    });
  }



  getCompanyCustomers(companyId: string): Observable<any> {
    let url = this.service_base + 'company/getCompanyCustomers/';
    return this.http.get<any[]>(url.concat(companyId), {
      headers: this.headers,
    });
  }
  //Company Customer Emails
  getCustomerEmailAddresses(id: any) {
    let url = this.service_base + 'company/getEmailAddresses/' + id;
    return this.http.get<any>(url, {
      headers: this.headers,
    });
  }

  //Get Company Accounts
  getCompanyAccounts(accountId: string): Observable<any> {
    let url = this.service_base + 'company/getCompanyAccounts/';
    return this.http.get<any[]>(url.concat(accountId), {
      headers: this.headers,
    });
  }

  //Get Company Accounts
  getCompanyCostCentres(costcentreId: string): Observable<any> {
    let url = this.service_base + 'company/getCompanyCostCentres/';
    return this.http.get<any[]>(url.concat(costcentreId), {
      headers: this.headers,
    });
  }

  //Company Customer Accounts Emails
  getAccountEmailAddresses(account_id: any) {
    let url = this.service_base + 'company/getAccountEmailAddresses/' + account_id;
    return this.http.get<any>(url, {
      headers: this.headers,
    });
  }

  //Company Cost Centre  Emails
  getCostCentreEmailAddresses(cost_centre_id: any) {
    let url = this.service_base + 'company/getCostCentreEmailAddresses/' + cost_centre_id;
    return this.http.get<any>(url, {
      headers: this.headers,
    });
  }

  //Company Customer Division Emails
  getDivisionEmailAddresses(division_id: any) {
    let url = this.service_base + 'company/getDivisionEmailAddresses/' + division_id;
    return this.http.get<any>(url, {
      headers: this.headers,
    });
  }


}
