<div class="myModal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p>IFM Reports Prepare</p>
      <p>Add description here</p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>

  <div class="modal-contain">
    <form class="myform">
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="fill">
                <mat-label>Start Date</mat-label>
                <input
                  (keydown)="$event.preventDefault()"
                  placeholder="Ex. 2020-02-20"
                  matInput
                  [matDatepicker]="StartDatePicker"
                  name="StartDatePicker"
                  value="2024-01-01"
                />
                <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="StartDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #StartDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="fill">
                <mat-label>Stop Date</mat-label>
                <input
                  (keydown)="$event.preventDefault()"
                  placeholder="Ex. 2020-02-20"
                  matInput
                  [matDatepicker]="EndDatePicker"
                  value="2024-01-01"
                  name="EndDatePicker"
                />
                <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="EndDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #EndDatePicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-12">
              <button mat-stroked-button>
                <p>Calculate Totals</p>
              </button>
            </div>
            <div class="col-12">
              <mat-checkbox color="primary"
                >Update Accumalitive Totals</mat-checkbox
              >
            </div>
            <div class="col-12">
              <button mat-stroked-button>
                <p>Export Q's</p>
              </button>
            </div>

            <div class="col-12">
              <mat-form-field appearance="fill">
                <mat-label>Burst Date</mat-label>
                <input
                  (keydown)="$event.preventDefault()"
                  placeholder="Ex. 2020-02-20"
                  matInput
                  [matDatepicker]="BurstDatePicker"
                  name="BurstDatePicker"
                />
                <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="BurstDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #BurstDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-6">
              <button mat-stroked-button>
                <div class="details">
                  <div class="circle bg-color-2"></div>
                  <p>Burst Report</p>
                </div>
              </button>
            </div>
            <div class="col-6">
              <button mat-stroked-button>
                <p>Cancel Burst</p>
              </button>
            </div>
            <div class="col-12">
              <button mat-stroked-button>
                <p>Export Errors</p>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-9">
          <div class="modal-tbl">
            <div class="mytable">
              <table
                mat-table
                [dataSource]="dataSource"
                class="mat-elevation-z8"
              >
                <ng-container matColumnDef="REGISTRATION_NO">
                  <th mat-header-cell *matHeaderCellDef>REGISTRATION NO</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.REGISTRATION_NO }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="DATE">
                  <th mat-header-cell *matHeaderCellDef>DATE</th>
                  <td mat-cell *matCellDef="let row">{{ row.DATE }}</td>
                </ng-container>

                <ng-container matColumnDef="TIME">
                  <th mat-header-cell *matHeaderCellDef>TIME</th>
                  <td mat-cell *matCellDef="let row">{{ row.TIME }}</td>
                </ng-container>
                <ng-container matColumnDef="ERROR">
                  <th mat-header-cell *matHeaderCellDef>ERROR</th>
                  <td mat-cell *matCellDef="let row">{{ row.ERROR }}</td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
