import { CompanyService } from './../../../services/companies/company.service';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransactionService } from 'src/app/services/transactions/transaction.service';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { scan } from 'rxjs';

@Component({
  selector: 'app-file-import',
  templateUrl: './file-import.component.html',
  styleUrls: ['./file-import.component.css'],
})
export class FileImportComponent {
  selectedDictionary: any = '';
  constructor(
    public dialogRef: MatDialogRef<FileImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private _snackbar: MatSnackBar,
    private _companyServ: CompanyService,
    private _tranServ: TransactionService
  ) {
    this.getDataDictionaries();
  }
  @ViewChild('dictionary') _dictionary: any;

  ngOnInit() {
    this._dictionary.control.markAsTouched();
  }
  closeModal(data?: any): void {
    this.dialogRef.close({ event: data });
  }
  openSnackBar(message: string, action: string, type: string) {
    this._snackbar.open(message, action, { duration: 5000, panelClass: type });
  }

  public files: NgxFileDropEntry[] = [];
  activeFile: any;
  dictionaries: any = [];

  fileError: any = '';
  validFilesExtensions = [
    '.xls',
    '.xlsx',
    '.csv',
    '.txt',
    '.txn',
    '.dat',
    '.TXN',
  ];
  uploadedFile: any = null;

  StoreUploadedFiles() {}

  uploadFiles() {
    if (!this.selectedDictionary) {
      this.openSnackBar('Please select Data Dictionary ', 'OK', 'error');
      return;
    }
    let formData = new FormData();
    var fileExtension = '.' + this.uploadedFile.name.split('.').pop();
    let companyName = this._companyServ.getCompanyName();
    let fileName = this.uploadedFile.name.split('.');
    let uploadTime = new Date();

    let refFileName: any;

    let timeValue =
      uploadTime.getFullYear() +
      '_' +
      (uploadTime.getMonth() + 1) +
      '_' +
      uploadTime.getDate() +
      '_' +
      uploadTime.getHours() +
      uploadTime.getMinutes() +
      uploadTime.getSeconds();
    fileName[0] = fileName[0].replaceAll(' ', '');

    refFileName =
      this._companyServ.getCompanyId() +
      '_' +
      this.selectedDictionary +
      '_' +
      companyName?.replaceAll(' ', '') +
      '_' +
      fileName[0].replaceAll(/[^a-zA-Z ]/g, '') +
      '_' +
      timeValue?.replaceAll(' ', '') +
      fileExtension;

    formData.append('file', this.uploadedFile, refFileName);

    if (this.uploadedFile) {
      return this.http.post('/assets/data-files/', formData).subscribe(
        (response) => {
          if (response == 'File already exists => ') {
            this.openSnackBar(response + '', 'OK', 'error');
            return;
          } else {
            this.openSnackBar(response + '', 'OK', 'success');
            formData = new FormData();
            this.closeModal(true);
          }
        },
        (error) => {
          this.openSnackBar(error.message, 'OK', 'error');
        }
      );
    } else {
      this.openSnackBar('Please select file(s)  to upload! ', 'OK', 'error');
      return;
    }
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    this.fileError = '';
    this.uploadedFile = null;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          // Here you can access the real file
          // console.log(droppedFile.relativePath, file);

          const FileName = droppedFile.relativePath;
          // console.log(fileEntry);
          this.uploadedFile = file;

          if (FileName.length > 0) {
            let blnValid = false;
            for (let j = 0; j < this.validFilesExtensions.length; j++) {
              const sCurExtension = this.validFilesExtensions[j];
              if (
                FileName.substr(
                  FileName.length - sCurExtension.length,
                  sCurExtension.length
                ).toLowerCase() === sCurExtension.toLowerCase()
              ) {
                blnValid = true;
                break;
              }
            }

            if (!blnValid) {
              this.fileError = 'unsupported_file';
            } else {
              this.fileError = 'success';
              this.uploadedFile = file;
            }
          } else {
            this.fileError = 'unsupported_file';
          }
        });
      } else {
        // It was a di;rectory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  getDataDictionaries() {
    try {
      this._tranServ.getDataDitionaries().subscribe({
        next: (response:any) => {
          this.dictionaries = response.results;
        },
      });
    } catch (error) {}
  }
}
