import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { CompanyService } from 'src/app/services/companies/company.service';
import { UserService } from 'src/app/services/users/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-assign-user-companies',
  templateUrl: './assign-user-companies.component.html',
  styleUrls: ['./assign-user-companies.component.css']
})
export class AssignUserCompaniesComponent {
  constructor(
    public dialogRef: MatDialogRef<AssignUserCompaniesComponent>,
    private _companyServ: CompanyService,
    private _userServ: UserService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: type,
      // horizontalPosition: "center",
      // verticalPosition: "top",
    });
  }
  // Fetch All Companies
  fetch_all_companies_response = 'loading';
  all_companies: any = [];
  raw_all_companies: any = [];
  fetch_all_companies() {
    this.fetch_all_companies_response = 'loading';
    this._companyServ.getAllCompanies().subscribe({
        next: (data: any) => {
          if (data.results && data.results.length > 0) {
            this.fetch_all_companies_response = 'success';
            this.all_companies = data.results;
            this.raw_all_companies = data.results;
          } else {
            this.fetch_all_companies_response = 'no_results';
            this.openSnackBar(
              'No Results',
              'Retry',
              'error'
            );
          }
        },
        error: (err: any) => {
          this.fetch_all_companies_response = 'network_err';
          this.openSnackBar(
            err.message,
            'Retry',
            'error'
          );
        },
      });
  }

  fetch_user_companies_response = 'loading';
  user_companies: any = [];
  raw_user_companies: any = [];

  fetch_user_companies() {
    this.fetch_user_companies_response = 'loading';
    this._companyServ.getUserCompanies(this.data.USERID).subscribe({
        next: (data: any) => {

          if (data.results && data.results.length > 0) {
            this.fetch_user_companies_response = 'success';
            const newdata = [];
            for (var i = 0; i < data.results.length; i++) {
              newdata.push({
                "company_id": Number(data.results[i].company_id),
                "COMPANYNAME": data.results[i].COMPANYNAME,
              });
            }

            this.user_companies = newdata;
            this.raw_user_companies = newdata;

          }else{
            this.fetch_user_companies_response = 'no_results';
            if(data.status.message == 'ConnectionError'){
              this.fetch_user_companies_response = 'network_err';
            }
          }
        },
        error: (err: any) => {
          this.fetch_user_companies_response = 'network_err';
          this.openSnackBar(
            'Ops, Check your network connectivity',
            'Okay',
            'error'
          );
        },
      });
  }

  checkAssigned(companyId:any){
    const found = this.user_companies.some((el:any) => el.company_id === companyId);
    if(found){
      return true;
    }else{
      return false
    }
  }
  unAssign(index:any, r:any){
    this.user_companies.splice(index, 1);
    const payload = {
      "USERID": this.data.USERID,
      "USERNAME": this.data.USERNAME,
      "company_id": r.company_id,
      "COMPANYNAME": r.COMPANYNAME,
    }

    this._userServ.deleteUserCompany(payload).subscribe({
      next: (data: any) => {
        if(data.status.message == "success"){
          this.openSnackBar('Successfully unssigned '+ payload.COMPANYNAME, 'Okay', 'success');
        }else{
            this.openSnackBar(data.status.message, 'Okay', 'error');
        }
      },
      error: (err: any) => {
        this.openSnackBar(
          err.message,
          'Okay',
          'error'
        );
      },
    });

  }

  Assign(r:any) {
    if(this.user_companies.length>0){
      this.fetch_user_companies_response = 'success';
    }else{
      this.fetch_user_companies_response = 'no_results';
    }

    this.user_companies.push(r);
    const payload = {
      "USERID": this.data.USERID,
      "USERNAME": this.data.USERNAME,
      "company_id": r.company_id,
      "COMPANYNAME": r.COMPANYNAME,
    }

    this._userServ.setUserCompany(payload).subscribe({
      next: (data: any) => {
        if(data.status.message == "success"){
          this.openSnackBar('Successfully assigned '+ payload.COMPANYNAME, 'Okay', 'success');
        }else{
          if(data.status.message.includes('Company already assigned')){
            this.openSnackBar('Company already assigned', 'Okay', 'error');
          }else{
            this.openSnackBar(data.status.message, 'Okay', 'error');
          }
        }
      },
      error: (err: any) => {
        this.openSnackBar(
          'Failed to assign, something went wrong.',
          'Okay',
          'error'
        );
      },
    });

  }

  all_company_string = "";
  search_all_companies(event: any) {
    var value = event.value.toLowerCase();
    this.all_company_string = value;
  }

  assigned_companies_search = "";
  search_assigned_companies(event: any) {
    var value = event.value.toLowerCase();
    this.assigned_companies_search = value;
  }

  ngOnInit() {
    this.fetch_all_companies()
    this.fetch_user_companies()
  }
}
