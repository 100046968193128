<div class="myModal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p>Update Driver</p>
      <p>Driver Information</p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>

  <div class="modal-contain">
    <form class="myform" [formGroup]="updateDriverForm" (ngSubmit)="onSubmit()">
      <!-- <div class="htitle">
                <p class="FxLine">
                    Vehicle Information
                </p>
            </div> -->
      <div class="row">
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Driver Name</mat-label>
            <input
              matInput
              formControlName="DRIVERNAME"
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>AS400 DRIVERID</mat-label>
            <input
              matInput
              formControlName="AS400DRIVERID"
              type="number"
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>EMPLOYEE NUMBER</mat-label>
            <input
              matInput
              formControlName="EMPLOYEENUMBER"
              value=""
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>ID NUMBER</mat-label>
            <input
              matInput
              formControlName="IDNUMBER"
              value=""
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>PASSPORT NUMBER</mat-label>
            <input
              matInput
              formControlName="PASSPORTNUMBER"
              value=""
              placeholder="Ex. 1234"
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>ACTIVE</mat-label>
            <input
              matInput
              formControlName="ISACTIVE"
              value="1"
              placeholder=""
            />
          </mat-form-field>
        </div>
        <div class="col-xs-12">
          <button class="mybtn" mat-flat-button color="primary" type="submit">
            Update Driver
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
