<div class="myModal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p>Combine Transactions</p>
      <p>Merge</p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>

  <div class="modal-contain">
    <form class="myform">
      <p><b>Selected transactions will be merged in to one transaction</b></p>
      <div class="mycombine">
        <div class="lft">
          <div class="contain">
            <div class="mychip" *ngFor="let r of dataSource" [matTooltip]="r">
              <p>Reg: {{ r.REGISTRATIONNO }}</p>
            </div>
          </div>
        </div>
        <div class="rgt">
          <div class="contain">
            <p>New Transaction Object</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <button
            class="mybtn"
            (click)="onSubmit()"
            type="button"
            mat-flat-button
            color="primary"
          >
            COMBINE
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<app-confirmation-dialog
  #confirmationModal
  (newConfirmationEvent)="getConfirmationValue($event)"
  [modalStyle]="modalStyle"
  [modalTitle]="modalTitle"
  [modalBody]="modalBody"
  [modalButtonColor]="modalButtonColor"
>
</app-confirmation-dialog>
