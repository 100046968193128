<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="lft">
        <p>Select Company</p>
        <p>Select company you would like to work with</p>
      </div>
      <div class="rgt">
        <button mat-flat-button (click)="closeModal()" >
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>

    <div class="modal-contain">
      <form class="myform">

        <div class="select-company-main">
            <div class="contain">
              <div class="myloader" *ngIf="fetch_response=='loading'">
                <div class="wrapp">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                  <p>Please wait while fetching companies</p>
                </div>
              </div>

            <div *ngIf="fetch_response=='success'">
                <div class="searchbox" >
                    <i class="fa-solid fa-magnifying-glass"></i>
                    <input type="text" (keyup)="handle_search($event.target)" placeholder="Search something here"/>
                </div>

                <div class="cardscontainer">
                    <div class="mycard-01" *ngFor="let r of fetch_data" [ngClass]="selected_company_name === r.company_name ? 'active' : ''"  (click)="selectCompany(r)">
                        <div class="lft">
                          <mat-icon>business</mat-icon>
                          <div class="titles">
                            <p>{{r.company_name }}</p>
                            <p><i class=""></i></p>
                          </div>
                        </div>
                        <div class="rgt">
                          <button mat-flat-button class="" color="primary">Select</button>
                        </div>
                      </div>
                    <p class="nomore">No more results</p>
                </div>
            </div>

            <div class="myfeedback" *ngIf="fetch_response=='network_err'">
                <div class="wrapp">
                    <mat-icon class="error">signal_cellular_off</mat-icon>
                    <p>Ops, Check your internet connectivity.</p>
                    <div class="btns">
                        <button mat-flat-button class="" (click)="fetch_companies()" color="accent">Retry</button>
                        <button mat-flat-button class="" (click)="closeModal()" color="primary">Close</button>
                    </div>
                </div>
            </div>

            <div class="myfeedback" *ngIf="fetch_response=='no_results'">
                <div class="wrapp">
                    <mat-icon class="error">report_problem</mat-icon>
                    <p>No results, you do not have companies.</p>
                    <div class="btns">
                        <button mat-flat-button class="" (click)="fetch_companies()" color="accent">Retry</button>
                        <button mat-flat-button class="" (click)="closeModal()" color="primary">Close</button>
                    </div>
                </div>
            </div>
            </div>
        </div>



      </form>
    </div>
  </div>
