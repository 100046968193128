import { Component } from '@angular/core';
import { CompanyService } from './services/companies/company.service';
import { environment } from './../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    private companyServ: CompanyService,
  ) {
    console.log(environment.production); // Logs false for development environment

  }
  ngOnInit() {
    if(this.companyServ.getCompanyName()){
      document.title = "IFM - "+ this.companyServ.getCompanyName()!;
    }else{
      document.title = "IFM";
    }
  }


}
