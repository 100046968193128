import {Component, Inject, ViewChild} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import {MatDialog, MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScorecardParamaterConfigComponent } from './scorecard-paramater-config/scorecard-paramater-config.component';
@Component({
  selector: 'app-scorecard-parameters',
  templateUrl: './scorecard-parameters.component.html',
  styleUrls: ['./scorecard-parameters.component.css']
})
export class ScorecardParametersComponent {

  constructor(
    public dialogRef: MatDialogRef<ScorecardParametersComponent>,
    private ReportsService: ReportsService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, { duration: 5000, panelClass: type });
  }
  closeModal(): void {
    this.dialogRef.close();
  }

  // Mark Record
  selected_records: any[] = [];

  markRecord(row: any) {
    var isInArray = this.selected_records.indexOf(row) !== -1;
    if (isInArray) {
      this.selected_records = this.selected_records.filter(function (el) {
        return el.company_id != row.company_id;
      });
    } else {
      this.selected_records.push(row);
    }
  }

  //Left Click Record
  ClickRow(event: any, row: any) {
    if (event.ctrlKey) {
      this.markRecord(row);
    } else {
      if (this.selected_records.length > 1) {
        this.selected_records = [];
      } else {
        this.selected_records.push(row);
        this.openScoreCardParamaterConfig('update')
      }
    }
  }

  //Right Click Record
  // we create an object that contains coordinates
  menuTopLeftPosition = { x: 0, y: 0 };
  @ViewChild('MoreBillingMenu', { static: true }) MoreBillingMenu: any =
    MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  onRightClick(event: MouseEvent, row: any) {
    event.preventDefault();
    this.menuTopLeftPosition.x = event.clientX;
    this.menuTopLeftPosition.y = event.clientY;
    this.MoreBillingMenu.openMenu();

    if (this.selected_records.length < 2) {
      this.selected_records = [];
      this.selected_records.push(row);
    } else {
      if (this.selected_records.indexOf(row) == -1) {
        this.selected_records = [];
        this.selected_records.push(row);
      }
    }
  }

  openScoreCardParamaterConfig(action:string) {
    let data = {
      "RVScorecardId": this.selected_records.length>0?this.selected_records[0].RVScorecardId:'',
      "CompanyId": this.selected_records.length>0?this.selected_records[0].CompanyId:'',
      "CompanyName": this.selected_records.length>0?this.selected_records[0].CompanyName:'',
      "AS400CUSTOMERID": this.selected_records.length>0?this.selected_records[0].AS400CUSTOMERID:'',
      "AS400ACCOUNTID": this.selected_records.length>0?this.selected_records[0].AS400ACCOUNTID:'',
      "AS400COSTCENTREID": this.selected_records.length>0?this.selected_records[0].AS400COSTCENTREID:'',
      "DivisionName": this.selected_records.length>0?this.selected_records[0].DivisionName:'',
      "AS400MVA": this.selected_records.length>0?this.selected_records[0].AS400MVA:'',
      "IfmId": this.selected_records.length>0?this.selected_records[0].IfmId:'',
      "Level123456": this.selected_records.length>0?this.selected_records[0].Level123456:'',
      "NormalWeekend": this.selected_records.length>0?this.selected_records[0].NormalWeekend:'',
      "ExtendedWeekend": this.selected_records.length>0?this.selected_records[0].ExtendedWeekend:'',
      "MonthlyLitresSet": this.selected_records.length>0?this.selected_records[0].MonthlyLitresSet:'',
      "FS_OutOfBounds": this.selected_records.length>0?this.selected_records[0].FS_OutOfBounds:'',
      "SameDayRefill": this.selected_records.length>0?this.selected_records[0].SameDayRefill:'',
      "TankCapExceed": this.selected_records.length>0?this.selected_records[0].TankCapExceed:'',
      "DriverResponse": this.selected_records.length>0?this.selected_records[0].DriverResponse:'',
      "MonthlyKmSet": this.selected_records.length>0?this.selected_records[0].MonthlyKmSet:'',
      "CashTrans": this.selected_records.length>0?this.selected_records[0].CashTrans:'',
      "LossesPer": this.selected_records.length>0?this.selected_records[0].LossesPer:'',
      "NormalWeekendPer": this.selected_records.length>0?this.selected_records[0].NormalWeekendPer:'',
      "ExtendedWeekendPer": this.selected_records.length>0?this.selected_records[0].ExtendedWeekendPer:'',
      "MonthlyLitresSetPer": this.selected_records.length>0?this.selected_records[0].MonthlyLitresSetPer:'',
      "FS_OutOfBoundsPer": this.selected_records.length>0?this.selected_records[0].FS_OutOfBoundsPer:'',
      "SameDayRefillPer": this.selected_records.length>0?this.selected_records[0].SameDayRefillPer:'',
      "TankCapExceedPer": this.selected_records.length>0?this.selected_records[0].TankCapExceedPer:'',
      "DriverResponsePer": this.selected_records.length>0?this.selected_records[0].DriverResponsePer:'',
      "MonthlyKmSetPer": this.selected_records.length>0?this.selected_records[0].MonthlyKmSetPer:'',
      "CashTransPer": this.selected_records.length>0?this.selected_records[0].CashTransPer:'',
      "MaxLitres": this.selected_records.length>0?this.selected_records[0].MaxLitres:'',
      "MaxKM": this.selected_records.length>0?this.selected_records[0].MonthlyKmSetPer:''
    }
    this.dialog.open(ScorecardParamaterConfigComponent, {
      panelClass: 'modal-md',
      data: {
        action: action,
        form: data
      }
    });
  }

    tab = '1';
    selectTab(value: any) {
      this.tab = value;
      this.FetchScoreCards(value)
    }

    displayedColumns = [
      'CompanyId',
      'CompanyName',
      'AS400CUSTOMERID',
      'AS400ACCOUNTID',
      'AS400COSTCENTREID',
      'DivisionName',
      'AS400MVA',
      'IfmId',
      'Level123456',
      'NormalWeekend',
      'ExtendedWeekend',
      'MonthlyLitresSet',
      'FS_OutOfBounds',
      'SameDayRefill',
      'TankCapExceed',
      'DriverResponse',
      'MonthlyKmSet',
      'CashTrans',
      'LossesPer',
      'NormalWeekendPer',
      'ExtendedWeekendPer',
      'MonthlyLitresSetPer',
      'FS_OutOfBoundsPer',
      'SameDayRefillPer',
      'TankCapExceedPer',
      'DriverResponsePer',
      'MonthlyKmSetPer',
      'CashTransPer',
      'MaxLitres',
      'MaxKM',
    ];

    dataSource = [];

    response = '';
    FetchScoreCards(level: string) {
      this.response = 'loading';
      this.ReportsService.getScoreCardParametersByLevel(level).subscribe({
        next: (data: any) => {
          if (data && data.length > 0) {
            this.response = 'success';
            this.dataSource = data;
          } else {
            this.response = 'no_results';
            this.openSnackBar('No results', 'Okay', 'success');
          }
        },
        error: (error:any) => {
          this.openSnackBar('Check internet connectivity', 'Okay', 'error');
          this.response = 'network_err';
        },
      });
    }

    ngOnInit() {
      this.FetchScoreCards('1')
    }
}
