import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyService } from '../companies/company.service';
import { Observable } from 'rxjs';
import {AuthService} from "../auth/auth.service";
import {ViewChild} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class FuelStationService {
  constructor(
    private http: HttpClient,
    private _companyServ: CompanyService,
    private _authService: AuthService
  ) {}

  public service_base = this._authService.service_base;

  private headers = new HttpHeaders({
    'x-Gateway-APIKey': 'c2c22314-ca55-4b42-b472-494a6c180706',
    'Access-Control-Allow-Origin': '*',
  });
  getAllFuelStations(): Observable<any> {
    let url = '/restv2/getAllFuelStations';
    return this.http.get<any[]>(url);
  }

  getCompanyFuelStations(id: string): Observable<any> {
    let url = this.service_base + `fuel-station/get-fuel-stations-by-company_uuid/${id}`;
    return this.http.get<any[]>(url, {
      headers: this.headers,
    });
  }

  addFuelStation(data: any): Observable<any> {
    let url = this.service_base + 'fuel-station/store';
    return this.http.post<any>(url, { data }, {
      headers: this.headers,
    });
  }

  updateFuelStation(data: any): Observable<any> {
    let url = this.service_base + 'fuel-station/update';
    return this.http.post<any>(
      url,
      {
        data
      },
      {
        headers: this.headers,
      }
    );
  }

  deleteFuelStation(id: string): Observable<any> {
    let url = this.service_base+'deleteFuelStation' + id;
    return this.http.delete<any[]>(url, {
      headers: this.headers,
    });
  }

  getFuelStationKPI(): Observable<any> {
    let url = '/restv2/getFuelStationKPI';
    return this.http.get<any[]>(url, {
      headers: this.headers,
    });
  }

  getAllFuelTypes(): Observable<any> {
    let url = this.service_base + 'fuel-type/get-all';
    return this.http.get<any[]>(url, {
      headers: this.headers,
    });
  }

}
