<div class="myModal emailbuttons fullscreen-dialog">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p>Email Buttons</p>
      <p>Customer Transaction(s) Mail Notification</p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>
  <div class="modal-contain">
    <form class="myform" *ngIf="!template">
      <div class="htitle">
        <div class="row">
          <div class="col-xs-12 col-md-12 col-lg-12">
            <mat-form-field class="chip-list">
              <mat-label>Receipients</mat-label>
              <mat-chip-grid #chipGrid aria-label="Enter Receipients">
                <mat-chip-row
                  *ngFor="let email of emails"
                  (removed)="remove(email)"
                  [editable]="true"
                  (edited)="edit(email, $event)"
                  [aria-description]="'press enter to edit ' + email.address"
                >
                  {{ email.address }}
                  <button
                    matChipRemove
                    [attr.aria-label]="'remove ' + email.address"
                  >
                    <i class="fa fa-cancel"></i>
                  </button>
                </mat-chip-row>
                <input
                  placeholder="Add Receipients "
                  [matChipInputFor]="chipGrid"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event)"
                />
              </mat-chip-grid>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div>
        <div class="feedback" *ngIf="response === 'success'">
          <div class="__contain">
            <i class="fa-regular fa-circle-check"></i>
            <div class="details">
              <p>{{ mail_type }} mail sent successfully!.</p>
            </div>
          </div>
        </div>
        <div *ngIf="response === 'loading' && !template" class="feedback">
          <div class="__contain">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            <p>Please wait while we are proccessing the records</p>
          </div>
        </div>
        <div class="mychips">
          <div class="mychip" *ngFor="let r of data" [matTooltip]="r">
            <p>Reg: {{ r.REGISTRATIONNO }}</p>
          </div>
        </div>
        <div class="row" *ngIf="!response">
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="suspectRegistrationNumber()"
            >
              <div class="details">
                <div class="circle bg-color-3"></div>
                <p>Suspect Registration Number</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="suspectHighOdo()"
            >
              <div class="details">
                <div class="circle bg-color-1"></div>
                <p>Suspect High Odo</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="staticOdoReading()"
            >
              <div class="details">
                <div class="circle bg-color-2"></div>
                <p>Static Odo</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              disabled
              mat-stroked-button
              class="emailbtn"
              (click)="suspectLowConsumption()"
            >
              <div class="details">
                <div class="circle bg-color-3"></div>
                <p>Suspect High Consupmtion [X]</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="suspectLowConsumption()"
            >
              <div class="details">
                <div class="circle bg-color-4"></div>
                <p>Suspect Low Consuption</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="faultyOdoReading()"
            >
              <div class="details">
                <div class="circle bg-color-gray"></div>
                <p>Faulty Odo</p>
              </div>
            </button>
          </div>

          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="duplicateTransaction()"
            >
              <div class="details">
                <div class="circle bg-color-gray"></div>
                <p>Duplicate Transcation</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="tankCapacityExceeded()"
            >
              <div class="details">
                <div class="circle bg-color-5"></div>
                <p>Tank Exceeded</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="consumptionNormVehicle()"
            >
              <div class="details">
                <div class="circle bg-color-gray"></div>
                <p>Suspect Consuption Norm</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="suspectLowOdo()"
            >
              <div class="details">
                <div class="circle bg-color-6"></div>
                <p>Suspect Low Odo</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button mat-stroked-button class="emailbtn" (click)="comment()">
              <div class="details">
                <div class="circle bg-color-gray"></div>
                <p>Comment Mail</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="weekendRefuel()"
            >
              <div class="details">
                <div class="circle bg-color-7"></div>
                <p>Weekend Refuel</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="odoMaintenance()"
            >
              <div class="details">
                <div class="circle bg-color-gray"></div>
                <p>Odo Maintenance</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="fuelStationEnquiry()"
            >
              <div class="details">
                <div class="circle bg-color-gray"></div>
                <p>Fuel Station Enquiry</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="confirmCredit()"
            >
              <div class="details">
                <div class="circle bg-color-gray"></div>
                <p>Confirm Credit</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="suspectRegistrationNumber()"
            >
              <div class="details">
                <div class="circle bg-color-gray"></div>
                <p>Suspect and Fuel Station [x]</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="suspectOdoReadings()"
            >
              <div class="details">
                <div class="circle bg-color-gray"></div>
                <p>Suspect Odo</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button mat-stroked-button class="emailbtn">
              <div class="details">
                <div class="circle bg-color-8"></div>
                <p>Fuel station out of bound [x]</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="possibleDuplicateCard()"
            >
              <div class="details">
                <div class="circle bg-color-gray"></div>
                <p>Duplicate Card</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button mat-stroked-button class="emailbtn">
              <div class="details">
                <div class="circle bg-color-9"></div>
                <p>Suspect High Consuption (Horse) [x]</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button mat-stroked-button class="emailbtn">
              <div class="details">
                <div class="circle bg-color-gray"></div>
                <p>Possible Clone Card [x]</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button mat-stroked-button class="emailbtn" (click)="tankNotFull()">
              <div class="details">
                <div class="circle bg-color-10"></div>
                <p>Tank Full N?</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="monthlyPermittedLimitExceeded()"
            >
              <div class="details">
                <div class="circle bg-color-11"></div>
                <p>Monthly Litres Exceeded</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="possibleCalculatedFills()"
            >
              <div class="details">
                <div class="circle bg-color-gray"></div>
                <p>Possible Calculated Fills</p>
              </div>
            </button>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3">
            <button
              mat-stroked-button
              class="emailbtn"
              (click)="suspectRegistrationNumber()"
            >
              <div class="details">
                <div class="circle bg-color-gray"></div>
                <p>Suspect Registration Number</p>
              </div>
            </button>
          </div>
        </div>
        <div class="foot">
          <div class="foot" *ngIf="response">
            <button
              mat-stroked-button
              (click)="closeModal()"
              [ngClass]="is_attachment ? 'active' : ''"
            >
              <p>Close <i class="fa fa-close"></i></p>
            </button>
          </div>
        </div>
        <div class="foot" *ngIf="!response">
          <p class="title">Add attachment on the email</p>
          <button
            mat-stroked-button
            (click)="toggleAttachment(true)"
            [ngClass]="is_attachment ? 'active' : ''"
          >
            <p>Yes</p>
          </button>
          <button
            mat-stroked-button
            (click)="toggleAttachment(false)"
            [ngClass]="!is_attachment ? 'active' : ''"
          >
            <p>No</p>
          </button>
        </div>
      </div>
    </form>
    <!-- <div [innerHTML]="template | htmlPreview"></div> -->
      <angular-editor
        id="editor1"
        *ngIf="template"
        [(ngModel)]="template"
        [config]="editorConfig"
        [ngClass]="!template ? 'disabled': ''"
      ></angular-editor>
  </div>
  <div class="foot" *ngIf="template" align="end">
    <button mat-stroked-button (click)="sendMail()">
      <p>submit <i class="fa fa-circle-check"></i></p>
    </button>
  </div>
</div>

