import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {CompanyService} from "../../../services/companies/company.service";
import {FuelStationService} from "../../../services/fuel-stations/fuel-station.service";
import {SnackbarsService} from "../../../services/indicators/notifications/snackbars.service";
import {MatSelect} from "@angular/material/select";
@Component({
  selector: 'app-add-fuel-station',
  templateUrl: './add-fuel-station.component.html',
  styleUrls: ['./add-fuel-station.component.css'],
})
export class AddFuelStationComponent implements OnInit {
  fuelStationForm: FormGroup;
  fuelTypes: any;
  @ViewChild('roleSelect') roleSelect: MatSelect;
  constructor(
    public dialogRef: MatDialogRef<AddFuelStationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _companyService: CompanyService,
    private _fuelStationService: FuelStationService,
    private _snackBar: SnackbarsService
  ) {}

  ngOnInit(): void {
    this.getAllFuelTypes();
    this.fuelStationForm = this.fb.group({
      uuid: [this.data ? this.data.uuid : ''],
      company_uuid: [this._companyService.getCompanyId()],
      fuel_station_master_id: [this.data ? this.data.fuel_station_master_id : '', Validators.required],
      registered_name: [this.data ? this.data.registered_name : '', Validators.required],
      trading_name: [this.data ? this.data.trading_name : ''],
      town: [this.data ? this.data.town : ''],
      merchant_number: [this.data ? this.data.merchant_number : ''],
      email_address: [this.data ? this.data.email_address : '', [Validators.required, Validators.email]],
      province: [this.data ? this.data.province : ''],
      zone: [this.data ? this.data.zone : ''],
      telephone: [this.data ? '0' + this.data.telephone : '', [Validators.required, Validators.pattern('^[0-9]{9}$')]],
      cellphone: [this.data ? '0' + this.data.cellphone : '', [Validators.required, Validators.pattern('^[0-9]{9}$')]],
      fax: [this.data ? this.data.fax : ''],
      magisterial_district: [this.data ? this.data.magisterial_district : ''],
      contact_person: [this.data ? this.data.contact_person : ''],
      longitude: [this.data ? this.data.longitude : ''],
      latitude: [this.data ? this.data.latitude : ''],
      fuel_types: [[]]
    });
  }

  closeModal(data?: any): void {
    this.dialogRef.close({ event: data });
  }

  onSubmit() {
    console.log(this.fuelStationForm);
    if (this.fuelStationForm.valid) {
      if(!this.data) {
        this._fuelStationService.addFuelStation(this.fuelStationForm.value).subscribe((response) => {
          this._snackBar.success(response.status.message);
          if(response.status.message.includes('Fuel station saved.')) {
            this.closeModal('add');
          }
        }, (error) => {
          console.log(error);
        });
      } else {
        this._fuelStationService.updateFuelStation(this.fuelStationForm.value).subscribe((response) => {
          this._snackBar.success(response.status.message);

          if(response.status.message.includes('Fuel station updated.')) {
            this.closeModal('update');
          }
        }, (error) => {
          this._snackBar.error(error);
        });
      }
    } else {
      if(this.fuelStationForm.controls['email_address']?.errors?.['email']) {
        this._snackBar.error('Invalid email address');
      } else if(this.fuelStationForm.controls['cellphone']?.errors?.['pattern']) {
        this._snackBar.error('Cellphone number must be exactly 10 digits.');
      } else if(this.fuelStationForm.controls['telephone']?.errors?.['pattern']) {
        this._snackBar.error('Telephone number must be exactly 10 digits.');
      } else {
        this._snackBar.error('Please fill all required fields');
      }

    }
  }

  onChangeValue(fieldName: string, value: any) {
    this.fuelStationForm.get(fieldName)?.setValue(value);
  }

  getAllFuelTypes() {
    this._fuelStationService.getAllFuelTypes().subscribe({
      next: (data: any) => {
        this.fuelTypes = data.results;

        const allFuelTypeIds = this.data?.fuel_station_fuel_types.map((fuelType: any) => fuelType.fuel_type_uuid);
        this.fuelStationForm.get('fuel_types')?.setValue(allFuelTypeIds);
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }
}
