<div
     [className]="vehicleDriver ? 'myModal col-12' : 'myModal'"
>
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
       *ngIf="!vehicleDriver"
  >
    <div class="lft">
      <div class="htitle">
        <p class="FxLine"><i class="fa fa-drivers-license"></i> {{ !data ? 'Add ' : 'Update ' }} Driver</p>
      </div>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal('close')">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>

  <div
    [className]="vehicleDriver ? 'ps-0' : 'modal-contain'"
  >
    <div class="row">
      <div class="col-12"
           *ngIf="vehicleDriver"
      >
        <form class="myform">
          <div class="row">
            <div class="col-xs-12 col-md-12">
              <mat-form-field>
                <mat-label>Select Driver</mat-label>
                <mat-select
                  #roleSelect
                  name="driver_uuid"
                  [(value)]="oldDriverData"
                  (selectionChange)="onChangeDriver($event.value)"
                >
                  <mat-option
                    *ngFor="let driver of allDrivers"
                    [value]="driver"
                    [style]="driver.vehicle !== null ? 'background: rgba(255,0,0,0.64)' : 'background: rgba(0,95,2,0.64)'"
                  >
                    {{ driver?.driver_name + ' - ' + (driver.vehicle !== null ? 'Assigned to ' + driver.vehicle?.make_vehicle_type?.make?.name + ': ' + driver.vehicle?.range?.name + '-' + driver.vehicle?.car_model?.name + '(' + driver.vehicle?.registration_number + ')' : 'Not Assigned')}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
      <div
        *ngIf="vehicleDriver"
        [class]="vehicleDriverForm.value['new_driver'] === null ? 'col-12' : 'col-6'">
        <div class="row">
          <div class="col-12">
            <h3 style="text-align: center;">Currently {{ !oldDriverData ? ' There\'s No ' : ''}}Assigned Driver</h3>
          </div>
        </div>
        <form
          [class]="vehicleDriverForm.value['new_driver'] === null ? 'myform' : 'myform p-2 mb-2 border'"
          [formGroup]="oldDriverForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <mat-form-field>
                <mat-label>Driver Name</mat-label>
                <input matInput formControlName="driver_name"/>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-6">
              <mat-form-field>
                <mat-label>Employee Number</mat-label>
                <input matInput formControlName="employee_number"/>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-6">
              <mat-form-field>
                <mat-label>ID Number</mat-label>
                <input matInput formControlName="id_number" type="number"/>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-6">
              <mat-form-field>
                <mat-label>Passport Number</mat-label>
                <input matInput formControlName="passport_number"/>
              </mat-form-field>
            </div>
          </div>
        </form>

        <div class="row">
          <div class="col-xs-12"
               *ngIf="vehicleDriverForm.value['new_driver'] !== null"
          >
            <button class="mybtn" mat-flat-button color="primary" type="button"
                    (click)="assignDriver()"
            >
              Assign New Driver
            </button>
          </div>
        </div>
      </div>
      <div
        *ngIf="vehicleDriver && vehicleDriverForm.value['new_driver'] !== null || !vehicleDriver"
        [class]="vehicleDriver ? 'col-6' : 'col-12'"
      >
        <div class="row"
             *ngIf="vehicleDriver"
        >
          <div class="col-12">
            <h3 style="text-align: center;">Newly Selected Driver</h3>
          </div>
        </div>
        <form
          [class]="vehicleDriver ? 'myform p-2 mb-2 border' : 'myform'"
          [formGroup]="addDriverForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <mat-form-field>
                <mat-label>Driver Name</mat-label>
                <input matInput formControlName="driver_name" placeholder="Ex. John Doe"/>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-6">
              <mat-form-field>
                <mat-label>Employee Number</mat-label>
                <input matInput formControlName="employee_number" placeholder="Ex. 1234"/>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-6">
              <mat-form-field>
                <mat-label>ID Number</mat-label>
                <input matInput formControlName="id_number" type="number" placeholder="Ex. 910101604088"/>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-6">
              <mat-form-field>
                <mat-label>Passport Number</mat-label>
                <input matInput formControlName="passport_number" value="" placeholder="Ex. 1234"/>
              </mat-form-field>
            </div>

            <div class="col-xs-12"
              *ngIf="!vehicleDriver"
            >
              <button class="mybtn" mat-flat-button color="primary" type="submit">
                {{ !data ? 'Add ' : 'Update ' }} Driver
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>



<div class="confirm-modal" *ngIf="confirmDriverAssignement">
  <div class="background"  (click)="closeConfirmDialog()"></div>
  <div class="contain">
    <div class="header">
      <div class="icon">
        <i class="fa-solid fa-triangle-exclamation"></i>
      </div>
    </div>
    <div class="body">
      <div class="htitle"><p>Assign New Driver</p></div>
      <ng-container *ngIf="confirmDriverResponse !== 'loading'">
        <div class="points" style="text-align: left;">
          <div>
            <strong>Caution:</strong>
            <ul>
              <li>
                This vehicle (<strong>{{ data.registration_number }}</strong>) has a driver already assigned to it (<strong>{{oldDriverData?.driver_name + ' - ' + oldDriverData.employee_number}}</strong>).
              </li>
              <li
                *ngIf="oldDriverData"
              >
                The newly selected driver, <strong>{{ vehicleDriverForm.value['new_driver']?.driver_name + ' - ' + vehicleDriverForm.value['new_driver']?.employee_number }}</strong>, is already assigned to another vehicle with registration number <strong>{{ vehicleDriverForm.value['new_driver']?.vehicle?.registration_number }}</strong>.
              </li>
            </ul>

            If you proceed with assigning <strong>{{ vehicleDriverForm.value['new_driver']?.driver_name }}</strong> to this vehicle (<strong>{{ data.registration_number }}</strong>), please note the following:
            <ul>
              <li>
                The previous vehicle (<strong>{{ vehicleDriverForm.value['new_driver']?.vehicle?.registration_number }}</strong>) will no longer have a driver assigned.
              </li>
              <li
                *ngIf="oldDriverData"
              >
                The driver (<strong>{{oldDriverData?.driver_name + ' - ' + oldDriverData.employee_number}}</strong>) will no longer have a vehicle assigned.
              </li>
            </ul>

            Please confirm your decision before proceeding.
          </div>
        </div>
        <div class="btns">
          <button mat-flat-button color="primary" (click)="saveAssignedDriver()"><p>Yes, Assign</p></button>
          <button mat-stroked-button color="warm" (click)="closeConfirmDialog()"><p>Close</p></button>
        </div>
      </ng-container>
      <ng-container *ngIf="confirmDriverResponse === 'loading'">
        <div class="__loader">
          <div class="contain__">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            <p>Please wait while we are assigning this driver to the vehicle.</p>
          </div>
        </div>
        <div class="btns">
          <button mat-flat-button color="primary" (click)="closeConfirmDialog()">
            <p>Close</p>
          </button>
          <button mat-stroked-button color="warm" style="display: none" (click)="closeConfirmDialog()">
            <p>Close</p>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
