import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class SnackbarsService {

  constructor(private _snackBar:MatSnackBar) { }

  private openSnackBar(message: string, type: string) {
    this._snackBar.open(message, 'OK', { duration: 5000, panelClass: type });
  }
  private openRetrySnackBar(message: string, type: string) {
    this._snackBar.open(message, 'OK', { duration: 5000, panelClass: type });
  }
  success(message: string){
    this.openSnackBar(message,'success');
  }
  error(message: string){
    this.openSnackBar(message,'error');
  }
  errorRetry(message: string){
  this.openRetrySnackBar(message,'error');
  }
  warn(message: string){
    this.openSnackBar(message,'warning');
  }

}
