<div class="myModal">
  <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="lft">
      <p><i class="fa fa-bar-chart"></i> Generate Monthly Billing</p>
      <p></p>
    </div>
    <div class="rgt">
      <button mat-flat-button (click)="closeModal()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>
  <div class="myModal">
    <div class="modal-contain">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="card007">
            <div class="mytable">
              <table
                mat-table
                [dataSource]="billingTypeOptions"
                class="mat-elevation-z8"
              >
                <ng-container matColumnDef="BILLINGTYPE">
                  <th mat-header-cell *matHeaderCellDef>BILLING TYPE</th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    (click)="getBillingAccounts(row.name)"
                  >
                    {{ row.name }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="BILLINGDESCRIPTION">
                  <th mat-header-cell *matHeaderCellDef>BILLING DESCRIPTION</th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    (click)="getBillingAccounts(row.name)"
                  >
                    {{ row.value }}
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="
                    displayedColumns_Email_Addresses;
                    sticky: true
                  "
                ></tr>
                <tr
                  mat-row
                  *matRowDef="
                    let row;
                    columns: displayedColumns_Email_Addresses
                  "
                ></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card007">
            <div class="mytable">
              <table
                mat-table
                [dataSource]="billingAccounts"
                class="mat-elevation-z8"
              >
                <ng-container matColumnDef="TRF">
                  <th mat-header-cell *matHeaderCellDef>TRF</th>
                  <td mat-cell *matCellDef="let row">
                    {{ 1 }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="ACCOUNTID">
                  <th mat-header-cell *matHeaderCellDef>ACCOUNT ID</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.ACCOUNTID }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="AS400ACCOUNTID">
                  <th mat-header-cell *matHeaderCellDef>AS400 ACCOUNT ID</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.AS400ACCOUNTID }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="BILLINGTYPE">
                  <th mat-header-cell *matHeaderCellDef>BILLINGTYPE</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.BillingType }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="BILLINGDESCRIPTION">
                  <th mat-header-cell *matHeaderCellDef>BILLING DESCRIPTION</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.BillingDescription }}
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns_Accounts; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns_Accounts"
                ></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card0071">
            <form class="myform" [formGroup]="">
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Choose Invoice Date</mat-label>
                    <input
                      matInput
                      formControlName="invoice_date"
                      [matDatepicker]="picker1"
                    />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle
                      matIconSuffix
                      [for]="picker1"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="actionsbtns">
                  <div class="myrow">
                    <button mat-stroked-button class="active">
                      <p>Populate Selected Accounts List</p>
                    </button>
                    <button mat-stroked-button class="active">
                      <p>Populate Full List To Bill</p>
                    </button>
                    <button mat-stroked-button class="active">
                      <p>Build Posting List</p>
                    </button>
                    <button mat-stroked-button class="">
                      <p>Create Batch</p>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-12">
          <div class="card111">
            <div class="mytable">
              <table mat-table [dataSource]="" class="mat-elevation-z8">
                <ng-container matColumnDef="ID">
                  <th mat-header-cell *matHeaderCellDef>ID</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.EMAIL_TYPE_DESCRIPTION }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="COMPANYNAME">
                  <th mat-header-cell *matHeaderCellDef>COMPANY NAME</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.EMAIL_TYPE_DESCRIPTION }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="CUSTOMERNAME">
                  <th mat-header-cell *matHeaderCellDef>CUSTOMER NAME</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.EMAIL_TYPE_DESCRIPTION }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="ACCOUNTID">
                  <th mat-header-cell *matHeaderCellDef>ACCOUNT ID</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.EMAIL_TYPE_DESCRIPTION }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="ACCOUNTNAME">
                  <th mat-header-cell *matHeaderCellDef>ACCOUNT NAME</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.EMAIL_TYPE_DESCRIPTION }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="BILLINGTYPE">
                  <th mat-header-cell *matHeaderCellDef>BILLING TYPE</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.EMAIL_TYPE_DESCRIPTION }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="BILLINGDESCRIPTION">
                  <th mat-header-cell *matHeaderCellDef>BILLING DESCRIPTION</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.EMAIL_TYPE_DESCRIPTION }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="REGISTRATIONNO">
                  <th mat-header-cell *matHeaderCellDef>REGISTRATION NO</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.EMAIL_TYPE_DESCRIPTION }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="FLEETNO">
                  <th mat-header-cell *matHeaderCellDef>FLEET NO</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.EMAIL_TYPE_DESCRIPTION }}
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns_Billing; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns_Billing"
                ></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12" align="end">
          <button class="mybtn" mat-flat-button color="primary" type="submit">
            close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
