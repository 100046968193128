import { Injectable } from '@angular/core';
import { Observable, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  constructor() {}

  manageDataState(data: any, stateStore: any) {
    data.subscribe((next: any) => {
      localStorage[stateStore] = JSON.stringify(next);
    });

    let response = data.pipe(
      startWith(JSON.parse(localStorage[stateStore] || []))
    );
    if (stateStore) {
      return response;
    } else {
      return data;
    }
  }
}
