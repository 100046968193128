<div class="myModal">
    <div class="myhead" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="lft">
        <ng-container *ngIf="data.action == 'create'">
            <p>Create New User</p>
            <p>Add new company</p>
          </ng-container>
          <ng-container *ngIf="data.action == 'update'">
            <p>Update User</p>
            <p>{{data.form.user_name}}</p>
          </ng-container>
      </div>
      <div class="rgt">
        <button mat-flat-button (click)="closeModal()">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>

    <div class="modal-contain">
      <form class="myform addnewuser" >
          <div class="userpp">
            <i class="fa-regular fa-user"></i>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field>
                <mat-label>Username</mat-label>
                <input
                    name="user_name"
                    (keyup)="onChangeValue('user_name', $event.target.value)"
                    [value]="data.form.user_name"
                    matInput
                    placeholder="E.g John Smith"
                    maxlength="50"
                />
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field>
                <mat-label>Email Address</mat-label>
                <input
                    name="email"
                    (keyup)="onChangeValue('email', $event.target.value)"
                    [value]="data.form.email"
                    matInput
                    placeholder="E.g name.surname@avis.co.za"
                    maxlength="50"
                />
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field>
                <mat-label>Role</mat-label>
                <mat-select
                  #roleSelect
                  name="role_uuid"
                  [(value)]="data.form.role_uuid"
                  (selectionChange)="onChangeValue('role_uuid', $event.value)"
                >
                  <mat-option
                    *ngFor="let role of roles"
                    [value]="role.uuid"
                  >
                    {{ role.role }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12">
                <mat-progress-bar mode="indeterminate"  *ngIf="response === 'loading'"></mat-progress-bar>
                <button class="mybtn" mat-flat-button color="primary" type="button"
                        [disabled]="response === 'loading'? true : false"
                        *ngIf="data.action == 'create'"
                        (click)="createUser()"
                >
                    Create User
                </button>
                <button class="mybtn" mat-flat-button color="primary" type="button"
                        [disabled]="response === 'loading'? true : false"
                        *ngIf="data.action == 'update'"
                        (click)="updateUser()"
                >
                    Update User
                </button>
            </div>
          </div>

      </form>
    </div>
  </div>
