import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarsService } from 'src/app/services/indicators/notifications/snackbars.service';
import { UserService } from 'src/app/services/users/user.service';
import { AssignUserCompaniesComponent } from '../assign-user-companies/assign-user-companies.component';
import {RoleService} from "../../../services/roles/role.service";
import {ViewChild} from "@angular/core";
import {MatSelect} from "@angular/material/select";

@Component({
  selector: 'app-user-config',
  templateUrl: './user-config.component.html',
  styleUrls: ['./user-config.component.css']
})
export class UserConfigComponent {
  addDriverForm: any;
  roles: any;
  @ViewChild('roleSelect') roleSelect: MatSelect;
  constructor(
    public dialogRef: MatDialogRef<UserConfigComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackbar: SnackbarsService,
    private _UserServ: UserService,
    private _roleService: RoleService,
  ) {
    this.getAllRoles();
  }

  fullname = '';
  email_address = '';

  validateFullName(event:any){
    const value = event.value;
    if(value.length<50){
      this.fullname = value;
    }else{
      this.fullname = value.substring(0, 50);
    }
  }

  isValidEmail(email:any){
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  onChangeValue(fieldName: string, value: any) {
    this.data.form[fieldName] = value;
  }
  isValidForm() {
    if (this.data.form.user_name.length < 1) {
      (document.querySelector("input[name=user_name]") as HTMLInputElement).focus();
      return false;
    }
    if (this.data.form.email.length < 1) {
      (document.querySelector("input[name=email]") as HTMLInputElement).focus();
      return false;
    }
    if (!this.isValidEmail(this.data.form.email)) {
      (document.querySelector("input[name=email]") as HTMLInputElement).focus();
      this._snackbar.error('Email address is invalid');
      return false;
    }
    if (this.data.form.role_uuid.length < 1) {
      this.roleSelect.focus();
      return false;
    }
    return true;
  }

  response = '';
  response_data:any = null;
  createUser() {
    if(!this.isValidForm()){
      return;
    }
    this.response = 'loading';
    this.response_data = null;

    let payload = this.data.form;
    this._UserServ.createUser(payload).subscribe({
      next: (data: any) => {
        if(data.status.message === "success"){
          this.response = 'success';
          this._snackbar.success('Successfully created the user '+ payload.user_name);
          this.dialogRef.close({event:'create', data: payload});
        }else{
          this.response = 'network_err';
          if(data.status.message.includes('Email address already exists')){
            this._snackbar.error('Email address already exists');
            (document.querySelector("input[name=email]") as HTMLInputElement).focus();
          }else{
            this._snackbar.error(data.status.message);
          }
        }
      },
      error: (err: any) => {
        this.response = 'network_err';
        this._snackbar.error(err.message);
      },
    });
  }

  updateUser() {
    if(!this.isValidForm()){
      return;
    }

    this.response = 'loading';
    this.response_data = null;

    let payload = this.data.form;
    this._UserServ.updateUser(payload).subscribe({
      next: (data: any) => {
        if(data.status.message == "success"){
          this.response = 'success';
          this._snackbar.success('Successfully updated the user '+ payload.user_name);
          this.dialogRef.close({event:'create', data: payload});
        }else{
          this.response = 'network_err';
          if (data.status.message.includes('Email address already exists')) {
            this._snackbar.error('Email address already exists');
            (document.querySelector("input[name=email]") as HTMLInputElement).focus();
          } else {
            this._snackbar.error(data.status.message);
          }
        }
      },
      error: (err: any) => {
        this.response = 'network_err';
        this._snackbar.error(err.message);
      },
    });
  }

  closeModal(): void {
    this.dialogRef.close({event:'close'});
  }

  getAllRoles() {
    this._roleService.getAllRoles().subscribe({
      next: (data: any) => {
        this.roles = data.results;
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }
}
