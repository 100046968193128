import {
  FaultyOdoReading,
  FuelStationEnquiry,
  IrregularFuelTransactions,
  IrregularFuelTransactions2,
  MonthlyPermittedLimitExceeded,
  PossibleCalculatedFills,
  PossibleCloneCard,
  StaticOdoReading,
  SuspectLowConsumption,
  SuspectLowOdo,
  TankCapacityExceeded,
  WeekendRefuel,
  DuplicateTransaction,
  ConsumptionNormVehicle,
  OdoMaintenance,
  ConfirmCredit,
  SuspectOdoReadings,
  Comment,
  PossibleDuplicateCard,
  PossibleSplit,
  TankNotFull,
  BereauServiceWeeklyReport,
  ReTankCapacityExceeded,
  ReTankCapacity,
  StaticOdoReadingForVehicle,
  PossibleCloneCardForVehicle,
  ReDuplicateTransactionForVehicle,
  UnknownRegistration,
  SuspectRegistrationNumber,
  BereauServiceMonthlyReport,
} from './../../interfaces/emails';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay, tap } from 'rxjs';
import { SuspectHighOdo } from 'src/app/interfaces/emails';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MailsService {
  constructor(private http: HttpClient) { }
  private url =
    'https://zedadev.apigw-aw-eu.webmethods.io/gateway/IFM/1.0/sendEmailTemplates';

  private headers = new HttpHeaders({
    'x-Gateway-APIKey': 'c2c22314-ca55-4b42-b472-494a6c180706',
    'Access-Control-Allow-Origin': '*',
  });

  public getAllMailTypes(): Observable<any> {
    return this.http.get<any>('/company/getAllMailTypes',
    ).pipe(shareReplay());
  }
  public getAllCompanyEmailAdresses(company_id: string) {
    return this.http.get<any>('/company/getAllCompanyEmails/' + company_id
    ).pipe(shareReplay());
  }
  public InsertCompanyNotificationEmail(data: any) {
    return this.http.post<any>('/notificationEMail/assign', data,
    ).pipe(tap(() => console.log('assign request executed'))
      ,
      shareReplay());
  }
  public sendNotification(data: any) {
    return this.http.post<any>('/sendmail', data,
    ).pipe(shareReplay());
  }

  public suspectRegistrationNumber(
    data: SuspectRegistrationNumber,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: SUSPECTREGISTRATIONNUMBER',
          from: 'ifm@avisbudget.co.za',
          Template: 'SUSPECTREGISTRATIONNUMBER',
          TemplateInputs: [data],
        },
      },
      {
        headers: this.headers,
      }
    );
  }

  public suspectHighOdo(
    data: SuspectHighOdo,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: SUSPECT HIGH ODOREADING',
          from: 'ifm@avisbudget.co.za',
          Template: 'SUSPECTHIGHODOREADING',
          TemplateInputs: [data],
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public suspectLowConsumption(
    data: SuspectLowConsumption,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: SUSPECT LOW CONSUMPTION',
          from: 'ifm@avisbudget.co.za',
          Template: 'SUSPECTLOWCONSUMPTION',
          TemplateInputs: [data],
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public irregularFuelTransactions(
    data: IrregularFuelTransactions,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: IRREGULAR FUEL TRANSACTIONS',
          from: 'ifm@avisbudget.co.za',
          Template: 'IRREGULARFUELTRANSACTIONS',
          TemplateInputs: [data],
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public tankCapacityExceeded(
    data: TankCapacityExceeded,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: TANK CAPACITY EXCEEDED',
          from: 'ifm@avisbudget.co.za',
          Template: 'TANKCAPACITYEXCEEDED',
          TemplateInputs: [data],
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public suspectLowOdo(
    data: SuspectLowOdo,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: SUSPECT LOW ODO READING',
          from: 'ifm@avisbudget.co.za',
          Template: 'SUSPECTLOWODOREADING',
          TemplateInputs: [data],
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public weekendRefuel(
    data: WeekendRefuel,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: WEEKEND RE-FUEL ALERT',
          from: 'ifm@avisbudget.co.za',
          Template: 'WEEKENDREFUELALERT',
          TemplateInputs: [data],
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public fuelStationEnquiry(
    data: FuelStationEnquiry,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: FUEL STATION ENQUIRY',
          from: 'ifm@avisbudget.co.za',
          Template: 'FUELSTATIONENQUIRY',
          TemplateInputs: [data],
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public outOfBoundsFuelStation(
    data: IrregularFuelTransactions2,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: OUT OF BOUNDS FUEL STATION ALERT',
          from: 'ifm@avisbudget.co.za',
          Template: 'OUTOFBOUNDSFUELSTATIONALERT',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public irregularFuelTransactions2(
    data: IrregularFuelTransactions2,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: IRREGULAR FUEL TRANSACTION_02',
          from: 'ifm@avisbudget.co.za',
          Template: 'IRREGULARFUELTRANSACTION_02',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public possibleCloneCard(
    data: PossibleCloneCard,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: POSSIBLE CLONE CARD',
          from: 'ifm@avisbudget.co.za',
          Template: 'POSSIBLECLONECARD',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public monthlyPermittedLimitExceeded(
    data: MonthlyPermittedLimitExceeded,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: MONTHLYPERMITTEDLIMITEXCEEDED',
          from: 'ifm@avisbudget.co.za',
          Template: 'MONTHLYPERMITTEDLIMITEXCEEDED',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public staticOdoReading(
    data: StaticOdoReading,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: STATIC ODO READING',
          from: 'ifm@avisbudget.co.za',
          Template: 'STATICODOREADING',
          TemplateInputs: [data],
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public faultyOdoReading(
    data: FaultyOdoReading[],
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: FAULTY ODO READING',
          from: 'ifm@avisbudget.co.za',
          Template: 'FAULTYODOREADING',
          TemplateInputs: [data],
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public duplicateTransaction(
    data: DuplicateTransaction,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: DUPLICATE TRANSACTION',
          from: 'ifm@avisbudget.co.za',
          Template: 'DUPLICATETRANSACTION',
          TemplateInputs: [data],
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public consumptionNormVehicle(
    data: ConsumptionNormVehicle[],
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: CONSUMPTION NORM VEHICLE',
          from: 'ifm@avisbudget.co.za',
          Template: 'CONSUMPTIONNORMVEHICLE',
          TemplateInputs: [data],
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public comment(data: Comment, template?: any, to?: any): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: COMMENT',
          from: 'ifm@avisbudget.co.za',
          Template: 'COMMENT',
          TemplateInputs: [data],
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public odoMaintenance(
    data: OdoMaintenance,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: ODO MAINTENANCE',
          from: 'ifm@avisbudget.co.za',
          Template: 'ODOMAINTENANCE',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public confirmCredit(
    data: ConfirmCredit,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: CONFIRM CREDIT',
          from: 'ifm@avisbudget.co.za',
          Template: 'CONFIRMCREDIT',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public suspectOdoReadings(
    data: SuspectOdoReadings,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: SUSPECT ODO READINGS',
          from: 'ifm@avisbudget.co.za',
          Template: 'SUSPECTODOREADINGS',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public possibleDuplicateCard(
    data: PossibleDuplicateCard,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: POSSIBLE DUPLICATE CARD ALERT',
          from: 'ifm@avisbudget.co.za',
          Template: 'POSSIBLEDUPLICATECARDALERT',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public possibleSplit(
    data: PossibleSplit,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: POSSIBLE SPLIT',
          from: 'ifm@avisbudget.co.za',
          Template: 'POSSIBLESPLIT',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public tankNotFull(
    data: TankNotFull,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: TANKNOTFULL',
          from: 'ifm@avisbudget.co.za',
          Template: 'TANKNOTFULL',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public possibleCalculatedFills(
    data: PossibleCalculatedFills,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: POSSIBLE CALCULATED FILLS',
          from: 'ifm@avisbudget.co.za',
          Template: 'POSSIBLECALCULATEDFILLS',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public bereauServiceWeeklyReport(
    data: BereauServiceWeeklyReport,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: BUREAU SERVICE WEEKLY REPORT',
          from: 'ifm@avisbudget.co.za',
          Template: 'BUREAUSERVICEWEEKLYREPORT',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public bereauServiceMontlhyReport(
    data: BereauServiceMonthlyReport[],
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: BUREAU SERVICE MONTHLY REPORT',
          from: 'ifm@avisbudget.co.za',
          Template: 'BUREAUSERVICEMONTHENDREPORT',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public reTankCapacityExceeded(
    data: ReTankCapacityExceeded[],
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: POSSIBLE CLONE CARD',
          from: 'ifm@avisbudget.co.za',
          Template: 'ReTankCapacityExceeded',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public reTankCapacity(
    data: ReTankCapacity,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: RE-TANK CAPACITY',
          from: 'ifm@avisbudget.co.za',
          Template: 'RETANKCAPACITY',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public staticOdoReadingForVehicle(
    data: StaticOdoReadingForVehicle[],
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: STATIC ODO READING FOR VEHICLE',
          from: 'ifm@avisbudget.co.za',
          Template: 'STATICODOREADINGFORVEHICLE',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public possibleCloneCardForVehicle(
    data: PossibleCloneCardForVehicle[],
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: POSSIBLE CLONE CARD FOR VEHICLE',
          from: 'ifm@avisbudget.co.za',
          Template: 'POSSIBLECLONECARDFORVEHICLE',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public reDuplicateTransactionForVehicle(
    data: ReDuplicateTransactionForVehicle[],
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: 4. RE_DUPLICATE TRANSACTIONS FOR VEHICLE',
          from: 'ifm@avisbudget.co.za',
          Template: 'RE_DUPLICATETRANSACTIONSFORVEHICLE',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
  public unknownRegistration(
    data: UnknownRegistration,
    template?: any,
    to?: any
  ): Observable<any> {
    return this.http.post<any>(
      this.url,
      {
        EmailInputs: {
          to: to,
          subject: 'IFM: 5. UNKNOWN REGISTRATION NUMBER',
          from: 'ifm@avisbudget.co.za',
          Template: 'UNKNOWNREGNO',
          TemplateInputs: data,
        },
      },
      {
        headers: this.headers,
      }
    );
  }
}
