import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { SnackbarsService } from '../indicators/notifications/snackbars.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private router: Router, private _snackBar: SnackbarsService) {}

  CheckState() {
    let status :boolean=true;
    if (!this.getItem('username')) {
      this._snackBar.error('Please provide your login credentials!');
      status=false
      this.end();
    }
    return status;
  }

  setItem(name: string, value: any) {
    localStorage.setItem(name, value);
  }

  getItem(name: string) {
    return localStorage.getItem(name);
  }

  setUserName (username:any){
    return localStorage.setItem('username',username);
  }
  getUserName (){
    if(localStorage.getItem('username')){
      return localStorage.getItem('username')!.split('@')[0].replaceAll('.', ' ');
    }else{
      return 'UserNameNotFound';
    }
  }

  setUserId(user_id:any){
    return localStorage.setItem('user_id', user_id);
  }
  getUserId (){
    if(localStorage.getItem('user_id')){
      return localStorage.getItem('user_id');
    }else{
      return 'UserIdNotFound';
    }
  }
  end() {
    localStorage.clear();
    sessionStorage.clear()
    this.router.navigate(['/login']);
    return;
  }
}
